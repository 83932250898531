import React, { Component, Fragment } from 'react';
import { Grid, Button } from "@material-ui/core";
import moment from 'moment';
import {
    Menu,
    Close,
    FindInPage,
    Delete,
} from '@material-ui/icons';
import { getCandidatesChairman, getDashboard } from '../../logic/APIHandler';
import { styles } from '../../styles/CustomTable_Style';
import { isMobile, isTablet } from '../../logic/Handler';

import TableV2 from '../../components/Table_Com_V2';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';

class Vote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            openStopVoting: false,
            openStartVoting: false,
            openReopenVoting: false,
            openClearCandidates: false,
            hasVotingResult: false,
            candidateId: '00000000',
            candidateName: '',
            isLoading: false,
            period: moment().format('YYYY'),
            votePeriod: false,
            isSidebarOpen: 'slide-out',
            search: localStorage.getItem('search') || '',
            totalData: 0,
            params: null,
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama", 
            field: "member_id",
            tooltip: "Nama calon pengurus",
            render: rowData =>
                rowData.member_id !== null? (
                    <p>{rowData.member_id.name}</p>
                ) : ( <p>-</p> )
        },
        { 
            title: "Nomor Kontak", 
            field: "member_id",
            tooltip: "Nomor kontak calon pengurus",
            render: rowData =>
                <p>{rowData.member_id.phone_number}</p>
        },
        {
            title: "Foto",
            field: "photo",
            tooltip: "Foto calon pengurus",
            render: rowData =>
                rowData.photo? (
                    <img src={rowData.photo} alt="img" width="100" height="100" />
                ) : ( <p className="no-image-text">Tidak ada foto</p> )
        },
        { 
            title: "Total Vote", 
            field: "total_vote",
            tooltip: "Total vote per calon",
        },
    ];

    actions = [
        {
            icon: () => <FindInPage />,
            tooltip: "Lihat Detail Calon Pengurus",
            onClick: (_, rowData) => {
                this.props.history.push({
                    pathname: "/vote-detail/" + rowData._id,
                });
            },
        },
        {
            icon: () => <Delete />,
            tooltip: "Hapus Calon Pengurus",
            onClick: (_, rowData) => {
                this.handleOpen(rowData);
            },
        },
    ];

    // Show delete candidate modal
    handleOpen(candidate) {
        this.setState({
            open: true,
            candidateId: candidate._id,
            candidateName: candidate.member_id.name,
        });
    };

    // CLose delete candidate modal
    handleClose() {
        this.setState({
            open: false,
            openStopVoting: false,
            openStartVoting: false,
            openReopenVoting: false,
            openClearCandidates: false,
        });
    };

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    };

    async getData(params) {
        this.setState({ isLoading: true });
        const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
        const payload = {
            page: params && params.page ? params.page :  page,
            sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
            sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
            ...this.state.search && { search: this.state.search },
        };

        this.setState({ params: payload });
        localStorage.setItem('sortBy', payload.sortBy);
        localStorage.setItem('sort', payload.sort);

        let resp = await getCandidatesChairman(payload, false);
        if (resp[0] && resp[0].status === 200) {
            this.setState({
                data: resp[0].status !== 204? resp[0].data.datas : [],
                totalData: resp[0].status !== 204? resp[0].data.total : 0,
                isLoading: false,
            });
        }
        else if (resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({ isLoading: false });
        }
    };

    async getDashboardVote() {
        this.setState({ isLoading: true });
        const resp = await getDashboard();

        if (resp[0] && resp[0].status === 200) {
            const data = resp[0].data;
            this.setState({
                hasVotingResult: !!data.voting_result,
                votePeriod: data.voting_period,
                period: data.voting_result && data.voting_result.periode ? data.voting_result.periode : moment().format('YYYY'),
                isLoading: false,
            });
        } else if (resp[1] && resp[1].status === 401) {
            this.setState({ openJWT: true, isLoading: false });
        } else {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount() {
        this.getData();
        this.getDashboardVote();
    };

    componentDidUpdate(_, prevState) {
        setTimeout(() => {
            if (this.state.search.length > 0 && (
              this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
            )) {
                const search = this.state.search.toLowerCase();
                localStorage.setItem('search', search);
                this.props.history.push({ pathname: '/vote/0' });
                this.getData({ search: '', page: 1 });
            } else if (this.state.search.length === 0 && prevState.search) {
                this.props.history.push({ pathname: '/vote/0' });
                this.getData({ search: '', page: 1 });
            }
        }, 700);
    };

    render() {
        return (
            <div className="content">
                { this.state.isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <StatusModal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-candidate"
                    id={this.state.candidateId}
                    color="secondary"
                    modalTitle="Hapus data calon pengurus?"
                    dataName={this.state.candidateName}
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus calon pengurus!"
                    errorText="Gagal menghapus calon pengurus!"
                />
                <StatusModal
                    open={this.state.openStopVoting}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="stop-voting"
                    color="primary"
                    modalTitle="Tutup sesi voting periode ini?"
                    successText="Berhasil menutup voting periode ini!"
                    errorText="Gagal menutup voting periode ini!"
                    votePeriod={this.state.period}
                />
                <StatusModal
                    open={this.state.openStartVoting}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="start-voting"
                    color="primary"
                    modalTitle="Buka sesi voting periode ini?"
                    successText="Berhasil membuka sesi voting periode ini!"
                    errorText="Gagal membuka sesi voting periode ini!"
                    votePeriod={this.state.period}
                />
                <StatusModal
                    open={this.state.openReopenVoting}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="reopen-vote"
                    color="primary"
                    modalTitle="Buka sesi voting periode ini?"
                    successText="Berhasil membuka kembali sesi voting!"
                    errorText="Gagal membuka kembali sesi voting!"
                    votePeriod={this.state.period}
                />
                <StatusModal
                    open={this.state.openClearCandidates}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="clear-candidates"
                    color="primary"
                    modalTitle="Hapus data voting? Rekap data akan otomatis terdownload dan dikirimkan ke dpp@idepreneursclub.org"
                    successText="Berhasil menghapus data voting!"
                    errorText="Gagal menghapus data voting!"
                    votePeriod={this.state.period}
                    isTitleCenter
                />
                <StatusModal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <Menu /> : <Close />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <TableV2
                        title={`Tabel Kandidat - Periode ${this.state.period}`}
                        path="/vote"
                        columns={this.columns}
                        actions={this.actions}
                        data={this.state.data}
                        total={this.state.totalData}
                        search={this.state.search}
                        setSearch={val => this.setState({ search: val })}
                        refetchTableData={params => this.getData(params)}
                        params={this.state.params}
                        footerBtn={(
                            <Fragment>
                                {this.state.votePeriod ? (
                                    <Button
                                        variant="contained"
                                        className="add-btn-table"
                                        onClick={() => this.setState({ openStopVoting: true })}
                                        style={styles({ isMobile, isTablet }).addButton}
                                    >
                                        Stop Voting
                                    </Button>
                                ) : null}
                                {!this.state.votePeriod && !this.state.hasVotingResult ? (
                                    <Button
                                        variant="contained"
                                        className="add-btn-table"
                                        onClick={() => this.setState({ openStartVoting: true })}
                                        style={styles({ isMobile, isTablet }).addButton}
                                        disabled={this.state.totalData < 2}
                                    >
                                        Start Voting
                                    </Button>
                                ) : null}
                                {!this.state.votePeriod && this.state.hasVotingResult ? (
                                    <Fragment>
                                        <Button
                                            variant="contained"
                                            className="add-btn-table"
                                            onClick={() => this.setState({ openReopenVoting: true })}
                                            style={styles({ isMobile, isTablet }).addButton}
                                        >
                                            Reopen Voting
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="add-btn-table"
                                            onClick={() => this.setState({ openClearCandidates: true })}
                                            style={styles({ isMobile, isTablet }).addButton}
                                        >
                                            Clear Data
                                        </Button>
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        )}
                        disableAddBtn={!((!this.state.votePeriod && !this.state.hasVotingResult) || (this.state.votePeriod && this.state.hasVotingResult))}
                    />
                </Grid>

            </div>
        );
    }
}

export default Vote;