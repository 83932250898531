import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateFnsUtils from '@date-io/date-fns';
import ModalImage from "react-modal-image";
import Select from 'react-select';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import {
    FormControl,
    InputLabel,
    TextField,
    RadioGroup,
    FormGroup,
    FormLabel,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Field for basic input text
export function TextInput(props) {
    return (
        <>
            <TextField
                variant="outlined"
                multiline={props.multiline}
                rows={props.rows}
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                inputRef={props.validation}
                inputProps={props.inputProps}
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for number input
export function NumberInput(props) {
    return (
        <>
            <TextField
                type="number"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={props.onChange}
                variant="outlined"
                className={props.className}
                disabled={props.disabled}
                inputRef={props.validation}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                    inputProps: {
                        min: props.min,
                    },
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for email input
export function EmailInput(props) {
    return (
        <>
            <TextField
                type="email"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                variant="outlined"
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: "*email harus diisi",
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "*alamat email tidak valid",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for phone number input
export function PhoneInput(props) {
    return (
        <>
            <TextField
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                variant="outlined"
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: props.isNotRequired? false : "*nomor telepon harus diisi",
                    pattern: {
                        value: /^[+-]?\d+$/,
                        message: "*format tidak valid",
                    },
                    minLength: {
                        value: 8,
                        message: "*nomor telepon terlalu pendek",
                    },
                    maxLength: {
                        value: 16,
                        message: "*nomor telepon terlalu panjang",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}
export function PhoneOnChangeInput(props) {
    const numberRegex = /^[0-9]*$/;
    return (
        <>
            <TextField
                variant="outlined"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event) => {
                    const value = event.target.value;
                    if ( value ) {
                        // allow + and number as first input
                        if ( value.length === 1 ) {
                            if ( value === '+' || numberRegex.test(value) ) {
                                props.onChange(event.target.value);
                            }
                        }
                        // only allow numbers after first input
                        else if ( value.length <= 16 ) {
                            if ( numberRegex.test(value.substring(1)) ) {
                                props.onChange(event.target.value);
                            }
                        }
                    }
                    else {
                        props.onChange(event.target.value);
                    }
                }}
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: (props.requiredText),
                    pattern: {
                        value: /^[+-]?\d+$/,
                        message: "*format tidak valid",
                    },
                    minLength: {
                        value: 7,
                        message: "*nomor telepon terlalu pendek",
                    },
                    maxLength: {
                        value: 16,
                        message: "*nomor telepon terlalu panjang",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ inputMode: 'numeric' }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for date input
export function DateInput(props) {
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    name={props.name}
                    label={props.label}
                    format="dd/MM/yyyy"
                    placeholder="dd/MM/yyyy"
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    value={props.value}
                    onChange={props.onChange}
                    className={`${props.className} ${props.errors? "error-style" : ""}`}
                    disabled={props.disabled}
                    inputRef={props.validation}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    InputLabelProps={{ shrink: true }}
                    disableFuture={props.disableFuture}
                    disablePast={props.disablePast}
                />
            </MuiPickersUtilsProvider>
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for time input
export function TimeInput(props) {
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    name={props.name}
                    label={props.label}
                    value={props.value}
                    className={`${props.className} ${props.errors? "error-style" : ""}`}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    inputRef={props.validation}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    keyboardIcon={<AccessTimeIcon/>}
                />
            </MuiPickersUtilsProvider>
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for choosing one option using radio button
export function RadioButtonInput(props) {
    return (
        <FormControl component="fieldset" style={{ width: "100%" }}>
            <InputLabel className="rb-label">{props.inputLabel}</InputLabel>
            <Controller
                rules={props.rules}
                control={props.control}
                name={props.name}
                defaultValue={props.defaultValue}
                as={
                    <RadioGroup value={props.name} onChange={props.onRBChange}
                        style={{ display: "block", margin: "5px 0 20px" }}>
                        {props.options}
                    </RadioGroup>
                }
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </FormControl>
    );
}

// Field for choosing many options using checkbox
export function ChoiceboxInput(props) {
    return (
        <FormControl component="fieldset" className="cb-item" style={{ width: "100%", ...props.style }}>
            <InputLabel className="cb-label">{props.inputLabel}</InputLabel>
            <FormGroup style={{ margin: "5px 0 20px" }}>
                {props.options}
            </FormGroup>
        </FormControl>
    );
}

// Field for selection/dropdown input
export function DropdownInput(props) {
    return (
        <>
            <Controller
                name={props.name}
                as={ ({ onChange }) => 
                    (<Autocomplete
                        disableClearable={props.disableClearable}
                        options={props.options}
                        getOptionLabel={props.getOptionLabel}
                        // onChange={(_, data) => onChange(data)}
                        onChange={(_, data) => {
                            // UPDATE THE DROPDOWN VALUE
                            onChange(data);
                            // KEEP THE DATA WHEN OTHER EVENT HAPPEN
                            props.keepData(data)
                        }}
                        value={props.value}
                        defaultValue=""
                        className={`${props.className} ${props.errors? "error-style" : ""}`}
                        disabled={props.disabled}
                        style={props.style}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                inputRef={props.inputRef}
                                label={props.label}
                                placeholder="Pilih"
                                variant="outlined"
                                required={props.required}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                            />}
                    />)
                }
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for upload image and show the preview
export function ImageInput(props) {
    const [state, setState] = useState({
      image: null,
      imgPath: 'Pilih Gambar',
      canceledImage: false,
      deleteResponse: 0,
    });
    const max = 2097152;
    const defaultImage = props.defaultImg
      ? props.defaultImg
      : require("../assets/img/imageDefault.png").default;
  
    const validateImage = value => {
      return value[0] ? value[0].size <= max : props.isEdit === true;
    };

    const inputImage = event => {
      let obj = new FormData();
      obj.append("image", event.target.files[0]);
      setState({
        ...state,
        image: URL.createObjectURL(event.target.files[0]),
        formData: obj,
        imgPath: event.target.files[0].name,
        canceledImage: false,
        deleteResponse: 0,
      });
    };
  
    const clearImage = () => {
      if ( props.name === 'national_identification' || props.name === 'photo'
        || props.name === 'proof_of_payment' ) {
            alert('Foto tidak dapat dihapus! Hanya dapat melakukan penggantian foto.')
      }
      else {
          document.getElementById("selectedFile_" + props.name).value = null;
          setState({
            ...state,
            image: require("../assets/img/imageDefault.png").default,
            formData: null,
            imgPath: "Pilih Gambar",
            canceledImage: true,
            deleteResponse: 200,
          });
      }
    };
  
    return (
      <div className={props.overallClassName}>
        { defaultImage && props.disabled ?
            <div className="modal-image-div">
                <ModalImage
                    small={defaultImage}
                    large={defaultImage}
                    hideDownload={true}
                />
            </div>
        :
          <div className="img-div">
            <div className={`delete-img-btn ${props.disabled? "hide" : ""}
                ${(props.defaultImg? "" : state.canceledImage? "hide" : state.image? "" : "hide")}
                ${state.deleteResponse === 200? "hide":""} ${props.canDelete? "":"hide"}`}>
                <CloseIcon
                    onClick={() => clearImage()}
                    className="can-delete"
                />
            </div>
            <img
                // style={{ maxHeight: "10rem" }}
                src={state.image ? state.image : defaultImage}
                alt="img"
            />
          </div>
        }
          <div className={props.disabled? "" : "image-info"}>
            <p style={{
                marginBottom: 0, paddingLeft: "2px",
                textAlign: (props.disabled? "center" : "left")
            }}>
                <b>{props.label}</b>
            </p>
            <div style={{ marginBottom: "10px" }}>
                <label>
                    <input
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    size="60"
                    name={props.name}
                    onChange={inputImage}
                    disabled={props.disabled}
                    id={"selectedFile_" + props.name}
                    ref={
                        !props.isEdit
                        ? props.validation({
                            required: props.error_label + " harus diisi",
                            validate: value =>
                                validateImage(value) ||
                                "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                            })
                        : props.validation({
                            required: false,
                            validate: value =>
                                validateImage(value) ||
                                "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                            })
                    }
                    />
                    <div className={(props.disabled? "hide" : "")}>
                        <p style={{
                            marginTop: "5px", marginBottom: "10px", paddingLeft: "2px",
                            textAlign: (props.disabled? "center" : "left")
                        }}>
                            {props.ratioLabel}
                        </p>
                        <span className="img-name">{state.imgPath}</span>
                        <span className="browse-btn">Browse</span>
                    </div>
                </label>
                {props.errors && (
                    <span style={{ color:"red", width:"100%", display:"inherit" }}>
                    <small>{props.errors.message}</small>
                    </span>
                )}
            </div>
          </div>
      </div>
    );
}

// Field for url
export function URLInput(props) {
    return (
        <>
            <TextField
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                variant="outlined"
                className={props.className}
                value={props.value}
                onChange={props.onChange}
                inputRef={props.validation({
                    pattern: {
                        value: /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                        message: "*format tidak valid",
                    },
                })}
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for boolean input
export function SwitchInput(props) {
    return (
        <>
            <FormControl
                variant="outlined"
                component="fieldset"
                className={props.className}
                disabled={props.disabled}
            >
                <FormLabel component="legend" className="legend-switch">
                    {props.legend}
                </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.checked}
                                onChange={props.onChange}
                                name={props.name}
                                value={props.value}
                                inputRef={props.validation}
                            />
                        }
                        label={props.label}
                        className="fcl-switch"
                    />
                </FormGroup>
            </FormControl>
        </>
    );
}

// Field for grouped dropdown
export function GroupedDropdown(props) {
    return (
        <>
            <label className={`grouped-label ${props.labelPosition}`}>{props.label}</label>
            <Controller
                as={Select}
                name={props.name}
                maxMenuHeight={props.maxMenuHeight}
                className={props.className}
                options={props.options}
                getOptionLabel={props.getOptionLabel}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                disabled={props.disabled}
                isMulti={props.isMulti}
                isClearable={props.isClearable}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    );
}

// Field for file input
export function FileInput(props) {
    const [state, setState] = useState({
        file: null,
        filePath: "Pilih File",
    });
    const handleInputFile = event => {
        setState({
            ...state,
            file: URL.createObjectURL(event.target.files[0]),
            filePath: event.target.files[0].name,
        });
    };
    const downloadFile = () => {
        if ( props.agreementFile && props.fileName ) {
            const element = document.createElement('a')
            element.href = props.agreementFile;
            element.target = '_blank';
            element.download = props.fileName + ".pdf";
            document.body.appendChild(element);
            element.click();
        }
    };
    return (
        <div className={props.overallClassName}>
            <div className="image-info">
                <p style={{
                    marginBottom: "10px", paddingLeft: "2px",
                    textAlign: (props.disabled? "center" : "left")
                }}>
                    {/* <AttachFileIcon className="file-icon" /> */}
                    <b>{props.label}</b>
                </p>
                <div className="file-input-div">
                    <label>
                        { props.agreementFile && props.disabled ?
                            <div>
                                <span className="img-name">{props.fileName}</span>
                                <span className="browse-btn" onClick={downloadFile}>
                                    Download
                                </span>
                            </div>
                            :
                            <>
                                <input
                                    style={{ display: "none" }}
                                    accept="application/pdf,application/msword"
                                    type="file"
                                    size="60"
                                    name={props.name}
                                    onChange={handleInputFile}
                                    disabled={props.disabled}
                                    id={"selectedFile_" + props.name}
                                    ref={props.validation}
                                />
                                <div>
                                    <span className="img-name">{state.filePath}</span>
                                    <span className="browse-btn">Choose File</span>
                                </div>
                            </>
                        }
                    </label>
                    {props.errors && (
                        <span style={{ color:"red", width:"100%", display:"inherit" }}>
                        <small>{props.errors.message}</small>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}