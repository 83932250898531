import React, { useState } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import {
    Grid,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {
    Close,
    Search,
    FirstPage,
    ChevronLeft,
    ChevronRight,
    LastPage,
    ArrowUpward,
    ArrowDownward,
} from '@material-ui/icons';
import { isMobile, isTablet } from '../logic/Handler';
import { styles } from '../styles/CustomTable_Style';

const TableV2 = (props) => {
    const {
        title, path, columns, actions, data, total,
        search, setSearch, refetchTableData, params,
        footerBtn, hideFooterBtn, disableAddBtn,
    } = props;
    let { page } = useParams();
    page = Number(page);
    const history = useHistory();
    const firstData = (page * 20) + 1;
    const lastData = ((page + 1) * 20) < total ? ((page + 1) * 20) : total;
    const sortBy = params && params.sortBy ? params.sortBy : '';
    const sortDirection = params && params.sort ? params.sort : 'asc';

    const [isHover, setIsHover] = useState(false);
    const [hoveredField, setHoveredField] = useState('');

    return (
        <Grid container id='custom-table'>
            <Grid container style={styles({}).tableHeader}>
                <Grid item xs={12} sm={6} style={styles({ isMobile }).title}>
                    <h3>{title}</h3>
                </Grid>
                <Grid item xs={12} sm={6} style={styles({ isMobile }).search}>
                    <TextField
                        variant="standard"
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment style={{ padding: 8 }}>
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton
                                        onClick={() => {
                                            setSearch('');
                                            localStorage.removeItem('search');
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                background: 'white',
                                borderRadius: 3,
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ overflow: 'auto' }}>
                <table style={styles({}).tableContent}>
                    <tr>
                        {(columns.concat([{ title: 'Aksi', field: 'actions', tooltip: 'Aksi' }]) || []).map((header, i) => (
                            <th
                                key={header.field}
                                className='content-header'
                                style={styles({}).borderCell}
                            >
                                <Grid
                                    container
                                    alignItems='center'
                                    style={styles({ width: header.width }).headerCell}
                                >
                                    <Tooltip title={header.tooltip}>
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onMouseEnter={() => {
                                                setIsHover(true);
                                                setHoveredField(header.field);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHover(false);
                                                setHoveredField('');
                                            }}
                                            onClick={() => {
                                                if (i !== columns.length) {
                                                    const sortParam = params && params.sortBy && params.sortBy === header.field && params.sort === 'asc' ? 'desc' : 'asc';
                                                    history.push({ pathname: path.concat('/', 0) });
                                                    refetchTableData({
                                                        page: 1,
                                                        sortBy: header.field,
                                                        sort: sortParam,
                                                    });
                                                }
                                            }}
                                        >
                                            {header.title}
                                        </span>
                                    </Tooltip>
                                    {((isHover && i !== columns.length && hoveredField === header.field && sortDirection === 'asc') || (sortBy === header.field && sortDirection === 'asc'))
                                        ? <ArrowUpward fontSize='small' />
                                        : null
                                    }
                                    {((isHover && i !== columns.length && hoveredField === header.field && sortDirection === 'desc') || (sortBy === header.field && sortDirection === 'desc'))
                                        ? <ArrowDownward fontSize='small' />
                                        : null
                                    }
                                </Grid>
                            </th>
                        ))}
                    </tr>
                    {(data && data.length > 0) ? (data.map((val, i) => {
                        return (
                            <tr key={`${val._id}-${i}`}>
                                {(columns.concat([{ actions }]) || []).map((header, j) =>
                                    j !== (columns.length) ? (
                                        <td key={`${header.title}-${j}`} style={styles({}).borderCell}>
                                            {header.render ? header.render(val) : ((val[header.field] || val[header.field] === 0) ? val[header.field] : '-')}
                                        </td>
                                    ) : (
                                        <td key={`action-${j}`} style={styles({}).actionCell}>
                                            <div style={{ display: 'flex' }}>
                                                {(header.actions || []).map(act => {
                                                    return (
                                                        <Tooltip title={act.tooltip}>
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={() => act.onClick(null, val)}
                                                                disabled={act.disabled ? act.disabled(val) : false}
                                                            >
                                                                {act.icon()}
                                                            </IconButton>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    )
                                )}
                            </tr>
                        );
                    })) : (
                        <tbody style={styles({}).emptyTable}>
                            <td colSpan={columns.length + 1}>
                                No records to display
                            </td>
                        </tbody>
                    )}
                    {(data && data.length < 5) ? (
                        <tr style={{ height: `calc((5 - ${data.length}) * 10vh)` }}>
                            <td></td>
                        </tr>
                    ) : null}
                </table>
            </Grid>
            <Grid container style={{ ...styles({}).footer, padding: (hideFooterBtn && !footerBtn) ? 10 : 0 }}>
                <Grid item xs={12} md={7} style={styles({ isMobile, isTablet }).footerBtnSection}>
                    <NavLink
                        className="btn-link"
                        to={`${path}-form`}
                        onClick={disableAddBtn ? e => e.preventDefault() : () => {}}
                        style={styles({
                            isMobile,
                            isTablet,
                            display: hideFooterBtn ? 'none' : 'inline-flex',
                            disabled: disableAddBtn,
                        }).addNavLink}
                    >
                        <Button
                            variant="contained"
                            className="add-btn-table"
                            style={styles({ isMobile, isTablet }).addButton}
                            disabled={disableAddBtn}
                        >
                            Add
                        </Button>
                    </NavLink>
                    {footerBtn || null}
                </Grid>
                <Grid item xs={12} md={5} style={{ paddingBottom: (isMobile || isTablet) ? 5 : 0 }}>
                    <Grid container justify={(isMobile || isTablet) ? 'center' : 'flex-end'} alignItems='center'>
                        <IconButton
                            onClick={() => {
                                document.getElementById('custom-table').scrollIntoView();
                                history.push({ pathname: path.concat('/', 0) });
                                refetchTableData({ page: 1 });
                            }}
                            style={styles({}).iconButton}
                            disabled={firstData === 1}
                        >
                            <FirstPage />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                document.getElementById('custom-table').scrollIntoView();
                                history.push({ pathname: path.concat('/', page - 1) });
                                refetchTableData({ page });
                            }}
                            style={styles({}).iconButton}
                            disabled={firstData === 1}
                        >
                            <ChevronLeft />
                        </IconButton>
                        <span style={{ alignSelf: 'center' }}>
                            {`${firstData}-${lastData} of ${total}`}
                        </span>
                        <IconButton
                            onClick={() => {
                                document.getElementById('custom-table').scrollIntoView();
                                history.push({ pathname: path.concat('/', page + 1) });
                                refetchTableData({ page: page + 2 });
                            }}
                            style={styles({}).iconButton}
                            disabled={lastData >= total}
                        >
                            <ChevronRight />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                document.getElementById('custom-table').scrollIntoView();
                                const lastPage = Math.floor(total / 20);
                                history.push({ pathname: path.concat('/', lastPage) });
                                refetchTableData({ page: lastPage + 1 });
                            }}
                            style={styles({}).iconButton}
                            disabled={lastData >= total}
                        >
                            <LastPage />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TableV2;
