import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Button, Snackbar } from "@material-ui/core";
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { getLocationCategories, getLocation, editLocation } from '../../logic/APIHandler';
import { TextInput, DropdownInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailChapter(props) {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [currCateg, setCurrCateg] = useState(null);
    const [locationName, setLocationName] = useState("");
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Wilayah');
    const [pageTitle, setPageTitle] = useState('Detail Wilayah');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idLocation, setIDLocation] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        const idLocation = props.match.params.id;
        setIDLocation(idLocation);
        if ( idLocation ) {
            getCategories();
            getData(idLocation);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get location category
    const getCategories = async() => {
        setIsLoading(true);
        let resp = await getLocationCategories();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data;
            let temp = [];
            result.forEach(res => {
                temp.push({
                    label: res, value: res
                });
            });
            setCategories(temp);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }
    const getData = async id => {
        setIsLoading(true);
        let resp = await getLocation(id);
        if (resp[0] && resp[0].status === 200) {
            const loc = resp[0].data;
            setLocationName(loc.name);
            setCurrCateg({
                label: loc.category, value: loc.category
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Wilayah" ) {
            setEdit("Cancel");
            setPageTitle("Edit Wilayah")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    const onSubmit = async (data) => {
        setIsLoading(true);
        data["category"] = currCateg;
        data["name"] = locationName;
        let resp = await editLocation(idLocation, data);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else {
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Wilayah
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <DropdownInput
                            label="Kategori"
                            name="category"
                            className="full-width add-margin-bottom tf-label"
                            keepData={(data) => {
                                setCurrCateg(data);
                                if ( data.value === "Pusat" ) {
                                    setLocationName("Pusat");
                                }
                                else if ( data.value === "Luar Wilayah" ) {
                                    setLocationName("Luar Wilayah");
                                }
                            }}
                            disableClearable={true}
                            control={control}
                            options={categories}
                            getOptionLabel={(option) => option.label}
                            value={currCateg}
                            disabled={isDisabled}
                        />

                        <TextInput
                            label="Nama"
                            name="name"
                            placeholder="Nama"
                            className="full-width add-margin-bottom"
                            value={locationName}
                            onChange={(e) => setLocationName(e.target.value)}
                            validation={register({ required: "*nama wilayah harus diisi" })}
                            errors={errors.name}
                            errorClassName="text-required"
                            disabled={ isDisabled ? true : currCateg && ( currCateg.value === "Pusat"
                                ||  currCateg.value === "Luar Wilayah" ) ? true : false
                            }
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}