import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { postLocation, getLocationCategories } from '../../logic/APIHandler';
import { TextInput, DropdownInput } from '../../logic/FormInput';
import '../../styles/Form_Style.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddChapter() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [currCateg, setCurrCateg] = useState(null);
    const [locationName, setLocationName] = useState("");
    const [openJWT, setOpenJWT] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        getCategories();
    }, []);

    // Get location category
    const getCategories = async() => {
        setIsLoading(true);
        let resp = await getLocationCategories();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data;
            let temp = [];
            result.forEach(res => {
                temp.push({
                    label: res, value: res
                });
            });
            setCategories(temp);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new chapter data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        if ( data.category.value === "Pusat" ) {
            data["name"] = "Pusat";
        }
        else if ( data.category.value === "Luar Wilayah" ) {
            data["name"] = "Luar Wilayah";
        }
        let resp = await postLocation(data);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/wilayah/0");
        }
        else {
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <StatusModal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Membuat Wilayah Baru
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Wilayah Baru</h1>

                            <Grid container>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <DropdownInput
                                        label="Kategori"
                                        name="category"
                                        className="full-width add-margin-bottom tf-label"
                                        keepData={(data) => {
                                            setCurrCateg(data);
                                            if ( data.value === "Pusat" ) {
                                                setLocationName("Pusat");
                                            }
                                            else if ( data.value === "Luar Wilayah" ) {
                                                setLocationName("Luar Wilayah");
                                            }
                                        }}
                                        disableClearable={true}
                                        control={control}
                                        options={categories}
                                        getOptionLabel={(option) => option.label}
                                        value={currCateg}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Wilayah"
                                        className="full-width add-margin-bottom"
                                        value={locationName}
                                        onChange={(e) => setLocationName(e.target.value)}
                                        validation={register({ required: "*nama wilayah harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                        disabled={ currCateg && ( currCateg.value === "Pusat" || 
                                            currCateg.value === "Luar Wilayah" ) ? true : false
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}