import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import './styles/Default_Style.css';
import './App.css';

import LoginPage from './pages/Login_Page';
import ChangePasswordPage from './pages/ChangePassword_Page';
import GeneratePinPage from './pages/GeneratePin_Page';
import DashboardPage from './pages/Dashboard_Page';
import AdminPage from './pages/Admin/Admin_Page';
import DetailAdminPage from './pages/Admin/DetailAdmin_Page';
import AddAdminPage from './pages/Admin/AddAdmin_Page';
import MemberPage from './pages/Member/Member_Page';
import DetailMemberPage from './pages/Member/DetailMember_Page';
import AddMemberPage from './pages/Member/AddMember_Page';
import PengurusPage from './pages/Eksekutif/Pengurus/Pengurus_Page';
import AddPengurusPage from './pages/Eksekutif/Pengurus/AddPengurus_Page';
import DetailPengurusPage from './pages/Eksekutif/Pengurus/DetailPengurus_Page';
import PembinaPage from './pages/Eksekutif/Pembina/Pembina_Page';
import AddPembinaPage from './pages/Eksekutif/Pembina/AddPembina_Page';
import DetailPembinaPage from './pages/Eksekutif/Pembina/DetailPembina_Page';
import JabatanPage from './pages/Eksekutif/Jabatan/Jabatan_Page';
import AddJabatanPage from './pages/Eksekutif/Jabatan/AddJabatan_Page';
import DetailJabatanPage from './pages/Eksekutif/Jabatan/DetailJabatan_Page';
import EventCategoryPage from './pages/Event/EventCategory_Page';
import AddEventCategoryPage from './pages/Event/AddEventCategory_Page';
import DetailEventCategoryPage from './pages/Event/DetailEventCategory_Page';
import NextEventPage from './pages/Event/NextEvent_Page';
import AddNextEventPage from './pages/Event/AddNextEvent_Page';
import DetailNextEventPage from './pages/Event/DetailNextEvent_Page';
import PrevEventPage from './pages/Event/PrevEvent_Page';
import DetailPrevEventPage from './pages/Event/DetailPrevEvent_Page';
import MerchantBenefitPage from './pages/Mitra/Merchant/MerchantBenefit_Page';
import DetailMerchantBenefitPage from './pages/Mitra/Merchant/DetailMerchantBenefit_Page';
import AddMerchantBenefitPage from './pages/Mitra/Merchant/AddMerchantBenefit_Page';
import PartnerPage from './pages/Mitra/Partner/Partner_Page';
import DetailPartnerPage from './pages/Mitra/Partner/DetailPartner_Page';
import AddPartnerPage from './pages/Mitra/Partner/AddPartner_Page';
import SponsorPage from './pages/Mitra/Sponsor/Sponsor_Page';
import AddSponsorPage from './pages/Mitra/Sponsor/AddSponsor_Page';
import DetailSponsorPage from './pages/Mitra/Sponsor/DetailSponsor_Page';
import NewsPage from './pages/News/News_Page';
import AddNewsPage from './pages/News/AddNews_Page';
import DetailNewsPage from './pages/News/DetailNews_Page';
import VotePage from './pages/Vote/Vote_Page';
import AddVotePage from './pages/Vote/AddVote_Page';
import DetailVotePage from './pages/Vote/DetailVote_Page';
import ChapterPage from './pages/Chapter/Chapter_Page';
import AddChapterPage from './pages/Chapter/AddChapter_Page';
import DetailChapterPage from './pages/Chapter/DetailChapter_Page';

// Error Page
import UnauthorizedPage from './pages/Error/Unauthorized_Page';
import PageNotFoundPage from './pages/Error/PageNotFound_Page';

function App() {
  return (
    <Router>
      <Switch>
        {/* Overall Pages */}
        <Route exact path="/" component={LoginPage}/>
        <PrivateRoute exact path="/dashboard/:page?" access={["master","database","merchant"]} component={DashboardPage}/>
        <PrivateRoute exact path="/ganti-password" access={["master","database","merchant","event"]} component={ChangePasswordPage}/>
        {/* Admin Master Pages */}
        <PrivateRoute exact path="/admin/:page?" access={["master"]} component={AdminPage}/>
        <PrivateRoute exact path="/admin-detail/:id?" access={["master"]} component={DetailAdminPage}/>
        <PrivateRoute exact path="/admin-form" access={["master"]} component={AddAdminPage}/>
        <PrivateRoute exact path="/pengurus/:page?" access={["master"]} component={PengurusPage}/>
        <PrivateRoute exact path="/pengurus-detail/:id?" access={["master"]} component={DetailPengurusPage}/>
        <PrivateRoute exact path="/pengurus-form" access={["master"]} component={AddPengurusPage}/>
        <PrivateRoute exact path="/pembina/:page?" access={["master"]} component={PembinaPage}/>
        <PrivateRoute exact path="/pembina-detail/:id?" access={["master"]} component={DetailPembinaPage}/>
        <PrivateRoute exact path="/pembina-form" access={["master"]} component={AddPembinaPage}/>
        <PrivateRoute exact path="/jabatan/:page?" access={["master"]} component={JabatanPage}/>
        <PrivateRoute exact path="/jabatan-detail/:id?" access={["master"]} component={DetailJabatanPage}/>
        <PrivateRoute exact path="/jabatan-form" access={["master"]} component={AddJabatanPage}/>
        <PrivateRoute exact path="/partner/:page?" access={["master"]} component={PartnerPage}/>
        <PrivateRoute exact path="/partner-detail/:id?" access={["master"]} component={DetailPartnerPage}/>
        <PrivateRoute exact path="/partner-form" access={["master"]} component={AddPartnerPage}/>
        <PrivateRoute exact path="/sponsor/:page?" access={["master"]} component={SponsorPage}/>
        <PrivateRoute exact path="/sponsor-detail/:id?" access={["master"]} component={DetailSponsorPage}/>
        <PrivateRoute exact path="/sponsor-form" access={["master"]} component={AddSponsorPage}/>
        <PrivateRoute exact path="/vote/:page?" access={["master"]} component={VotePage}/>
        <PrivateRoute exact path="/vote-detail/:id?" access={["master"]} component={DetailVotePage}/>
        <PrivateRoute exact path="/vote-form" access={["master"]} component={AddVotePage}/>
        <PrivateRoute exact path="/generate-pin" access={["master"]} component={GeneratePinPage}/>
        {/* Database Pages */}
        <PrivateRoute exact path="/member/:page?" access={["master","database"]} component={MemberPage}/>
        <PrivateRoute path="/member-detail/:id?" access={["master","database"]} component={DetailMemberPage}/>
        <PrivateRoute path="/member-form" access={["master","database"]} component={AddMemberPage}/>
        <PrivateRoute exact path="/wilayah/:page?" access={["master","database"]} component={ChapterPage}/>
        <PrivateRoute exact path="/wilayah-detail/:id?" access={["master","database"]} component={DetailChapterPage}/>
        <PrivateRoute exact path="/wilayah-form" access={["master","database"]} component={AddChapterPage}/>
        {/* Merchant Pages */}
        <PrivateRoute exact path="/merchant-benefit/:page?" access={["master","merchant"]} component={MerchantBenefitPage}/>
        <PrivateRoute exact path="/merchant-benefit-detail/:id?" access={["master","merchant"]} component={DetailMerchantBenefitPage}/>
        <PrivateRoute exact path="/merchant-benefit-form" access={["master","merchant"]} component={AddMerchantBenefitPage}/>
        {/* Event Pages */}
        <PrivateRoute exact path="/kategori-acara/:page?" access={["master","event"]} component={EventCategoryPage}/>
        <PrivateRoute exact path="/kategori-acara-detail/:id?" access={["master","event"]} component={DetailEventCategoryPage}/>
        <PrivateRoute exact path="/kategori-acara-form" access={["master","event"]} component={AddEventCategoryPage}/>
        <PrivateRoute exact path="/acara-mendatang/:page?" access={["master","event"]} component={NextEventPage}/>
        <PrivateRoute exact path="/acara-mendatang-detail/:id?" access={["master","event"]} component={DetailNextEventPage}/>
        <PrivateRoute exact path="/acara-mendatang-form" access={["master","event"]} component={AddNextEventPage}/>
        <PrivateRoute exact path="/acara-lampau/:page?" access={["master","event"]} component={PrevEventPage}/>
        <PrivateRoute exact path="/acara-lampau-detail/:id?" access={["master","event"]} component={DetailPrevEventPage}/>
        <PrivateRoute exact path="/news/:page?" access={["master","event"]} component={NewsPage}/>
        <PrivateRoute exact path="/news-detail/:id?" access={["master","event"]} component={DetailNewsPage}/>
        <PrivateRoute exact path="/news-form" access={["master","event"]} component={AddNewsPage}/>
        {/* Unauthorized Route */}
        <Route exact path="/unauthorized" component={UnauthorizedPage}/>
        {/* Non-exist Route */}
        <Route path="*" exact={true} component={PageNotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
