import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Style.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { postEventCategory } from '../../logic/APIHandler';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { TextInput, NumberInput, ImageInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddEventCategory() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [score, setScore] = useState('');
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = () => setOpenSuccessAlert(false);

    // Close error toast
    const handleCloseError = () => setOpenErrorAlert(false);

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new member data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = await postEventCategory(data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/kategori-acara/0");
            }
            else {
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Membuat Kategori Acara
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        Gagal Membuat Kategori Acara
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <h1 style={{ marginBottom: "2rem" }}>Tambah Kategori Acara Baru</h1>

                        <TextInput
                            label="Nama *"
                            name="name"
                            placeholder="Nama"
                            className="full-width add-margin-bottom"
                            validation={register({ required: "*nama harus diisi" })}
                            errors={errors.name}
                            errorClassName="text-required"
                        />
                        <TextInput
                            label="Deskripsi"
                            name="description"
                            placeholder="Deskripsi"
                            className="full-width add-margin-bottom"
                            validation={register}
                            multiline={true}
                            rows={3}
                        />
                        <NumberInput
                            label="Ranking *"
                            name="score"
                            placeholder="0"
                            min={1}
                            value={score}
                            onChange={(e) => {
                                const value = e.target.value;
                                setScore(parseFloat(value) <= 0 ? '1' : setScore(value));
                            }}
                            className="full-width add-margin-bottom"
                            validation={register({ required: "*ranking kategori acara harus diisi" })}
                            errors={errors.score}
                            errorClassName="text-required"
                        />

                        {/* Input for image */}
                        <Grid item xs={12} className="left-aligned-img">
                            <ImageInput
                                name="image"
                                label="Foto"
                                validation={register}
                                errors={errors.image}
                                isEdit={true}
                                control={control}
                                defaultImg={false}
                                overallClassName="full-input-image"
                            />
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            style={{ display: "block", marginLeft: "auto" }}
                            className="add-new-btn"
                        >Submit</Button>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}