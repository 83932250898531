import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Style.css';
import city from '../../assets/data/Cities';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { postMember } from '../../logic/APIHandler';
import { Button, FormControlLabel, Grid, Radio, Checkbox, Snackbar } from '@material-ui/core';
import { TextInput, EmailInput, PhoneInput, DateInput, ImageInput,
    RadioButtonInput, ChoiceboxInput, GroupedDropdown } from '../../logic/FormInput';
import moment from 'moment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddMember() {

    // Form State
    const [cityData, setCityData] = useState([]);
    const [selectedBirthPlace, setSelectedBirthPlace] = useState(null);
    const [selectedDomicile, setSelectedDomicile] = useState(null);
    const [gender, setGender] = useState('male');
    const [selectedDate, setDate] = useState(moment());
    const currMembership = { label: "Silver", value: "silver" };

    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        let res = [];
        city.forEach(val => {
            res.push({
                value: val, label: val,
            });
        });
        setCityData(res);
        setIsLoading(false);
    }, []);

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Handle (gender) radio button event
    const onRBChange = (event) => {
        setGender(event.target.value);
    }

    // Handle when date is changed
    const handleDateChange = (date) => {
        setDate(moment(date));
    };

    // Handle onChange autocomplete
    const handlePlaceOfBirth = value => {
        setSelectedBirthPlace(value);
    }
    const handleDomicile = value => {
        setSelectedDomicile(value);
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new member data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            if ( selectedBirthPlace == null || selectedDomicile == null ) {
                if (selectedBirthPlace == null) {
                    setErrorText("Input untuk 'Tempat Lahir' belum diisi");
                    setOpenErrorAlert(true);
                }
                else if ( selectedDomicile == null ) {
                    setErrorText("Input untuk 'Kota Domisili' belum diisi");
                    setOpenErrorAlert(true);
                }
            }
            else {
                setIsLoading(true);
                let resp = await postMember(data, selectedBirthPlace.value, selectedDomicile.value);
                if (resp[0]) {
                    setOpenSuccessAlert(true);
                    await sleep(1000);
                    history.replace("/member/0");
                }
                else {
                    console.log(resp);
                    if ( resp[1].data && resp[1].data[0].msg ) {
                        setErrorText(resp[1].data[0].msg);
                    }
                    else {
                        setErrorText(resp[1].message);
                    }
                    setOpenErrorAlert(true);
                    setIsLoading(false);
                }
            }
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Membuat Member
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Member Baru</h1>
                            
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for name */}
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama"
                                        className="form-left-field"
                                        validation={register({ required: "*nama harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    {/* Input for membership */}
                                    <GroupedDropdown
                                        label="Jenis Keanggotaan"
                                        labelPosition="half-right-label"
                                        name="membership"
                                        className="form-right-field add-margin-bottom grouped-field"
                                        control={control}
                                        options={[
                                            { label: "Honorary", value: "honorary" },
                                            { label: "Silver", value: "silver" },
                                            { label: "Platinum", value: "platinum" },
                                        ]}
                                        defaultValue={currMembership}
                                        isClearable={false}
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for job */}
                            <TextInput
                                label="Bidang Usaha *"
                                name="job"
                                placeholder="Bidang Usaha"
                                className="full-width add-margin-bottom"
                                validation={register({ required: "*bidang usaha harus diisi" })}
                                errors={errors.job}
                                errorClassName="text-required"
                            />

                            <Grid container>
                                <Grid item xs={12} md={6} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for place of birth */}
                                    <label className="grouped-label">Tempat Lahir *</label>
                                    <Select
                                        value={selectedBirthPlace}
                                        onChange={handlePlaceOfBirth}
                                        options={cityData}
                                        className="form-left-field add-margin-bottom grouped-field"
                                        isClearable={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    {/* Input for date of birth */}
                                    <DateInput
                                        label="Tanggal Lahir"
                                        name="date_of_birth"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        className="form-right-field"
                                        validation={register({ register: "*tanggal lahir harus diisi" })}
                                        errors={errors.date_of_birth}
                                        errorClassName="text-required-half half-right"
                                        disableFuture={true}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ display: selectedBirthPlace &&
                                    selectedBirthPlace.value == "Lainnya"? "block" : "none" }}
                                >
                                    {/* Free text input for place of birth */}
                                    <TextInput
                                        label="Nama Tempat Lahir *"
                                        name="place_of_birth"
                                        placeholder="Nama Tempat Lahir"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: 
                                            selectedBirthPlace && selectedBirthPlace.value == "Lainnya"?
                                                "*nama kota harus diisi" : false
                                        })}
                                        errors={errors.place_of_birth}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for gender */}
                            <RadioButtonInput
                                inputLabel="Jenis Kelamin *"
                                control={control}
                                name="gender"
                                rules={{ required: "*jenis kelamin harus diisi" }}
                                errors={errors.gender}
                                errorClassName="text-required"
                                onRBChange={onRBChange}
                                options={
                                    <Grid container className="rb-layout">
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio />}
                                                label="Laki-laki"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio />}
                                                label="Perempuan"
                                                style={{ marginLeft: "0" }}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for phone number */}
                                    <PhoneInput
                                        label="Nomor Kontak *"
                                        name="phone_number"
                                        placeholder="Nomor Kontak"
                                        className="form-left-field"
                                        validation={register}
                                        errors={errors.phone_number}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for email */}
                                    <EmailInput
                                        label="Email *"
                                        name="email"
                                        placeholder="Email"
                                        className="form-right-field"
                                        validation={register}
                                        errors={errors.email}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for domicile */}
                                    <label className="grouped-label">Kota Domisili *</label>
                                    <Select
                                        value={selectedDomicile}
                                        onChange={handleDomicile}
                                        options={cityData}
                                        className="full-width add-margin-bottom grouped-field"
                                        isClearable={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Alamat Domisili *"
                                        name="address"
                                        placeholder="Alamat Domisili"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat domisili harus diisi" })}
                                        errors={errors.address}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Alamat Pengiriman *"
                                        name="shipping_address"
                                        placeholder="Alamat Pengiriman"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat pengiriman harus diisi" })}
                                        errors={errors.shipping_address}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: selectedDomicile &&
                                    selectedDomicile.value == "Lainnya"? "block" : "none" }}
                                >
                                    {/* Free text input for domicile */}
                                    <TextInput
                                        label="Nama Domisili *"
                                        name="city"
                                        placeholder="Nama Domisili"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: 
                                            selectedDomicile && selectedDomicile.value == "Lainnya"?
                                                "*nama domisili harus diisi" : false
                                        })}
                                        errors={errors.city}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for join reason */}
                            <ChoiceboxInput
                                inputLabel="Alasan Bergabung"
                                options={
                                    <Grid container className="cb-layout">
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                value="Menambah Networking"
                                                control={<Checkbox/>}
                                                label="Menambah Networking"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                            <FormControlLabel
                                                value="Menambah Konsumen Potensial"
                                                control={<Checkbox/>}
                                                label="Menambah Konsumen Potensial"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                            <FormControlLabel
                                                value="Mengembangkan Usaha"
                                                control={<Checkbox/>}
                                                label="Mengembangkan Usaha"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingLeft: "20px" }}>
                                            <FormControlLabel
                                                value="Menambah Suplier Potensial"
                                                control={<Checkbox/>}
                                                label="Menambah Suplier Potensial"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                            <FormControlLabel
                                                value="Mencari Rekan Usaha"
                                                control={<Checkbox/>}
                                                label="Mencari Rekan Usaha"
                                                name="join_reason"
                                                inputRef={register}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    {/* Input for reference */}
                                    <GroupedDropdown
                                        label="Referensi *"
                                        name="reference"
                                        className="form-left-field add-margin-bottom grouped-field"
                                        rules={{ required: "*referensi harus diisi" }}
                                        control={control}
                                        options={[
                                            { label: "Anggota", value: "member" },
                                            { label: "Media Sosial", value: "social-media" },
                                            { label: "Lainnya", value: "other" },
                                        ]}
                                        isClearable={true}
                                        defaultValue=""
                                        errors={errors.reference}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for reference source */}
                                    <TextInput
                                        label="Sumber Referensi (Nama Anggota/Sosmed/Lainnya) *"
                                        name="reference_source"
                                        placeholder="Sumber Referensi"
                                        className="form-right-field"
                                        validation={register({ required: "*sumber referensi harus diisi" })}
                                        errors={errors.reference_source}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <GroupedDropdown
                                        label="Ukuran Baju *"
                                        name="clothes_size"
                                        className="full-width add-margin-bottom grouped-field"
                                        control={control}
                                        options={[
                                            { label: "XS", value: "XS" },
                                            { label: "S", value: "S" },
                                            { label: "M", value: "M" },
                                            { label: "L", value: "L" },
                                            { label: "XL", value: "XL" },
                                            { label: "XXL", value: "XXL" },
                                            { label: "3XL", value: "3XL" },
                                        ]}
                                        rules={{ required: "*ukuran baju harus diisi" }}
                                        errors={errors.clothes_size}
                                        errorClassName="text-required"
                                        defaultValue=''
                                        isClearable={false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: "20px" }}>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="national_identification"
                                        label="Foto KTP *"
                                        validation={register}
                                        errors={errors.national_identification}
                                        isEdit={false}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={false}
                                        error_label="* foto KTP"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="photo"
                                        label="Foto *"
                                        validation={register}
                                        errors={errors.photo}
                                        isEdit={false}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={false}
                                        error_label="* foto"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="proof_of_payment"
                                        label="Bukti Bayar *"
                                        validation={register}
                                        errors={errors.proof_of_payment}
                                        isEdit={false}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={false}
                                        error_label="* bukti bayar"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}