import React, { useState, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, TextField, Button, Snackbar, } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Sidebar from "../components/Sidebar_Com";
import Spinner from "../components/Loading_Com";
import Modal from "../components/Modal_Com";
import { generatePin } from "../logic/APIHandler";
import "../styles/Form_Style.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GeneratePinPage() {

    // Page State
    const [pin, setPin] = useState("");
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };
    
    // Copy invitation link to clipboard
    const copyGeneratedPin = () => {
        navigator.clipboard.writeText(pin);
        setOpenSuccessAlert(true);
    }

    // Generate new pin
    const handleGeneratePin = async () => {
        setIsLoading(true);
        let resp = await generatePin();
        if (resp[0] && resp[0].status === 200) {
            navigator.clipboard.writeText(resp[0].data.value);
            setPin(resp[0].data.value);
            setIsLoading(false);
            setOpenSuccessAlert(true);
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar
                isMobile={(windowWidth < 768)? true : false}
                user={localStorage.getItem("user")}
            />
            
            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Copied to clipboard!
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form className="add-form">
                        <h1 style={{ marginBottom: 0 }}>
                            Generate Pin Master
                        </h1>
                        <p className="generate-pin-notes">
                            Pin ini tidak memiliki tanggal kadaluarsa, tetapi hanya dapat digunakan <b>1 kali</b>.
                        </p>

                        <Button
                            variant="contained"
                            style={{ display: "block", marginLeft: "auto" }}
                            className="add-new-btn add-margin-bottom"
                            onClick={handleGeneratePin}
                        >Submit</Button>
                        
                        {pin?
                            <>
                                <h3 className="text-bold" style={{ marginTop: "10px" }}>Result</h3>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            value={pin}
                                            className="copy-clipboard-area add-margin-bottom"
                                            multiline={true}
                                            rows={3}
                                            disabled={true}
                                        />
                                        <Button
                                            variant="contained"
                                            className="generate-btn copy"
                                            startIcon={<FileCopyIcon />}
                                            onClick={copyGeneratedPin}
                                        >Copy</Button>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>
                        }
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}