import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Grid, TextField, Button, InputAdornment,
    IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { hitLogin } from '../logic/APIHandler';
import Spinner from '../components/Loading_Com';
import '../styles/Login_Styles.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage(props) {

    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if ( localStorage.getItem("token") ) {
            if ( localStorage.getItem("admin") !== "event" ) {
                history.push("/dashboard/0");
            }
            else {
                history.push("/acara-mendatang/0");
            }
        }
    }, []);

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Toggle show password
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    // Close error toast
    const handleCloseError = () => {
        setOpenErrorAlert(false);
    };

    const onSubmit = async data => {
        setIsLoading(true);
        let resp = await hitLogin(data);
        if ( resp[0] ) {
            const result = resp[0].data;
            localStorage.setItem('token', result.token);
            localStorage.setItem('admin', result.level);
            await sleep(1000);
            if ( result.level !== "event" ) {
                history.push("/dashboard/0");
            }
            else {
                history.push("/acara-mendatang/0");
            }
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    if ( localStorage.getItem("token") ) {
        return null;
    }
    else {
        return (
            <Container className="login-container">
                { isLoading ? ( <Spinner /> ) : "" }
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid container>
                    <Grid item xs={12}>
                        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <h1>IDEPRENEURS</h1>
                            <TextField
                                label="Email"
                                name="email"
                                placeholder="Email"
                                className="login-input"
                                inputRef={register}
                            />
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                name="password"
                                placeholder="Password"
                                className="login-input"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                            >
                                                {showPassword? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputRef={register}
                            />
                            {/* <Link href="#" className="forgot-password-link">Forgot password?</Link> */}
    
                            <Button type="submit" variant="contained" id="login-btn">
                                Submit
                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}