import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Style.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { postAdmin } from '../../logic/APIHandler';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { TextInput, DropdownInput, EmailInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddAdmin() {

    // Modal State
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const [currLevel, setCurrLevel] = useState(null);
    const windowWidth = window.innerWidth;

    // Close success toast
    const handleCloseSuccess = () => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = () => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new member data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postAdmin(data);
        if (resp[0] && resp[0].status === 200) {
            setEmail(resp[0].data.email);
            setPassword(resp[0].data.password);
            setIsLoading(false);
            setOpenSuccessAlert(true);
            setOpen(true);
        }
        else {
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <StatusModal
                    open={open}
                    modalType="admin-info"
                    color="primary"
                    modalTitle="Informasi Akun"
                    emailCallback={email}
                    passwordCallback={password}
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuat Admin
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            Gagal Membuat Admin
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Admin Baru</h1>

                            {/* Input for admin level */}
                            <DropdownInput
                                label="Jenis Admin"
                                name="level"
                                className="full-width add-margin-bottom tf-label"
                                keepData={(data) => setCurrLevel(data)}
                                disableClearable={true}
                                control={control}
                                options={[
                                    { name: "Database", value: "database" },
                                    { name: "Merchant", value: "merchant" },
                                    { name: "Acara", value: "event" },
                                ]}
                                getOptionLabel={(option) => option.name}
                                value={currLevel}
                                required={true}
                            />
                            {/* Input for name */}
                            <TextInput
                                label="Nama *"
                                name="name"
                                placeholder="Nama"
                                className="full-width add-margin-bottom"
                                validation={register({ required: "*nama harus diisi" })}
                                errors={errors.name}
                                errorClassName="text-required"
                            />
                            {/* Input for email */}
                            <EmailInput
                                label="Email *"
                                name="email"
                                placeholder="Email"
                                className="full-width add-margin-bottom"
                                validation={register}
                                errors={errors.email}
                                errorClassName="text-required"
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}