import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Style.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { getSpecificNews, editNews } from '../../logic/APIHandler';
import { TextInput, ImageInput, URLInput, DropdownInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailNews(props) {

    // Data State
    const [idNews, setIDNews] = useState(null);
    const [isEvent, setIsEvent] = useState(true);
    const [currPosition, setCurrPosition] = useState({ name: "Kiri", value: "left" });
    const [state, setState] = useState({
        banner: '',
    });

    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit News');
    const [pageTitle, setPageTitle] = useState('Detail News');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        const idNews = props.match.params.id;
        setIDNews(idNews);
        if ( idNews ) {
            getData(idNews);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get news's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getSpecificNews(id);
        if (resp[0] && resp[0].status === 200) {
            const news = resp[0].data;
            setValue("name", news.name);
            const category = news.category.charAt(0).toUpperCase() + news.category.slice(1);
            setValue("category", category);
            setValue("title", news.title? news.title : "");
            setValue("sub_title", news.sub_title? news.sub_title : "");
            setValue("description", news.description? news.description : "");
            if ( news.position ) {
                if ( news.position === "left" )
                    setCurrPosition({ name: "Kiri", value: "left" })
                else if ( news.position === "center" )
                    setCurrPosition({ name: "Tengah", value: "center" })
                else
                    setCurrPosition({ name: "Kanan", value: "right" })
            }
            if ( news.event_id ) {
                setValue("event_id", news.event_id.name);
            }
            setValue("link", news.link);

            if ( category !== "Event" ) setIsEvent(false);
            setState({
                ...state,
                banner: (news.banner !== "undefined")? news.banner : "",
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit News" ) {
            setEdit("Cancel");
            setPageTitle("Edit News")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data news to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            data["position"] = currPosition.value;
            let resp = await editNews(idNews, data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Berita
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                                {/* Input for name */}
                                <TextInput
                                    label="Nama"
                                    name="name"
                                    placeholder="Nama"
                                    className="form-left-field add-margin-bottom"
                                    validation={register({ required: "*nama harus diisi" })}
                                    errors={errors.name}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DropdownInput
                                    label="Posisi"
                                    name="position"
                                    className="form-right-field add-margin-bottom tf-label"
                                    keepData={(data) => setCurrPosition(data)}
                                    disableClearable={true}
                                    control={control}
                                    options={[
                                        { name: "Kiri", value: "left" },
                                        { name: "Tengah", value: "center" },
                                        { name: "Kanan", value: "right" },
                                    ]}
                                    getOptionLabel={(option) => option.name}
                                    value={currPosition}
                                    disabled={isDisabled}
                                />
                                {/* <TextInput
                                    label="Kategori"
                                    name="category"
                                    placeholder="Kategori"
                                    className="form-right-field add-margin-bottom"
                                    validation={register}
                                    errors={errors.category}
                                    errorClassName="text-required-half"
                                    disabled={true}
                                /> */}
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for title */}
                                <TextInput
                                    label="Judul"
                                    name="title"
                                    placeholder="Judul News"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for sub-title */}
                                <TextInput
                                    label="Sub Judul"
                                    name="sub_title"
                                    placeholder="Sub Judul News"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for description */}
                                <TextInput
                                    label="Deskripsi"
                                    name="description"
                                    placeholder="Deskripsi"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    multiline={true}
                                    rows={3}
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        {/* Event name data */}
                        { isEvent?
                            <TextInput
                                label="Nama Acara"
                                name="event_id"
                                placeholder="Nama Acara"
                                validation={register}
                                className="full-width add-margin-bottom"
                                disabled={true}
                            /> : ""
                        }

                        {/* Input for address link */}
                        <URLInput
                            label="Link"
                            name="link"
                            placeholder="Link"
                            className="full-width add-margin-bottom"
                            validation={register}
                            errors={errors.link}
                            errorClassName="text-required"
                            disabled={isDisabled}
                        />

                        {/* Input for image */}
                        <ImageInput
                            name="banner"
                            label="Banner"
                            ratioLabel="Ratio 8:3"
                            validation={register}
                            isEdit={true}
                            control={control}
                            defaultImg={state.banner}
                            canDelete={state.banner? false : true}
                            disabled={isEvent? true : isDisabled}
                            overallClassName="full-input-image eight-three-size"
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}