import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Button, Snackbar } from "@material-ui/core";
import {
  getEvent,
  postImageToGallery,
  deleteImageInGallery,
  editEvent
} from "../../logic/APIHandler";
import Sidebar from "../../components/Sidebar_Com";
import Spinner from "../../components/Loading_Com";
import StatusModal from "../../components/Modal_Com";
import "../../styles/Form_Style.css";
import {
  TextInput,
  DateInput,
  TimeInput,
  ImageInput,
  URLInput
} from "../../logic/FormInput";
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailPrevEvent(props) {
  const history = useHistory();
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [openJWT, setOpenJWT] = useState(false);
  const [editGallery, setEditGallery] = useState("Edit Galeri");
  const [hide, setHide] = useState("hide");
  const [nonHide, setNonHide] = useState("");
  const [hideEvent, setHideEvent] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [idEvent, setIDEvent] = useState(null);
  const [inputList, setInputList] = useState([]);
  const [images, setImages] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState("slide-out");
  const windowWidth = window.innerWidth;
  const [state, setState] = useState({
    selectedDate: moment(),
    selectedTime: moment(),
    banner: "",
    thumbnail: "",
    counter: 0
  });

  // Method that run first when the page is opened
  useEffect(() => {
    const idEvent = props.match.params.id;
    setIDEvent(idEvent);
    if (idEvent) {
      getData(idEvent);
    } else {
      console.log("ID not found");
    }
  }, [props.match.params.id]);

  // Handle toggle edit gallery button
  const onClickEditGallery = () => {
    if (editGallery === "Edit Galeri") {
      setEditGallery("Cancel");
      setHide("");
      setNonHide("");
      setHideEvent("hide");
      setIsDisabled(false);
    } else {
      history.go(0);
    }
  };

  // Get event's data from API
  const getData = async (id) => {
    setIsLoading(true);
    let resp = await getEvent(id);
    if (resp[0] && resp[0].status === 200) {
      const event = resp[0].data;
      setValue("name", event.name);
      setValue("event_category", event.event_categories.name);
      setValue("description", event.description);
      setValue("pic", event.pic);
      setValue("pic_contact", event.pic_contact);
      let eventDate = event.date.substring(0, 10);
      setValue("date", eventDate);
      let formatedDate = event.date.substring(0, 19);
      setValue("address", event.address);
      setValue("address_link", event.address_link);
      setValue("video_url", event.video_url);
      setValue("city", event.city);
      setValue(
        "categories",
        event.location_id ? event.location_id.category : ""
      );
      setValue("location", event.location_id ? event.location_id.name : "");
      if (event.report) {
        setValue("report", event.report);
      } else {
        setShowReportModal(true);
      }
      if (event.gallery.length > 0) {
        let galleries = [];
        for (let i = 0; i < event.gallery.length; i++) {
          galleries.push({ gallery: event.gallery[i] });
        }
        setInputList([...inputList, ...galleries]);
      }
      setState({
        ...state,
        selectedDate: formatedDate,
        selectedTime: moment(event.date),
        banner: event.banner !== "undefined" ? event.banner : "",
        thumbnail: event.thumbnail !== "undefined" ? event.thumbnail : "",
        counter: event.gallery.length
      });
    } else if (resp[1] && resp[1].status === 401) {
      setIsLoading(false);
      setOpenJWT(true);
    } else {
      console.log(resp);
      if (resp[1].data && resp[1].data[0].msg) {
        setErrorText(resp[1].data[0].msg);
      } else {
        setErrorText(resp[1].message);
      }
      setOpenErrorAlert(true);
    }
    setIsLoading(false);
  };

  // handle click event of the Remove button & remove image using API
  const handleRemoveClick = async (index) => {
    setIsLoading(true);
    let updatedImages = images.filter((image) => image.idx !== index);
    let resp = await deleteImageInGallery(idEvent, index);
    if (resp[0] && resp[0].status === 200) {
      setOpenSuccessAlert(true);
    } else {
      console.log("Delete non-submited image");
    }
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    updatedImages.forEach((res) => {
      if (res["idx"] > index) {
        res["idx"] -= 1;
      }
    });
    setImages(updatedImages);
    setState({
      ...state,
      counter: state.counter - 1
    });
    setIsLoading(false);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    if (state.counter < 6) {
      setInputList([...inputList, { gallery: "" }]);
      setState({
        ...state,
        counter: state.counter + 1
      });
    }
  };
  // Handle event when image is assigned
  const handleChange = (event) => {
    // If image file size is less than 25MB then pass
    if (event.target.files[0].size < 26214400) {
      const temp = [];
      inputList.forEach((val) => {
        if (val.gallery !== "") {
          temp.push({ gallery: val.gallery });
        }
      });
      temp.push({ gallery: URL.createObjectURL(event.target.files[0]) });
      setInputList([...temp]);
      setImages([
        ...images,
        { gallery: event.target.files[0], idx: inputList.length - 1 }
      ]);
    } else {
      setErrorText("Ukuran gambar harus lebih kecil dari 25MB!");
      setOpenErrorAlert(true);
    }
  };

  // Make the website stay still for __ ms
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // Close toast
  const handleCloseAlert = () => {
    setOpenSuccessAlert(false);
    setOpenErrorAlert(false);
  };

  // Toggle sidebar menu in responsive
  const toggleSidebar = () => {
    if (isSidebarOpen === "slide-out") {
      setIsSidebarOpen("slide-in");
    } else {
      setIsSidebarOpen("slide-out");
    }
  };

  // Update past event's report then update gallery
  const onSubmitReport = async (data) => {
    setIsLoading(true);
    let resp = await editEvent(idEvent, data, null, true);
    if (resp[0] && resp[0].status === 200) {
      onSubmitGallery();
    } else {
      console.log(resp);
      if (resp[1].data && resp[1].data[0].msg) {
        setErrorText(resp[1].data[0].msg);
      } else {
        setErrorText(resp[1].message);
      }
      setOpenErrorAlert(true);
      setIsLoading(false);
    }
  };

  // Send updated event's gallery to server
  const onSubmitGallery = async () => {
    if (images) {
      let resp = await postImageToGallery(idEvent, images);
      if (resp[0] && resp[0].status === 200) {
        setOpenSuccessAlert(true);
        await sleep(1000);
        history.go(0);
      } else {
        console.log(resp);
        if (resp[1].data && resp[1].data[0].msg) {
          setErrorText(resp[1].data[0].msg);
        } else {
          setErrorText(resp[1].message);
        }
        setOpenErrorAlert(true);
        setIsLoading(false);
      }
    } else {
      setErrorText("Tidak ada gambar yang dimasukkan.");
      setOpenErrorAlert(true);
      setIsLoading(false);
    }
  };

  return (
    <div id="detail-prev-event" className="content">
      {isLoading ? <Spinner /> : ""}
      <Sidebar sidebarState={windowWidth < 768 ? isSidebarOpen : ""} />

      <StatusModal
        open={openJWT}
        modalType="handle-jwt"
        modalTitle="Token Anda Sudah Expire"
      />
      <StatusModal
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        onClickCancel={() => setShowReportModal(false)}
        modalType="is-report-filled"
        id={idEvent}
        color="primary"
        modalTitle="Laporan Kegiatan Belum Diisi"
        submitText="Ya"
        cancelText="Tidak"
        goToForm={() => {
          onClickEditGallery();
          setShowReportModal(false);
          window.scrollTo(0, 0);
        }}
      />

      <Grid container className="responsive-header">
        <Grid item xs={6}>
          <h2>IDEPRENEURS</h2>
        </Grid>
        <Grid item xs={6} className="menu-icon-btn">
          <Button variant="outlined" onClick={toggleSidebar}>
            {isSidebarOpen === "slide-out" ? <MenuIcon /> : <CloseIcon />}
          </Button>
        </Grid>
      </Grid>

      <Grid container className="grid-container">
        <Snackbar
          open={openSuccessAlert}
          autoHideDuration={2500}
          onClose={handleCloseAlert}
        >
          <Alert severity="success">Berhasil Update Galeri Acara</Alert>
        </Snackbar>
        <Snackbar
          open={openErrorAlert}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
        >
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>
        <Grid item xs={12} className={hideEvent}>
          <form className="add-form">
            <Grid container>
              <Grid item xs={12}>
                <h1 style={{ marginBottom: "2rem" }}>Detail Acara</h1>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for category name */}
                <TextInput
                  label="Nama"
                  name="name"
                  placeholder="Nama"
                  className="form-left-field"
                  validation={register({ required: "*nama harus diisi" })}
                  errors={errors.name}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for event category */}
                <TextInput
                  label="Kategori Acara"
                  name="event_category"
                  placeholder="Kategori Acara"
                  validation={register}
                  className="form-right-field"
                  disabled={true}
                />
              </Grid>
            </Grid>

            <TextInput
              multiline={true}
              rows={3}
              label="Deskripsi"
              name="description"
              placeholder="Deskripsi"
              className="full-width add-margin-bottom"
              validation={register({ required: "*deskripsi harus diisi" })}
              errors={errors.description}
              errorClassName="text-required"
              disabled={true}
            />

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for PIC */}
                <TextInput
                  label="PIC"
                  name="pic"
                  placeholder="PIC"
                  className="form-left-field"
                  validation={register}
                  errors={errors.pic}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for PIC contact number */}
                <TextInput
                  label="Nomor Kontak PIC"
                  name="pic_contact"
                  placeholder="Nomor Kontak PIC"
                  className="form-right-field"
                  validation={register}
                  errors={errors.pic_contact}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for event date */}
                <DateInput
                  label="Tanggal Acara"
                  name="date"
                  value={state.selectedDate}
                  className="form-left-field"
                  validation={register}
                  errors={errors.date}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for event time */}
                <TimeInput
                  label="Waktu Acara"
                  name="time"
                  value={state.selectedTime}
                  className="form-right-field"
                  validation={register}
                  errors={errors.time}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for address */}
                <TextInput
                  label="Lokasi Acara"
                  name="address"
                  placeholder="Lokasi Acara"
                  className="form-left-field"
                  validation={register}
                  errors={errors.address}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for address link */}
                <URLInput
                  label="Link Lokasi"
                  name="address_link"
                  placeholder="Link Lokasi"
                  className="form-right-field"
                  validation={register}
                  errors={errors.address_link}
                  errorClassName="text-required-half"
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextInput
                  label="Kota Tempat Acara"
                  name="city"
                  placeholder="Kota Tempat Acara"
                  className="full-width add-margin-bottom"
                  validation={register}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Kategori Wilayah"
                  name="categories"
                  placeholder="Kategori Wilayah"
                  className="form-left-field add-margin-bottom"
                  validation={register}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Nama Wilayah"
                  name="location"
                  placeholder="Nama Wilayah"
                  className="form-right-field add-margin-bottom"
                  validation={register}
                  disabled={true}
                />
              </Grid>
            </Grid>

            {/* Input for video URL */}
            <URLInput
              label="URL Video"
              name="video_url"
              placeholder="URL Video"
              className="full-width add-margin-bottom"
              validation={register}
              errors={errors.video_url}
              errorClassName="text-required"
              disabled={true}
            />

            <Grid
              container
              className={`
                            gallery-section add-margin-bottom
                            ${isDisabled ? "disabled-border" : ""}
                        `}
            >
              <Grid item xs={12}>
                <h2>Banner & Thumbnail</h2>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                {/* Input for banner image */}
                <ImageInput
                  name="banner"
                  label="Banner"
                  validation={register}
                  errors={errors.banner}
                  isEdit={true}
                  control={control}
                  defaultImg={state.banner}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                {/* Input for thumbnail image */}
                <ImageInput
                  name="thumbnail"
                  label="Thumbnail"
                  validation={register}
                  errors={errors.thumbnail}
                  isEdit={true}
                  control={control}
                  defaultImg={state.thumbnail}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>

        {/* -------------------------------------------------------------------------------------- */}

        <Grid item xs={12} className={nonHide} style={{ marginTop: "30px" }}>
          <form onSubmit={handleSubmit(onSubmitReport)} className="add-form">
            <Grid container className={nonHide}>
              <Grid item xs={12}>
                <h1>Laporan Acara</h1>
                <TextInput
                  multiline={true}
                  rows={3}
                  label="Laporan Kegiatan *"
                  name="report"
                  placeholder="Laporan Kegiatan"
                  className="full-width add-margin-bottom"
                  validation={register({
                    required: "*laporan kegiatan harus diisi"
                  })}
                  errors={errors.report}
                  errorClassName="text-required"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <h1 className={hide === "" ? "no-margin-bottom" : ""}>
                  Galeri
                </h1>
                <p className={`input-img-terms ${hide}`}>
                  * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ padding: "30px 30px 0" }}
              className={`
                            gallery-section add-margin-bottom 
                            ${isDisabled ? "disabled-border" : ""} 
                            ${nonHide}
                        `}
            >
              {/* Button show when there is no image */}
              <Grid item xs={12} className={hideEvent}>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: "2rem",
                    textAlign: "center"
                  }}
                >
                  Belum ada foto yang dimasukkan
                </p>
                {/* <Button
                                    variant="contained"
                                    className={`add-gallery-null ${(state.counter > 0)? "hide":""}`}
                                    onClick={onClickEditGallery}
                                >
                                    Tambah Foto
                                </Button> */}
              </Grid>

              {/* Input for image */}
              {inputList.map((value, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={`gallery-${index}`}
                    style={{ marginBottom: "25px" }}
                  >
                    <ImageInput
                      name={`gallery${index}`}
                      label={`Foto ${index + 1}`}
                      validation={register}
                      isEdit={true}
                      control={control}
                      defaultImg={value.gallery}
                      disabled={true}
                      hideDeleteBtn="hide"
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      className={`delete-btn-gallery ${hide}`}
                      onClick={() => handleRemoveClick(index)}
                    >
                      Remove
                    </Button>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={4}>
                <label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <span
                    onClick={handleAddClick}
                    className={`add-new-gallery ${hide} ${
                      state.counter < 6 ? "" : "hide"
                    }`}
                  >
                    +
                  </span>
                </label>
              </Grid>
            </Grid>

            <Grid container>
              {/* Preview Mode Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={`edit-btn ${hideEvent}`}
                  onClick={onClickEditGallery}
                >
                  {editGallery}
                </Button>
              </Grid>
              {/* Edit Mode Buttons */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={onClickEditGallery}
                  className={`add-cancel-btn ${hide} ${nonHide}`}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ float: "right", marginRight: "15px" }}
                  className={`add-new-btn responsive-submit-btn ${hide}`}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
