import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput, URLInput, PhoneOnChangeInput } from '../../../logic/FormInput';

export const renderFormFields = (
    level, register, errors, waNumber, setWANumber, description, wordCounter,
    instagram, facebook, twitter, website, tiktok, onChangeHandler, isDisabled
) => {
    let result = null;
    switch (level) {
        case 'Bronze': {
            result = (
                <Grid item xs={12}>
                    <URLInput
                        label="Instagram"
                        name="instagram"
                        placeholder="Link Instagram"
                        value={instagram}
                        onChange={(e) => onChangeHandler('instagram', e.target.value)}
                        className="full-width add-margin-bottom"
                        validation={register}
                        disabled={isDisabled}
                    />
                </Grid>
            );
            break;
        }
        case 'Silver': {
            result = (
                <>
                    <Grid item xs={12}>
                        <TextInput
                            multiline={true}
                            rows={3}
                            label="Deskripsi"
                            name="description"
                            placeholder="Deskripsi"
                            value={description}
                            onChange={(e) => onChangeHandler('desc', e.target.value)}
                            className="full-width add-margin-bottom"
                            inputProps={{ maxLength: 126 }}
                            validation={register}
                            disabled={isDisabled}
                        />
                        { !isDisabled && <span className='character-counter'>{wordCounter}/126</span> }
                    </Grid>
                    <Grid item xs={12}>
                        <URLInput
                            label="Instagram"
                            name="instagram"
                            placeholder="Link Instagram"
                            value={instagram}
                            onChange={(e) => onChangeHandler('instagram', e.target.value)}
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                </>
            );
            break;
        }
        case 'Gold': {
            result = (
                <>    
                    <Grid item xs={12}>
                        <TextInput
                            multiline={true}
                            rows={3}
                            label="Deskripsi"
                            name="description"
                            placeholder="Deskripsi"
                            value={description}
                            onChange={(e) => onChangeHandler('desc', e.target.value)}
                            className="full-width add-margin-bottom"
                            inputProps={{ maxLength: 256 }}
                            validation={register}
                            disabled={isDisabled}
                        />
                        { !isDisabled && <span className='character-counter'>{wordCounter}/256</span> }
                    </Grid>
                    <Grid item xs={12}>
                        <URLInput
                            label="Instagram"
                            name="instagram"
                            placeholder="Link Instagram"
                            value={instagram}
                            onChange={(e) => onChangeHandler('instagram', e.target.value)}
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <URLInput
                            label="Facebook"
                            name="facebook"
                            placeholder="Link Facebook"
                            value={facebook}
                            onChange={(e) => onChangeHandler('facebook', e.target.value)}
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <URLInput
                            label="Twitter"
                            name="twitter"
                            placeholder="Link Twitter"
                            value={twitter}
                            onChange={(e) => onChangeHandler('twitter', e.target.value)}
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <URLInput
                            label="Website"
                            name="website"
                            placeholder="Link Website"
                            value={website}
                            onChange={(e) => onChangeHandler('website', e.target.value)}
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <URLInput
                            label="TikTok"
                            name="tiktok"
                            placeholder="Link TikTok"
                            value={tiktok}
                            onChange={(e) => onChangeHandler('tiktok', e.target.value)}
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneOnChangeInput
                            label="Whatsapp"
                            name="whatsapp"
                            placeholder="Nomor Whatsapp"
                            className="full-width add-margin-bottom"
                            value={waNumber}
                            onChange={(val) => setWANumber(val)}
                            validation={register}
                            disabled={isDisabled}
                        />
                    </Grid>
                </>
            )
            break;
        }
    }
    return result;
};
