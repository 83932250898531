import React, { Component } from 'react';
import '../styles/Sidebar_Style.css';
import { NavLink, withRouter } from 'react-router-dom';
import {
  List, ListItem, ListItemText,
  ListSubheader, Collapse
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventIcon from '@material-ui/icons/Event';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    
    const currLocation = props.location.pathname;
    const currID = props.match.params.id;

    if ( currLocation.includes('/pengurus') || currLocation.includes('/pembina') || currLocation.includes('/jabatan') ) {
      this.state = {
        openPengurus: true, openAcara: false, openMitra: false, id: (currID? currID : ""),
      }
    }
    else if ( currLocation.includes('/acara-mendatang') || currLocation.includes('/acara-lampau')
      || currLocation.includes('/kategori-acara') ) {
      this.state = {
        openPengurus: false, openAcara: true, openMitra: false, id: (currID? currID : ""),
      }
    }
    else if ( currLocation.includes('/merchant-benefit') || currLocation.includes('/partner') || currLocation.includes('/sponsor') ) {
      this.state = {
        openPengurus: false, openAcara: false, openMitra: true, id: (currID? currID : ""),
      }
    }
    else {
      this.state = {
        openPengurus: false, openAcara: false, openMitra: false, id: (currID? currID : ""),
      }
    }
  }

  // Maintain sidebar active menu to stay active in different pages
  onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname);
    };
  };

  handleListItemClick = (event, index) => {
    localStorage.removeItem('last-opened-card');
    localStorage.removeItem('search');
    localStorage.removeItem('sortBy');
    localStorage.removeItem('sort');
    if ( event.target.innerText === "Pengurus" ) {
      this.setState({
        openPengurus: !this.state.openPengurus,
      })
    }
    else if ( event.target.innerText === "Acara" ) {
      this.setState({
        openAcara: !this.state.openAcara,
      })
    }
    else if ( event.target.innerText === "Mitra" ) {
      this.setState({
        openMitra: !this.state.openMitra,
      })
    }
    else if ( event.target.innerText === "Logout" ) {
      localStorage.clear();
    }
  };

  render() {
    return (
      <div className={`sidebar ${this.props.sidebarState}`}>
        <List
          dense
          subheader={
            <ListSubheader className="sidebar-header">
              <NavLink to="/dashboard" className="default-link-style">
                <h2>IDEPRENEURS</h2>
              </NavLink>
            </ListSubheader>
          }
        >
          <React.Fragment>

            {/* Home Link */}
            {localStorage.getItem("admin") !== "event"?
              <NavLink
                activeClassName="active-link"
                exact
                isActive={(_, location) => !!location.pathname.includes('/dashboard')}
                to="/dashboard/0"
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon"><HomeIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Home</ListItemText>
                </ListItem>
              </NavLink> : <></>
            }

            {/* Admin Link */}
            {localStorage.getItem("admin") === "master" ? (
              <NavLink
                activeClassName="active-link"
                exact
                isActive={(_, location) => !!location.pathname.includes('/admin')}
                to="/admin/0"
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={(event) => this.handleListItemClick(event, 13)}
                >
                  <ListItemIcon className="sidebar-icon"><AccountCircleIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Admin</ListItemText>
                </ListItem>
              </NavLink>
            ) : null}

            {/* Member Link */}
            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
              <NavLink
                activeClassName="active-link"
                exact
                isActive={(_, location) => location.pathname.includes('/member')}
                to="/member/0"
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={() => {
                    localStorage.removeItem('path');
                    localStorage.removeItem('search');
                    localStorage.removeItem('sortBy');
                    localStorage.removeItem('sort');
                  }}
                >
                  <ListItemIcon className="sidebar-icon"><PeopleIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Member</ListItemText>
                </ListItem>
              </NavLink> : <></>
            }

            {/* Executive Link */}
            {localStorage.getItem("admin") === "master"?
              <>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 2)}
              >
                <ListItemIcon className="sidebar-icon">
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                <ListItemText className="sidebar-item-text" primary="Pengurus" />
                {this.state.openPengurus ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openPengurus} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/pengurus')}
                    to="/pengurus/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 3)}
                    >
                      <ListItemText primary="Data Pengurus" />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/pembina')}
                    to="/pembina/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 4)}
                    >
                      <ListItemText primary="Data Pembina" />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/jabatan')}
                    to="/jabatan/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 4)}
                    >
                      <ListItemText primary="Daftar Jabatan" />
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse></> : <></>
            }

            {/* Event Link */}
            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "event"?
              <>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 5)}
              >
                <ListItemIcon className="sidebar-icon">
                  <EventIcon />
                </ListItemIcon>
                <ListItemText className="sidebar-item-text" primary="Acara" />
                {this.state.openAcara ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openAcara} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/kategori-acara')}
                    to="/kategori-acara/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 15)}
                    >
                      <ListItemText primary="Kategori Acara" />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/acara-mendatang')}
                    to="/acara-mendatang/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 6)}
                    >
                      <ListItemText primary="Acara Mendatang" />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/acara-lampau')}
                    to="/acara-lampau/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 7)}
                    >
                      <ListItemText primary="Acara Lampau" />
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse></> : <></>
            }

            {/* Mitra Link */}
            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "merchant"?
              <>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 8)}
              >
                <ListItemIcon className="sidebar-icon">
                  <PeopleOutlineIcon />
                </ListItemIcon>
                <ListItemText className="sidebar-item-text" primary="Mitra" />
                {this.state.openMitra ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openMitra} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={(_, location) => !!location.pathname.includes('/merchant-benefit')}
                    to="/merchant-benefit/0"
                    style={{ textDecoration: "none" }}>
                    <ListItem
                      button
                      className="sidebar-subitem"
                      onClick={(event) => this.handleListItemClick(event, 9)}
                    >
                      <ListItemText primary="Merchant Benefit" />
                    </ListItem>
                  </NavLink>
                  {localStorage.getItem("admin") === "master"?
                    <NavLink
                      activeClassName="active-link"
                      exact
                      isActive={(_, location) => !!location.pathname.includes('/partner')}
                      to="/partner/0"
                      style={{ textDecoration: "none" }}>
                      <ListItem
                        button
                        className="sidebar-subitem"
                        onClick={(event) => this.handleListItemClick(event, 10)}
                      >
                        <ListItemText primary="Partner" />
                      </ListItem>
                    </NavLink> : <></>
                  }
                  {localStorage.getItem("admin") === "master"?
                    <NavLink
                      activeClassName="active-link"
                      exact
                      isActive={(_, location) => !!location.pathname.includes('/sponsor')}
                      to="/sponsor/0"
                      style={{ textDecoration: "none" }}>
                      <ListItem
                        button
                        className="sidebar-subitem"
                        onClick={(event) => this.handleListItemClick(event, 11)}
                      >
                        <ListItemText primary="Sponsor" />
                      </ListItem>
                    </NavLink> : <></>
                  }
                </List>
              </Collapse></> : <></>
            }

            {/* News Link */}
            {localStorage.getItem("admin") === "master"?
              <NavLink
                activeClassName="active-link"
                exact
                isActive={(_, location) => !!location.pathname.includes('/news')}
                to="/news/0"
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={(event) => this.handleListItemClick(event, 12)}
                >
                  <ListItemIcon className="sidebar-icon"><AnnouncementIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">News</ListItemText>
                </ListItem>
              </NavLink> : <></>
            }

            {/* Vote Link */}
            {localStorage.getItem("admin") === "master"?
              <NavLink
                activeClassName="active-link"
                exact
                isActive={(_, location) => !!location.pathname.includes('/vote')}
                to="/vote/0"
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={(event) => this.handleListItemClick(event, 13)}
                >
                  <ListItemIcon className="sidebar-icon"><HowToVoteIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Vote</ListItemText>
                </ListItem>
              </NavLink> : <></>
            }

            {/* Chapter Link */}
            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database" ? (
              <NavLink
                activeClassName="active-link"
                exact
                isActive={(_, location) => !!location.pathname.includes('/wilayah')}
                to="/wilayah/0"
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={() => {
                    localStorage.removeItem('search');
                    localStorage.removeItem('sortBy');
                    localStorage.removeItem('sort');
                  }}
                >
                  <ListItemIcon className="sidebar-icon"><LocationOnIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Wilayah</ListItemText>
                </ListItem>
              </NavLink>
            ) : <></>}

            { localStorage.getItem("admin") === "master" ?
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/generate-pin"
                ])}
                to="/generate-pin"
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={(event) => this.handleListItemClick(event, 12)}
                >
                  <ListItemIcon className="sidebar-icon"><FiberPinIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Generate Pin</ListItemText>
                </ListItem>
              </NavLink> : <></>
            }

            <NavLink
              activeClassName="active-link"
              exact
              isActive={this.onPaths([
                "/ganti-password"
              ])}
              to="/ganti-password"
              style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 12)}
              >
                <ListItemIcon className="sidebar-icon"><VpnKeyIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Ganti Password</ListItemText>
              </ListItem>
            </NavLink>

            {/* Logout Link */}
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 14)}
              >
                <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Logout</ListItemText>
              </ListItem>
            </NavLink>
  
          </React.Fragment>
        </List>
      </div>
    );

  }
}

export default withRouter(Sidebar);