import React from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';

export function BarChart(props) {
    return (
        <Bar
            data={{
                // Label shows data title
                labels: (props.labels),
                // Custom the data's value and styles
                datasets: [
                    {
                        // Legend's text
                        label: (props.datasetsLabel),
                        // Data for each data title
                        data: (props.data),
                        // Chart color
                        backgroundColor: (props.backgroundColor),
                    }
                ]
            }}
            options={{
                maintainAspectRatio: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            }
                        }
                    ]
                },
            }}
        />
    )
}

export function LineChart(props) {
    return (
        <Line
            data={{
                // Label shows data title
                labels: (props.labels),
                // Custom the data's value and styles
                datasets: [
                    {
                        // Legend's text
                        label: (props.datasetsLabel),
                        // Data for each data title
                        data: (props.data),
                        // Chart color
                        borderColor: (props.borderColor),
                        fill: false,
                        lineTension: 0,
                    }
                ]
            }}
            options={{
                maintainAspectRatio: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            }
                        }
                    ]
                },
            }}
        />
    )
}

export function PieChart(props) {
    return (
        <Pie
            data={{
                // Label shows data title
                labels: (props.labels),
                // Custom the data's value and styles
                datasets: [
                    {
                        // Legend's text
                        label: (props.datasetsLabel),
                        // Data for each data title
                        data: (props.data),
                        // Chart color
                        backgroundColor: (props.backgroundColor),
                    }
                ]
            }}
            options={{
                maintainAspectRatio: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                display: false,
                            },
                        }
                    ],
                },
                legend: {
                    position: props.position? props.position : "top"
                }
            }}
        />
    )
}