import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReactExport from "react-data-export";

export const ExcelFile = ReactExport.ExcelFile;
export const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const membership = {
    "silver": "Silver",
    "platinum": "Platinum",
    "honorary": "Honorary",
};

/**
 * Filter list of data
 * @param data = list of data
 * @param key = data's key (ex: Color)
 * @param value = data's value (ex: Blue)
 */
export const filterData = async (data, key, value) => {
    if ( data != null ) {
        return data.filter(res => res[key] === value);
    }
    else {
        return [];
    }
}

/**
 * Filter list of data with 2 conditions
 * @param data = list of data
 * @param key = data's key (ex: Status)
 * @param value_1 = first data's value (ex: Active)
 * @param value_2 = second data's value (ex: Inactive)
 */
export const filterDataTwoConditions = async (data, key, value_1, value_2) => {
    if ( data != null) {
        return data.filter(res => ( res[key] === value_1 || res[key] === value_2 ));
    }
    else {
        return [];
    }
}

/**
 * Filter for past dates
 * @param data: list of data
 * @param today: today's date 
 */
export const filterPastData = async (data, today) => {
    if ( data != null ) {
        return data.filter(res => (
            (moment(res['date']) - today) < 0
        ));
    }
    else {
        return [];
    }
}

/**
 * Filter for upcoming dates
 * @param data: list of data
 * @param today: today's date 
 */
export const filterUpcomingData = async (data, today) => {
    if ( data != null ) {
        return data.filter(res => (
            (moment(res['date']) - today) >= 0
        ));
    }
    else {
        return [];
    }
}

// ------------------------------------------ PDF ------------------------------------------- //

/**
 * Download member's birthday list in PDF format
 * @param data: list of members
 */
 export const formatMemberBirthdayPDF = (data, chapter, month) => {
    let cellBodyData = [];
    let pdf = new jsPDF("potrait", "pt", "a4");
    let i = 1;
    
    pdf.setFontSize(14);
    if ( chapter !== 'All' && month.name !== 'All' ) {
        pdf.text(
            (
                "Daftar Ulang Tahun Member Idepreneurs (" + month.name + " - "
                + chapter + ")"
            ),
            pdf.internal.pageSize.width / 2, 50, "center"
        );
    }
    else if ( chapter !== 'All' && month.name === 'All' ) {
        pdf.text(
            ( "Daftar Ulang Tahun Member Idepreneurs (" + chapter + ")" ),
            pdf.internal.pageSize.width / 2, 50, "center"
        );
    }
    else if ( chapter === 'All' && month.name !== 'All' ) {
        pdf.text(
            ( "Daftar Ulang Tahun Member Idepreneurs (" + month.name + ")" ),
            pdf.internal.pageSize.width / 2, 50, "center"
        );
    }
    else {
        pdf.text("Daftar Ulang Tahun Member Idepreneurs", pdf.internal.pageSize.width / 2, 50, "center");
    }
    
    data.forEach( (member) => {
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        const obj = [
            i,
            member.name,
            member.phone_number,
            moment(member.date_of_birth).format('DD MMMM YYYY')
        ];
        cellBodyData.push(obj);
        i++;
    })

    // Table
    pdf.autoTable({
        head: [[ "NO", "NAMA", "NOMOR TELEPON", "ULANG TAHUN" ]],
        body: cellBodyData,
        startY: 80,
        theme: "grid",
        styles: {
            lineColor: [0, 0, 0],
            lineWidth: 1,
            fontSize: 12,
            minCellHeight: 25,
        },
        headStyles: {
            fillColor: [206, 153, 99],
            halign: "center",
            valign: "middle",
            minCellHeight: 0,
        },
        bodyStyles: {
            halign: "left",
            valign: "middle",
            cellWidth: "wrap",
            fontSize: 11,
        },
        columnStyles: {
            0: { cellWidth: 35, halign: "center" },
            1: { cellWidth: 205 },
            2: { cellWidth: 130, halign: "center" },
            3: { cellWidth: 135, halign: "center" },
        },
        didDrawCell: (data) => {
            data.section === "body" && cellBodyData.push(data.cell);
        },
    });
    // End Of Table
    
    let selectedMonth = month.name !== 'All'? month.name : false;
    let selectedChapter = chapter !== 'All'? chapter : false;
    if ( selectedMonth && selectedChapter ) {
        pdf.save('Idepreneurs Birthday List (' + selectedMonth + ' - '
            + selectedChapter + ').pdf'
        );
    }
    else if ( selectedMonth && !selectedChapter ) {
        pdf.save('Idepreneurs Birthday List (' + selectedMonth + ').pdf');
    }
    else if ( !selectedMonth && selectedChapter ) {
        pdf.save('Idepreneurs Birthday List (' + selectedChapter + ').pdf');
    }
    else {
        pdf.save('Idepreneurs Birthday List.pdf');
    }
 }
 
// ---------------------------------------- EXCEL --------------------------------------------- //

/**
 * Print member data in excel format
 * @param data: list of member data
 */
export const formatMemberExcel = (data) => {
    if ( data ) {
        // Create columns & header style
        let res = [
            {
                columns: [
                    {title: "No", width: {wch: 4}, style: {
                        alignment: { horizontal: "center", },
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "ID Member", width: {wch: 14}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Nama", width: {wch: 30}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Membership", width: {wch: 12}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Chapter", width: {wch: 12}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Jenis Kelamin", width: {wch: 12}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Nomor Telepon", width: {wch: 15}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Email", width: {wch: 35}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Tanggal Lahir", width: {wch: 20}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Tempat Lahir", width: {wch: 15}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Kota", width: {wch: 15}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Pekerjaan", width: {wch: 35}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Status", width: {wch: 12}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                ],
                data: [],
            }
        ]
        // Add row items to table
        if ( data.length > 0 ) {
            for (let i=0; i < data.length; i++) {
                res[0].data.push([
                    {
                        value: (i+1),
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data[i].member_card_id || "",
                    },
                    {
                        value: data[i].name || "",
                    },
                    {
                        value: membership[data[i].membership] || "",
                    },
                    {
                        value: data[i].chapter || "",
                        style: { alignment: { wrapText: true } },
                    },
                    {
                        value: data[i].gender === "male" ? "Laki-laki"
                            : data[i].gender === "female" ? "Perempuan"
                            : "",
                    },
                    {
                        value: data[i].phone_number || "",
                    },
                    {
                        value: data[i].email && data[i].email !== "null@gmail.com"? data[i].email : "",
                    },
                    {
                        value: moment(data[i].date_of_birth).format("DD MMMM YYYY") || "",
                    },
                    {
                        value: data[i].place_of_birth || "",
                        style: { alignment: { wrapText: true } },
                    },
                    {
                        value: data[i].city || "",
                        style: { alignment: { wrapText: true } },
                    },
                    {
                        value: data[i].job !== "null"? data[i].job : "",
                        style: { alignment: { wrapText: true } },
                    },
                    {
                        value: data[i].status === "active"? "Aktif" : "Tidak Aktif",
                    },
                ])
            }
        }
        else {
            res[0].data.push([
                { value: "-" }, { value: "-" }, { value: "-" },
                { value: "-" }, { value: "-" }, { value: "-" },
                { value: "-" }, { value: "-" }, { value: "-" },
                { value: "-" }, { value: "-" }, { value: "-" },
                { value: "-" },
            ])
        }
        return res;
    }
    else {
        return [];
    }
}

/**
 * Print voters data in excel format
 * @param data: list of voters
 */
export const formatVotersExcel = (data) => {
    if ( data ) {
        // Create columns & header style
        let res = [
            {
                columns: [
                    {title: "No", width: {wch: 4}, style: {
                        alignment: { horizontal: "center", },
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "ID Member", width: {wch: 14}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Nama", width: {wch: 30}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Chapter", width: {wch: 12}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                    {title: "Pilihan Kandidat", width: {wch: 30}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: 'CE9963' } },
                    }},
                ],
                data: [],
            }
        ]
        // Add row items to table
        if ( data.length > 0 ) {
            for (let i=0; i < data.length; i++) {
                res[0].data.push([
                    {
                        value: (i+1),
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data[i].member_card_id,
                    },
                    {
                        value: data[i].name,
                    },
                    {
                        value: data[i].chapter,
                        style: { alignment: { wrapText: true } },
                    },
                    {
                        value: data[i].selected_candidate ? data[i].selected_candidate.candidate_name : "-",
                    },
                ]);
            };
        }
        else {
            res[0].data.push([
                { value: "-" }, { value: "-" },
                { value: "-" }, { value: "-" },
            ])
        }
        return res;
    }
    else {
        return [];
    }
}

export const setQueryParam = (params) => {
    let result = '?';

    if (params) {
        if (params.search) {
            result += 'search='.concat(params.search, '&');
        }
        if (params.sortBy) {
            result += 'sort_by='.concat(params.sortBy);
        }
        if (params.sort) {
            result += '&sort='.concat(params.sort);
        }
        if (params.status) {
            result += '&status='.concat(params.status);
        }
        if (params.category) {
            result += '&category='.concat(params.category);
        }
    }

    return result === '?' ? '' : result;
};

export const isMobile = window.screen.width <= 599;

export const isTablet = window.screen.width > 599 && window.screen.width <= 959;
