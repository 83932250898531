import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../../styles/Form_Style.css';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
// import executiveTitle from '../../../assets/data/ExecutiveTitle';
import { getMembers, postExecutiveBoard, getLocations, getJabatan } from '../../../logic/APIHandler';
import { TextInput, GroupedDropdown, DropdownInput, ImageInput } from '../../../logic/FormInput';
import { filterData } from '../../../logic/Handler';
import { Grid, Button, Snackbar } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddPengurus() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [locationNames, setLocationNames] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currCateg, setCurrCateg] = useState(null);
    const [positions, setPositions] = useState([]);
    const [currPosition, setCurrPosition] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        data: [],
    });

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Method that run first when the page is opened 
    useEffect(() => {
        getAllLocations();
        getAllPositions();
        getData();
    }, []);

    const getAllLocations = async() => {
        setIsLoading(true);
        let resp = await getLocations();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data;
            let categs = [], names = [];
            result.forEach(res => {
                if ( categs.filter(temp => temp.category === res.category).length < 1 ) {
                    categs.push(res);
                }
                if ( res.category !== "Pusat" && res.category !== "Luar Wilayah" ) {
                    names.push(res);
                }
            });
            setCategories(categs);
            setLocationNames(names);
            setIsLoading(false);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText(resp[0].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    const getAllPositions = async() => {
        setIsLoading(true);
        const resp = await getJabatan();
        if (resp[0] && resp[0].status === 200) {
            const result = resp[0].data.map(item => ({
                label: item.name,
                value: item._id,
            }));
            setPositions(result);
        }
        else if (resp[1] && resp[1].status === 401) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            setErrorText(resp[1].data && resp[1].data[0].msg ? resp[1].data[0].msg : resp[1].message);
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Get members who have member card ID
    const getData = async() => {
        setIsLoading(true);
        let members = await getMembers();
        if (members[0] && members[0].status === 200) {
            let filteredMember = await filterData(members[0].data, "status", "active",);
            let res = [];
            filteredMember.forEach(member => {
                res.push({
                    member_card_id: member.member_card_id,
                    label: member.name,
                    value: member._id,
                });
            });
            setState({
                data: res,
            });
        }
        else if ( members[1] && members[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(members);
            if ( members[1].data && members[1].data[0].msg ) {
                setErrorText(members[1].data[0].msg);
            }
            else {
                setErrorText(members[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            if ( currCateg.category === "Pusat" || currCateg.category === "Luar Wilayah" ) {
                data["chapter"] = currCateg._id;
            }
            else {
                data["chapter"] = data.location._id;
            }
            let resp = await postExecutiveBoard(data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/pengurus/0");
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <StatusModal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Membuat Pengurus
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Pengurus Baru</h1>

                            <Grid container>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    {/* Input for member */}
                                    <GroupedDropdown
                                        label="Pilih Member *"
                                        name="member_id"
                                        className="full-width add-margin-bottom grouped-field"
                                        rules={{ required: "*salah satu member harus dipilih" }}
                                        control={control}
                                        options={state.data}
                                        getOptionLabel={(option) => 
                                            option.member_card_id + " - " + option.label}
                                        isClearable={true}
                                        defaultValue=""
                                        errors={errors.member_id}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <DropdownInput
                                        label="Jabatan"
                                        name="title"
                                        className="full-width add-margin-bottom tf-label"
                                        keepData={(data) => setCurrPosition(data)}
                                        disableClearable={true}
                                        control={control}
                                        options={positions}
                                        getOptionLabel={(option) => option.label}
                                        value={currPosition}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <DropdownInput
                                        label="Kategori Wilayah"
                                        name="categories"
                                        className="full-width add-margin-bottom tf-label"
                                        keepData={(data) => setCurrCateg(data)}
                                        disableClearable={true}
                                        control={control}
                                        options={categories}
                                        getOptionLabel={(option) => option.category}
                                        value={currCateg}
                                        required={true}
                                    />
                                </Grid>
                                { currCateg && currCateg.category !== "Pusat" && currCateg.category !== "Luar Wilayah" ?
                                    <Grid item xs={12} style={{ position: "relative" }}>
                                        <GroupedDropdown
                                            label="Nama Wilayah *"
                                            name="location"
                                            className="full-width add-margin-bottom grouped-field"
                                            rules={{ required: "*nama wilayah harus dipilih" }}
                                            control={control}
                                            options={locationNames.filter(res => res.category === currCateg.category)}
                                            getOptionLabel={option => option.name}
                                            isClearable={false}
                                            defaultValue=""
                                            errors={errors.location}
                                            errorClassName="text-required"
                                        />
                                    </Grid> : <></>
                                }
                            </Grid>

                            {/* Input for pengurus image */}
                            <ImageInput
                                name="image_executiveboard"
                                label="Foto Pengurus *"
                                ratioLabel="Ratio 1:1"
                                validation={register}
                                errors={errors.image_executiveboard}
                                isEdit={false}
                                control={control}
                                defaultImg={false}
                                canDelete={true}
                                error_label="* foto pengurus"
                                overallClassName="full-input-image"
                            />

                            {/* Input for category */}
                            <TextInput
                                label="Kategori"
                                name="category"
                                placeholder="Kategori"
                                defaultValue="pengurus"
                                className="hide"
                                validation={register}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}