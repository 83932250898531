import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Button, Snackbar } from "@material-ui/core";
import {
  postEvent,
  getEventCategories,
  getLocations,
  getMembers
} from "../../logic/APIHandler";
import Sidebar from "../../components/Sidebar_Com";
import Spinner from "../../components/Loading_Com";
import StatusModal from "../../components/Modal_Com";
import cities from "../../assets/data/Cities";
import {
  TextInput,
  DateInput,
  TimeInput,
  ImageInput,
  URLInput,
  GroupedDropdown,
  DropdownInput,
  PhoneOnChangeInput
} from "../../logic/FormInput";
import { filterData } from "../../logic/Handler";
import "../../styles/Form_Style.css";
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddNextEvent() {
  const history = useHistory();
  const { register, handleSubmit, errors, control } = useForm();
  const [cityData, setCityData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [picContact, setPICContact] = useState("");
  const [selectedPIC, setSelectedPIC] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [locationNames, setLocationNames] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currCateg, setCurrCateg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [openJWT, setOpenJWT] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState("slide-out");
  const windowWidth = window.innerWidth;
  const [state, setState] = useState({
    selectedDate: moment(),
    selectedTime: moment(),
    data: []
  });

  // Method that run first when the page is opened
  useEffect(() => {
    let res = [];
    cities.forEach((val) => {
      res.push({ value: val, label: val });
    });
    setCityData(res);
    getData();
    getAllLocations();
  }, []);

  // Get list of event category
  const getData = async () => {
    setIsLoading(true);
    let categories = await getEventCategories();
    if (categories[0] && categories[0].status === 200) {
      const filteredCategories = await filterData(
        categories[0].data,
        "isDeleted",
        false
      );
      let res = [];
      filteredCategories.forEach((category) => {
        res.push({
          id: category._id,
          label: category.name,
          value: category.name
        });
      });
      setState({
        data: res
      });
      getMember();
    } else if (categories[1] && categories[1].status === 401) {
      setIsLoading(false);
      setOpenJWT(true);
    } else {
      console.log(categories);
      if (categories[1].data && categories[1].data[0].msg) {
        setErrorText(categories[1].data[0].msg);
      } else {
        setErrorText(categories[1].message);
      }
      setOpenErrorAlert(true);
      setIsLoading(false);
    }
  };
  // Get all locations
  const getAllLocations = async () => {
    setIsLoading(true);
    let resp = await getLocations();
    if (resp[0] && resp[0].status === 200) {
      let result = resp[0].data;
      let categs = [],
        names = [];
      result.forEach((res) => {
        if (
          categs.filter((temp) => temp.category === res.category).length < 1
        ) {
          categs.push(res);
        }
        if (res.category !== "Pusat" && res.category !== "Luar Wilayah") {
          names.push(res);
        }
      });
      setCategories(categs);
      setLocationNames(names);
      setIsLoading(false);
    } else if (resp[0] && resp[0].status === 204) {
      setErrorText(resp[0].message);
      setOpenErrorAlert(true);
      setIsLoading(false);
    } else if (resp[1] && resp[1].status === 401) {
      setIsLoading(false);
      setOpenJWT(true);
    } else {
      console.log(resp);
      if (resp[1].data && resp[1].data[0].msg) {
        setErrorText(resp[1].data[0].msg);
      } else {
        setErrorText(resp[1].message);
      }
      setOpenErrorAlert(true);
      setIsLoading(false);
    }
  };

  // Get all members
  const getMember = async () => {
    let resp = await getMembers();
    if (resp[0] && resp[0].status === 200) {
      let members = await filterData(resp[0].data, "status", "active");
      let res = [];
      members.forEach((member) => {
        res.push({
          value: member.phone_number,
          label: member.name
        });
      });
      setMemberData(res);
      setIsLoading(false);
    } else if (resp[1] && resp[1].status === 401) {
      setIsLoading(false);
      setOpenJWT(true);
    } else {
      console.log(resp);
      if (resp[1].data && resp[1].data[0].msg) {
        setErrorText(resp[1].data[0].msg);
      } else {
        setErrorText(resp[1].message);
      }
      setOpenErrorAlert(true);
      setIsLoading(false);
    }
  };

  // Handle input time onchange
  const handleTimeChange = (time) => {
    setState({
      ...state,
      selectedTime: time
    });
  };
  // Handle input date onchange
  const handleDateChange = (date) => {
    setState({
      ...state,
      selectedDate: moment(date)
    });
  };

  // Make the website stay still for __ ms
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  // Close toast
  const handleCloseAlert = () => {
    setOpenSuccessAlert(false);
    setOpenErrorAlert(false);
  };

  // Toggle sidebar menu in responsive
  const toggleSidebar = () => {
    if (isSidebarOpen === "slide-out") {
      setIsSidebarOpen("slide-in");
    } else {
      setIsSidebarOpen("slide-out");
    }
  };

  // Send updated data event to server
  const onSubmit = async (data) => {
    setIsLoading(true);
    if (
      currCateg.category === "Pusat" ||
      currCateg.category === "Luar Wilayah"
    ) {
      data["chapter"] = currCateg._id;
    } else {
      data["chapter"] = data.location._id;
    }
    // data.date = moment(
    //   data.date + " " + data.time,
    //   "DD/MM/YYYY HH:mm"
    // ).toISOString();
    let resp = await postEvent(data, selectedCity.value);
    if (resp[0] && resp[0].status === 200) {
      setOpenSuccessAlert(true);
      await sleep(1000);
      history.replace("/acara-mendatang/0");
    } else {
      console.log(resp);
      if (resp[1].data && resp[1].data[0].msg) {
        setErrorText(resp[1].data[0].msg);
      } else {
        setErrorText(resp[1].message);
      }
      setOpenErrorAlert(true);
      setIsLoading(false);
    }
  };

  return (
    <div id="detail-prev-event" className="content">
      {isLoading ? <Spinner /> : ""}
      <Sidebar sidebarState={windowWidth < 768 ? isSidebarOpen : ""} />

      <StatusModal
        open={openJWT}
        modalType="handle-jwt"
        modalTitle="Token Anda Sudah Expire"
      />

      <Grid container className="responsive-header">
        <Grid item xs={6}>
          <h2>IDEPRENEURS</h2>
        </Grid>
        <Grid item xs={6} className="menu-icon-btn">
          <Button variant="outlined" onClick={toggleSidebar}>
            {isSidebarOpen === "slide-out" ? <MenuIcon /> : <CloseIcon />}
          </Button>
        </Grid>
      </Grid>

      <Grid container className="grid-container">
        <Snackbar
          open={openSuccessAlert}
          autoHideDuration={2500}
          onClose={handleCloseAlert}
        >
          <Alert severity="success">Berhasil Membuat Acara</Alert>
        </Snackbar>
        <Snackbar
          open={openErrorAlert}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
        >
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} className="add-form">
            <Grid container>
              <Grid item xs={12}>
                <h1 style={{ marginBottom: "2rem" }}>Tambah Acara Baru</h1>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for name */}
                <TextInput
                  label="Nama *"
                  name="name"
                  placeholder="Nama"
                  className="form-left-field"
                  validation={register({ required: "*nama harus diisi" })}
                  errors={errors.name}
                  errorClassName="text-required-half"
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ position: "relative" }}>
                {/* Input for event category */}
                <GroupedDropdown
                  label="Kategori Acara *"
                  labelPosition="half-right-label"
                  name="event_category"
                  className="form-right-field grouped-field"
                  control={control}
                  options={state.data}
                  rules={{ required: "*kategori acara harus diisi" }}
                  errors={errors.event_category}
                  errorClassName="text-required-half half-right"
                  isClearable={true}
                  defaultValue=""
                />
              </Grid>
            </Grid>

            {/* Input for description */}
            <TextInput
              label="Deskripsi *"
              name="description"
              placeholder="Deskripsi"
              className="full-width add-margin-bottom"
              validation={register({ required: "*deskripsi harus diisi" })}
              errors={errors.description}
              errorClassName="text-required"
              multiline={true}
              rows={3}
            />

            <Grid container>
              <Grid item xs={12} md={6}>
                <DropdownInput
                  label="PIC"
                  name="pic"
                  className="form-left-field add-margin-bottom tf-label"
                  keepData={(data) => {
                    setSelectedPIC(data);
                    if (data) setPICContact(data.value);
                    else setPICContact("");
                  }}
                  disableClearable={false}
                  control={control}
                  options={memberData}
                  getOptionLabel={(option) => option.label}
                  value={selectedPIC}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for PIC contact number */}
                <PhoneOnChangeInput
                  label="Nomor Kontak PIC"
                  name="pic_contact"
                  placeholder="Nomor Kontak PIC"
                  className="form-right-field"
                  value={picContact}
                  onChange={(val) => setPICContact(val)}
                  validation={register}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for event date */}
                <DateInput
                  label="Tanggal Acara *"
                  name="date"
                  value={state.selectedDate}
                  onChange={handleDateChange}
                  className="form-left-field"
                  validation={register({
                    required: "*tanggal acara harus diisi"
                  })}
                  errors={errors.date}
                  errorClassName="text-required-half"
                  // disablePast={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for event time */}
                <TimeInput
                  label="Waktu Acara *"
                  name="time"
                  value={state.selectedTime}
                  onChange={handleTimeChange}
                  className="form-right-field"
                  validation={register({
                    required: "*waktu acara harus diisi"
                  })}
                  errors={errors.time}
                  errorClassName="text-required-half"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                {/* Input for address */}
                <TextInput
                  label="Lokasi Acara"
                  name="address"
                  placeholder="Lokasi Acara"
                  className="form-left-field"
                  validation={register}
                  errors={errors.address}
                  errorClassName="text-required-half"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Input for address link */}
                <URLInput
                  label="Link Lokasi"
                  name="address_link"
                  placeholder="Link Lokasi"
                  className="form-right-field"
                  validation={register}
                  errors={errors.address_link}
                  errorClassName="text-required-half"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                className="grouped-layout"
                style={{ position: "relative" }}
              >
                <label className="grouped-label">Kota Tempat Acara *</label>
                <Select
                  value={selectedCity}
                  onChange={(val) => setSelectedCity(val)}
                  options={cityData}
                  className="full-width add-margin-bottom grouped-field"
                  isClearable={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display:
                    selectedCity && selectedCity.value == "Lainnya"
                      ? "block"
                      : "none"
                }}
              >
                {/* Free text input for domicile */}
                <TextInput
                  label="Nama Kota *"
                  name="city"
                  placeholder="Nama Kota"
                  className="full-width add-margin-bottom"
                  validation={register({
                    required:
                      selectedCity && selectedCity.value == "Lainnya"
                        ? "*nama kota harus diisi"
                        : false
                  })}
                  errors={errors.city}
                  errorClassName="text-required"
                />
              </Grid>
              <Grid item xs={12} style={{ position: "relative" }}>
                <DropdownInput
                  label="Kategori Wilayah"
                  name="categories"
                  className="full-width add-margin-bottom tf-label"
                  keepData={(data) => setCurrCateg(data)}
                  disableClearable={true}
                  control={control}
                  options={categories}
                  getOptionLabel={(option) => option.category}
                  value={currCateg}
                  required={true}
                />
              </Grid>
              {currCateg &&
              currCateg.category !== "Pusat" &&
              currCateg.category !== "Luar Wilayah" ? (
                <Grid item xs={12} style={{ position: "relative" }}>
                  <GroupedDropdown
                    label="Nama Wilayah *"
                    name="location"
                    className="full-width add-margin-bottom grouped-field"
                    rules={{ required: "*nama wilayah harus dipilih" }}
                    control={control}
                    options={locationNames.filter(
                      (res) => res.category === currCateg.category
                    )}
                    getOptionLabel={(option) => option.name}
                    isClearable={false}
                    defaultValue=""
                    errors={errors.location}
                    errorClassName="text-required"
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Grid container className="gallery-section add-margin-bottom">
              <Grid item xs={12}>
                <h2>Banner & Thumbnail</h2>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                {/* Input for banner image */}
                <ImageInput
                  name="banner"
                  label="Banner *"
                  ratioLabel="Ratio 8:3"
                  validation={register}
                  errors={errors.banner}
                  isEdit={false}
                  control={control}
                  defaultImg={state.banner}
                  overallClassName="half-input-image eight-three-size"
                  error_label="* foto banner"
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                {/* Input for thumbnail image */}
                <ImageInput
                  name="thumbnail"
                  label="Thumbnail *"
                  ratioLabel="Ratio 3:2"
                  validation={register}
                  errors={errors.thumbnail}
                  isEdit={false}
                  control={control}
                  defaultImg={state.thumbnail}
                  overallClassName="half-input-image three-two-size"
                  error_label="* foto thumbnail"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ float: "right", marginRight: "15px" }}
                  className="add-new-btn"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
