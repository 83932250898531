import React, { Component } from 'react';
import { Grid, Button } from "@material-ui/core";
import {
    Menu,
    Close,
    FindInPage,
    Delete,
} from '@material-ui/icons';
import { getNews } from '../../logic/APIHandler';

import TableV2 from '../../components/Table_Com_V2';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';

class News extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            newsId: '00000000',
            newsName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
            search: localStorage.getItem('search') || '',
            totalData: 0,
            params: null,
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama news",
        },
        { 
            title: "Judul", 
            field: "title",
            tooltip: "Judul news",
        },
        {
            title: "Banner",
            field: "banner",
            tooltip: "Banner news",
            render: rowData =>
                rowData.banner? (
                    <img src={rowData.banner} alt="img" width="150" height="100"
                        style={{ objectFit: "cover", objectPosition: "center" }} />
                ) : ( <p className="no-image-text">No Banner</p> )
        },
    ];

    actions = [
        {
            icon: () => <FindInPage />,
            tooltip: "Lihat Detail News",
            onClick: (_, rowData) => {
                this.props.history.push({
                    pathname: "/news-detail/" + rowData._id,
                });
            },
        },
        {
            icon: () => <Delete />,
            tooltip: "Hapus News",
            onClick: (_, rowData) => {
                this.handleOpen(rowData);
            },
        },
    ];

    // Show delete news modal
    handleOpen(news) {
        this.setState({
            open: true,
            newsId: news._id,
            newsName: news.name,
        })
    }

    // CLose delete news modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData(params) {
        this.setState({ isLoading: true });
        const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
        const payload = {
            page: params && params.page ? params.page :  page,
            sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
            sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
            ...this.state.search && { search: this.state.search },
        };

        this.setState({ params: payload });
        localStorage.setItem('sortBy', payload.sortBy);
        localStorage.setItem('sort', payload.sort);

        let news = await getNews(payload, false);
        if ( news[0] && news[0].status === 200 ) {
            this.setState({
                data: news[0].data.datas,
                totalData: news[0].data.total,
                isLoading: false,
            });
        }
        else if ( news[1] && news[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(_, prevState) {
        setTimeout(() => {
            if (this.state.search.length > 0 && (
              this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
            )) {
                const search = this.state.search.toLowerCase();
                localStorage.setItem('search', search);
                this.props.history.push({ pathname: '/news/0' });
                this.getData({ search: '', page: 1 });
            } else if (this.state.search.length === 0 && prevState.search) {
                this.props.history.push({ pathname: '/news/0' });
                this.getData({ search: '', page: 1 });
            }
        }, 700);
    };

    render() {
        return (
            <div className="content">
                { this.state.isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <StatusModal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="news"
                    id={this.state.newsId}
                    color="secondary"
                    modalTitle="Hapus data?"
                    dataName={this.state.newsName}
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus news!"
                    errorText="Gagal menghapus news!"
                />
                <StatusModal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <Menu /> : <Close />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <TableV2
                        title="Tabel News"
                        path="/news"
                        columns={this.columns}
                        actions={this.actions}
                        data={this.state.data}
                        total={this.state.totalData}
                        search={this.state.search}
                        setSearch={val => this.setState({ search: val })}
                        refetchTableData={params => this.getData(params)}
                        params={this.state.params}
                    />
                </Grid>

            </div>
        );
    }
}

export default News;