import React, { Component } from 'react';
import { Grid, Button } from "@material-ui/core";
import {
    Menu,
    Close,
    FindInPage,
    Delete,
} from '@material-ui/icons';
import { getExecutiveBoards } from '../../../logic/APIHandler';
import { styles } from '../../../styles/CustomTable_Style';
import { isMobile, isTablet } from '../../../logic/Handler';

import Sidebar from '../../../components/Sidebar_Com';
import TableV2 from '../../../components/Table_Com_V2';
import StatusModal from '../../../components/Modal_Com';
import Spinner from '../../../components/Loading_Com';

class Pembina extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            openClearBoards: false,
            executiveId: "00000000",
            executiveName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
            search: localStorage.getItem('search') || '',
            totalData: 0,
            params: null,
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpenClearBoards = this.handleOpenClearBoards.bind(this);
    }

    columns = [
        { 
            title: "ID Member", 
            field: "member_id",
            tooltip: "ID member pembina",
            render: rowData =>
                rowData.member_id !== null? (
                    <p>{rowData.member_id.member_card_id}</p>
                ) : ( <p>-</p> )
        },
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama pembina",
            render: rowData =>
                rowData.member_id !== null? (
                    <p>{rowData.member_id.name}</p>
                ) : ( <p>{rowData.name}</p> )
        },
        { 
            title: "Jabatan", 
            field: "title",
            tooltip: "Jabatan pembina",
        },
    ]

    actions = [
        {
            icon: () => <FindInPage />,
            tooltip: "Lihat Detail Pembina",
            onClick: (_, rowData) => {
                this.props.history.push({
                    pathname: "/pembina-detail/" + rowData._id,
                });
            },
        },
        {
            icon: () => <Delete />,
            tooltip: "Hapus Pembina",
            onClick: (_, rowData) => {
                this.handleOpen(rowData);
            },
        },
    ]

    // Show delete executive board modal
    handleOpen(executive) {
        this.setState({
            open: true,
            executiveId: executive._id,
            executiveName: (executive.member_id? executive.member_id.name : executive.name),
        });
    };
    handleOpenClearBoards() {
        this.setState({
            openClearBoards: true,
        });
    };

    // CLose delete executive board modal
    handleClose() {
        this.setState({
            open: false,
            openClearBoards: false,
        });
    };

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Get data for table
    async getData(params) {
        this.setState({ isLoading: true });
        const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
        const payload = {
            page: params && params.page ? params.page :  page,
            sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'position_id',
            sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
            category: 'dewan',
            ...this.state.search && { search: this.state.search },
        };

        this.setState({ params: payload });
        localStorage.setItem('sortBy', payload.sortBy);
        localStorage.setItem('sort', payload.sort);

        let executives = await getExecutiveBoards(payload, false);
        if ( executives[0] && executives[0].status === 200 ) {
            const filteredExecutives = executives[0].data.datas;
            this.setState({
                data: filteredExecutives,
                totalData: executives[0].data.total,
            });
        }
        else if ( executives[1] && executives[1].status === 401) {
            this.setState({
                openJWT: true,
            });
        }
        this.setState({ isLoading: false });
    };

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(_, prevState) {
        setTimeout(() => {
            if (this.state.search.length > 0 && (
              this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
            )) {
                const search = this.state.search.toLowerCase();
                localStorage.setItem('search', search);
                this.props.history.push({ pathname: '/pembina/0' });
                this.getData({ search: '', page: 1 });
            } else if (this.state.search.length === 0 && prevState.search) {
                this.props.history.push({ pathname: '/pembina/0' });
                this.getData({ search: '', page: 1 });
            }
        }, 700);
    };

    render() {
        return (
            <div className="content">
                { this.state.isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <StatusModal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="pembina"
                    id={this.state.executiveId}
                    color="primary"
                    modalTitle="Hapus Pembina?"
                    dataName={this.state.executiveName}
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus pembina!"
                    errorText="Gagal menghapus pembina!"
                />
                <StatusModal
                    open={this.state.openClearBoards}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="clear-boards"
                    color="primary"
                    modalTitle="Hapus Seluruh Data Pembina?"
                    listOfID={this.state.data}
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus seluruh data pembina!"
                    errorText="Gagal menghapus seluruh data pembina!"
                />
                <StatusModal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <Menu /> : <Close />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <TableV2
                        title="Tabel Pembina"
                        path="/pembina"
                        columns={this.columns}
                        actions={this.actions}
                        data={this.state.data}
                        total={this.state.totalData}
                        search={this.state.search}
                        setSearch={val => this.setState({ search: val })}
                        refetchTableData={params => this.getData(params)}
                        params={this.state.params}
                        footerBtn={(
                            <Button
                                variant="contained"
                                className="add-btn-table"
                                onClick={this.handleOpenClearBoards}
                                style={styles({ isMobile, isTablet }).addButton}
                            >
                                Hapus Semua Pembina
                            </Button>
                        )}
                    />
                </Grid>
            </div>
        );
    }
}

export default Pembina;