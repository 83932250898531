import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Style.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { getCandidateChairman, editCandidateChairman } from '../../logic/APIHandler';
import { TextInput, ImageInput, } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailNews(props) {

    const history = useHistory();
    const { register, handleSubmit, control, setValue, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Calon Pengurus');
    const [pageTitle, setPageTitle] = useState('Detail Calon Pengurus');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idCandidate, setIDCandidate] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        photo: '',
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idCandidate = props.match.params.id;
        setIDCandidate(idCandidate);
        if ( idCandidate ) {
            getData(idCandidate);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get news's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getCandidateChairman(id);
        if (resp[0] && resp[0].status === 200) {
            const candidate = resp[0].data;
            setValue("name", candidate.member_id.name);
            setValue("phone_number", candidate.member_id.phone_number);
            setValue("email", candidate.member_id.email);
            setValue("member_since", candidate.member_since);
            setValue("visi_misi", candidate.visi_misi);
            setValue("company_name", candidate.company_name);
            setValue("business_fields", candidate.business_fields);
            setValue("last_position", candidate.last_position);
            setValue("work_program", candidate.work_program);
            setValue("message", candidate.message);

            setState({
                ...state,
                photo: (candidate.photo !== "undefined")? candidate.photo : "",
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Calon Pengurus" ) {
            setEdit("Cancel");
            setPageTitle("Edit Calon Pengurus")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data candidate chairman to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = await editCandidateChairman(idCandidate, data);
            console.log(resp)
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Data Calon Pengurus
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                {/* Input for name */}
                                <TextInput
                                    label="Nama"
                                    name="name"
                                    placeholder="Nama"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for phone number */}
                                <TextInput
                                    label="Nomor Kontak"
                                    name="phone_number"
                                    placeholder="Nomor Kontak"
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for email */}
                                <TextInput
                                    label="Email"
                                    name="email"
                                    placeholder="Email"
                                    className="form-right-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for member since */}
                                <TextInput
                                    label="Tahun Menjadi Member"
                                    name="member_since"
                                    placeholder="----"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for visi misi */}
                                <TextInput
                                    label="Visi Misi"
                                    name="visi_misi"
                                    placeholder="Visi Misi"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                    multiline={true}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for company name */}
                                <TextInput
                                    label="Name Perusahaan"
                                    name="company_name"
                                    placeholder="Name Perusahaan"
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for business field */}
                                <TextInput
                                    label="Bidang Usaha"
                                    name="business_fields"
                                    placeholder="Bidang Usaha"
                                    className="form-right-field add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for last position */}
                                <TextInput
                                    label="Jabatan Terakhir"
                                    name="last_position"
                                    placeholder="Jabatan Terakhir"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for work program */}
                                <TextInput
                                    label="Program Kerja"
                                    name="work_program"
                                    placeholder="Program Kerja"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                    multiline={true}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for message */}
                                <TextInput
                                    label="Pesan"
                                    name="message"
                                    placeholder="Pesan"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                    multiline={true}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for photo */}
                                <ImageInput
                                    name="photo"
                                    label="Foto Calon Pengurus"
                                    ratioLabel="Ratio 1:1"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    defaultImg={state.photo}
                                    canDelete={false}
                                    disabled={isDisabled}
                                    overallClassName="full-input-image"
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}