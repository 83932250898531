import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import {
  Edit, Menu, Close, FindInPage,
  AttachMoney, CloudDownload, CheckCircleOutline,
} from "@material-ui/icons";
import { getMembers } from "../../logic/APIHandler";
import {
  ExcelFile,
  ExcelSheet,
  formatMemberExcel
} from "../../logic/Handler";

import Sidebar from "../../components/Sidebar_Com";
import TableV2 from '../../components/Table_Com_V2';
import StatusModal from "../../components/Modal_Com";
import Spinner from "../../components/Loading_Com";

class Member extends Component {
  constructor(props) {
    super(props);
    let currPage = 0;
    if (props.match.params && props.match.params.page) {
      currPage = parseInt(props.match.params.page);
    }
    this.state = {
      data: [],
      allMembers: [],
      firstMemberId: null,
      lastMemberId: null,
      status: "active",
      suspendList: false,
      suspendError: '',
      open: false,
      openJWT: false,
      openVote: false,
      openPaid: false,
      memberId: "00000000",
      memberCardId: "00000000",
      memberName: "",
      isLoading: false,
      isSidebarOpen: "slide-out",
      startPage: currPage,
      isInitial: true,
      search: localStorage.getItem('search') || '',
      totalData: 0,
      params: null,
    };
    this.excelRef = React.createRef();
    this.windowWidth = window.innerWidth;
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    localStorage.setItem('path', props.location.pathname);
  }

  columns = [
    {
      title: "No Member",
      field: "member_card_id",
      tooltip: "ID kartu member",
      width: "120px",
      defaultSort: "desc"
    },
    {
      title: "Nama",
      field: "name",
      tooltip: "Nama member",
      width: "150px",
    },
    {
      title: "Membership",
      field: "membership",
      tooltip: "Status membership",
      width: "120px",
      render: (rowData) =>
        rowData.membership === "silver" ? (
          <h5 className="membership-bar-silver">Silver</h5>
        ) : rowData.membership === "platinum" ? (
          <h5 className="membership-bar-platinum">Platinum</h5>
        ) : (
          <h5 className="membership-bar-honorary">Honorary</h5>
        )
    },
    {
      title: "Status",
      field: "status",
      tooltip: "Status member",
      render: rowData => rowData.status === "active"
        ? <h5 className="status-bar-active">Active</h5>
        : <h5 className="status-bar-suspended">Suspended</h5>,
    }
  ];
  actions = [
    {
      icon: () => <FindInPage />,
      tooltip: "Lihat Detail Member",
      onClick: (_, rowData) => {
        this.props.history.push({
          pathname: "/member-detail/" + rowData.member_card_id,
          state: {
            firstId: this.state.firstMemberId,
            lastId: this.state.lastMemberId,
          },
        });
      }
    },
    {
      icon: () => <CheckCircleOutline />,
      tooltip: "Ubah Status Member",
      onClick: (_, rowData) => {
        this.setState({
          open: true,
          memberId: rowData._id
        });
      }
    },
    {
      disabled: rowData => rowData.is_paid === true,
      icon: () => <AttachMoney />,
      tooltip: "Change Is Paid",
      onClick: (_, rowData) => {
        this.setState({
          openPaid: true,
          memberId: rowData._id,
          memberName: rowData.name
        });
      }
    },
    {
      disabled: rowData => rowData.isVote === false,
      icon: () => <Edit />,
      tooltip: "Reset Status Vote",
      onClick: (_, rowData) => {
        this.setState({
          openVote: true,
          memberCardId: rowData.member_card_id,
          memberName: rowData.name
        });
      }
    },
  ];

  // CLose change status modal
  handleClose() {
    this.setState({
      open: false,
      openVote: false,
      openPaid: false
    });
  }

  // Toggle sidebar menu in responsive
  toggleSidebar() {
    if (this.state.isSidebarOpen === "slide-out") {
      this.setState({
        isSidebarOpen: "slide-in"
      });
    } else {
      this.setState({
        isSidebarOpen: "slide-out"
      });
    }
  }

  // Create formatted excel data & download it
  async downloadExcel() {
    this.setState({ isLoading: true });

    const members = await getMembers(null, true);
    if (members[0] && members[0].status === 200) {
      const res = formatMemberExcel(members[0].data);
      this.setState({ excelData: res, isLoading: false }, () => {
        if (this.excelRef.current !== null) {
          this.excelRef.current.click();
        }
      });
    } else if (members[1] && members[1].status === 401) {
      this.setState({
        openJWT: true,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: true });
    }
  }

  // Members in this page will be filtered based on active member only
  async getData(params) {
    this.setState({ isLoading: true });
    const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
    const payload = {
      page: params && params.page ? params.page :  page,
      sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'member_card_id',
      sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'desc',
      status: 'active',
      ...this.state.search && { search: this.state.search },
    };

    this.setState({ params: payload });
    localStorage.setItem('sortBy', payload.sortBy);
    localStorage.setItem('sort', payload.sort);

    let members = await getMembers(payload, false);
    if (members[0] && members[0].status === 200) {
      const result = members[0].data.datas;
      this.setState({
        data: result,
        allMembers: members[0].data.datas,
        firstMemberId: result && result[result.length - 1].member_card_id,
        lastMemberId: result && result[0].member_card_id,
        totalData: members[0].data.total,
      });
    } else if (members[1] && members[1].status === 401) {
      this.setState({
        openJWT: true
      });
    }
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.getData();
    if (this.props.history.action === 'POP' && localStorage.getItem('path') !== this.props.location.pathname) {
      this.props.history.go(0);
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.props.history.action === 'POP' && localStorage.getItem('path') !== this.props.location.pathname) {
      // this.setState({ isInitial: false });
      this.props.history.go(0);
    }
    setTimeout(() => {
      if (this.state.search.length > 0 && (
        this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
      )) {
        const search = this.state.search.toLowerCase();
        localStorage.setItem('search', search);
        this.props.history.push({ pathname: '/member/0' });
        this.getData({ search, page: 1 });
      } else if (this.state.search.length === 0 && prevState.search) {
        this.props.history.push({ pathname: '/member/0' });
        this.getData({ search: '', page: 1 });
      }
    }, 700);
  }

  render() {
    return (
      <div className="content">
        {this.state.isLoading ? <Spinner /> : ""}
        <Sidebar
          sidebarState={this.windowWidth < 768 ? this.state.isSidebarOpen : ""}
        />

        <StatusModal
          open={this.state.open}
          onClose={this.handleClose}
          onClickCancel={this.handleClose}
          modalType="member"
          id={this.state.memberId}
          color="primary"
          modalTitle="Ganti Status Member"
          submitText="Submit"
          cancelText="Cancel"
          successText="Berhasil update status member!"
          showModal={(error) => this.setState({ suspendList: true, suspendError: error })}
        />
        <StatusModal
          open={this.state.openVote}
          onClose={this.handleClose}
          onClickCancel={this.handleClose}
          modalType="reset-is-vote"
          id={this.state.memberCardId}
          dataName={this.state.memberName}
          color="secondary"
          modalTitle="Reset Status Vote Member?"
          submitText="Reset"
          cancelText="Cancel"
          successText="Berhasil reset status vote member!"
          errorText="Gagal reset status vote member!"
        />
        <StatusModal
          open={this.state.openPaid}
          onClose={this.handleClose}
          onClickCancel={this.handleClose}
          modalType="renew-member"
          id={this.state.memberId}
          dataName={this.state.memberName}
          color="secondary"
          modalTitle="Perpanjang Membership"
          submitText="Submit"
          cancelText="Cancel"
          successText="Berhasil memperpanjang membership!"
          errorText="Gagal memperpanjang membership!"
        />
        <StatusModal
          open={this.state.openJWT}
          modalType="handle-jwt"
          modalTitle="Token Anda Sudah Expire"
        />
        <StatusModal
          open={this.state.suspendList}
          modalType="suspend-pic"
          modalTitle="Gagal Update Status Member"
          errorText={this.state.suspendError}
          onClickCancel={() => this.setState({ suspendList: false })}
        />

        <Grid container className="responsive-header">
          <Grid item xs={6}>
            <h2>IDEPRENEURS</h2>
          </Grid>
          <Grid item xs={6} className="menu-icon-btn">
            <Button variant="outlined" onClick={this.toggleSidebar}>
              {this.state.isSidebarOpen === "slide-out" ? (
                <Menu />
              ) : (
                <Close />
              )}
            </Button>
          </Grid>
        </Grid>

        <Grid container className="grid-container">
          <Button
            variant="contained"
            className="download-btn"
            style={{ marginBottom: "20px" }}
            onClick={this.downloadExcel}
          >
            <CloudDownload style={{ marginRight: "10px" }} />
            Download Excel
          </Button>
          <TableV2
              title="Tabel Member"
              path="/member"
              columns={this.columns}
              actions={this.actions}
              data={this.state.data}
              total={this.state.totalData}
              search={this.state.search}
              setSearch={val => this.setState({ search: val })}
              refetchTableData={params => this.getData(params)}
              params={this.state.params}
          />
        </Grid>
        <div
          style={{
            display: "inline-block",
            width: "0",
            pointerEvents: "none"
          }}
        >
          <ExcelFile
            filename="Data Lengkap Member"
            element={<Button innerRef={this.excelRef}></Button>}
          >
            <ExcelSheet dataSet={this.state.excelData} name="Data Member" />
          </ExcelFile>
        </div>
      </div>
    );
  }
}

export default Member;
