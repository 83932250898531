import { getMembers } from '../../logic/APIHandler';
import { filterData } from '../../logic/Handler';
import moment from 'moment';

export const chartColor = [
    "#ACD1AF",  //red
    "#F47174",  //green
    "#93CAED",  //blue
    "#EEEE9B",   //yelow
    "#F5CA7B",  //orange
    "#AF8FE9",  //purple
    "#E5CCD6",  //pink
];

/**
 * Get active and inactive members
 */
export const getActiveInactiveMembers = async () => {
    let activeMembers = [];
    let inactiveMembers = [];
    
    let resp = await getMembers();
    if ( resp[0] ) {
        activeMembers = await filterData(resp[0].data, 'status', 'active');
        inactiveMembers = await filterData(resp[0].data, 'status', 'inactive');
    }

    return [activeMembers.length, inactiveMembers.length];
}

/**
 * Get registered members per year
 */
export const getRegisteredMembersPerYear = async () => {
    let members = [];
    let barData = [];
    let years = [];
    let resp = await getMembers();
    if ( resp[0] ) {
        members = resp[0].data;
    }
    const currYear = parseInt(moment().format('YYYY'));
    
    for ( let i=2016; i <= currYear; i++ ) {
        barData.push(0);
        years.push(i);
    }

    if ( members ) {
        members.forEach(data => {
            const dataYear = parseInt(moment(data.created_at).format('YYYY'));
            barData[dataYear-2016]++;
        })
    }

    for ( let i=1; i < barData.length; i++ ) {
        barData[i] += barData[i-1];
    }

    return [years, barData];
}

/**
 * Get registered members per year
 */
export const getRegisteredMembersPerMonth = async () => {
    let members = [];
    let lineData = [];
    let resp = await getMembers();
    const currMonth = parseInt(moment().format('MM'));
    const currYear = parseInt(moment().format('YYYY'));
    if ( resp[0] ) {
        members = resp[0].data;
    }

    for ( let i=0; i < currMonth; i++ ) {
        lineData.push(0);
    }

    if ( members ) {
        members.forEach(data => {
            if ( parseInt(moment(data.created_at).format('YYYY')) === currYear ) {
                lineData[parseInt(moment(data.created_at).format('MM')) - 1]++;
            }
        })
    }

    for ( let i=1; i < lineData.length; i++ ) {
        lineData[i] += lineData[i-1];
    }

    return lineData;
}