import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import '../../styles/Form_Style.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { Grid, Button, Snackbar } from '@material-ui/core';
import { postNews, getEvents } from '../../logic/APIHandler';
import { filterUpcomingData } from '../../logic/Handler';
import { TextInput, ImageInput, DropdownInput, URLInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddNews() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [currPosition, setCurrPosition] = useState({ name: "Kiri", value: "left" });
    const [currCategory, setCurrCategory] = useState({ name: "News", value: "news" });
    const [currEventID, setCurrEventID] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const inputRef = useRef('');
    const [state, setState] = React.useState({
        data: [],
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        getData();
    }, []);

    // Get list of upcoming event 
    const getData = async data => {
        setIsLoading(true);
        let events = await getEvents();
        if (events[0] && events[0].status === 200) {
            let filteredEvents = await filterUpcomingData(events[0].data, moment());
            setState({
                data: filteredEvents,
            })
        }
        else if ( events[1] && events[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log("Can't load events or still loading.")
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Handle dropdown onChange event
    const handleDropdownChange = (data) => {
        setCurrCategory(data);
    }
    const handleDropdownEventChange = (data) => {
        setCurrEventID(data)
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Focus to dropdown field when value is null and required
    const checkNullDropdown = () => {
        if ( currCategory.value === "event" ) {
            inputRef.current.focus();
        }
    }

    // Send new partner data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            data["category"] = "news";
            data["position"] = currPosition;
            let resp = await postNews(data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/news/0");
            }
            else {
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <StatusModal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuat News
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            Gagal Membuat News
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah News Baru</h1>

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for name */}
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama"
                                        className="form-left-field add-margin-bottom"
                                        validation={register({ required: "*nama harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <DropdownInput
                                        label="Kategori"
                                        name="category"
                                        className="form-right-field add-margin-bottom tf-label"
                                        keepData={handleDropdownChange}
                                        disableClearable={true}
                                        control={control}
                                        options={[
                                            { name: "News", value: "news" },
                                            { name: "Event", value: "event" },
                                            { name: "Kustom", value: "custom" },
                                        ]}
                                        getOptionLabel={(option) => option.name}
                                        value={currCategory}
                                        required={false}
                                        disabled={true}
                                    />
                                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    {/* Input for category */}
                                    <DropdownInput
                                        label="Posisi"
                                        name="position"
                                        className="form-right-field add-margin-bottom tf-label"
                                        keepData={(data) => setCurrPosition(data)}
                                        disableClearable={true}
                                        control={control}
                                        options={[
                                            { name: "Kiri", value: "left" },
                                            { name: "Tengah", value: "center" },
                                            { name: "Kanan", value: "right" },
                                        ]}
                                        getOptionLabel={(option) => option.name}
                                        value={currPosition}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for title */}
                                    <TextInput
                                        label="Judul *"
                                        name="title"
                                        placeholder="Judul News"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*judul harus diisi" })}
                                        errors={errors.title}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for sub-title */}
                                    <TextInput
                                        label="Sub Judul"
                                        name="sub_title"
                                        placeholder="Sub Judul News"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for description */}
                                    <TextInput
                                        label="Deskripsi"
                                        name="description"
                                        placeholder="Deskripsi"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                            </Grid>

                            {currCategory.value === "event"?
                                // Input for event ID
                                (<DropdownInput
                                    label="Pilih Acara *"
                                    name="event_id"
                                    className="full-width add-margin-bottom tf-label"
                                    keepData={handleDropdownEventChange}
                                    control={control}
                                    options={state.data}
                                    getOptionLabel={(option) => option.name}
                                    value={currEventID}
                                    rules={{ required: "*pilih minimal 1 acara" }}
                                    errors={errors.event_id}
                                    errorClassName="text-required"
                                    inputRef={currCategory.value==="event"? inputRef : ""}
                                    // required={true}
                                />) : ""
                            }
                            
                            {/* Input for link */}
                            <URLInput
                                label="Link"
                                name="link"
                                placeholder="Link"
                                className="full-width add-margin-bottom"
                                validation={register}
                                errors={errors.link}
                                errorClassName="text-required"
                            />

                            <ImageInput
                                name="banner"
                                label="Banner *"
                                ratioLabel="Ratio 8:3"
                                validation={register}
                                errors={errors.banner}
                                isEdit={false}
                                control={control}
                                defaultImg={false}
                                canDelete={false}
                                error_label="* foto banner"
                                overallClassName="full-input-image eight-three-size add-margin-top"
                            />
                            {/* {currCategory.value !== "event"?
                                // Input for image
                                <ImageInput
                                    name="banner"
                                    label="Banner"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    defaultImg={false}
                                    canDelete={true}
                                    overallClassName="full-input-image"
                                /> : ""
                            } */}

                            <Button
                                type="submit"
                                onClick={checkNullDropdown}
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}