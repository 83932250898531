import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../../styles/Form_Style.css';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
import { getSponsor, editSponsor, postImageSponsor, deleteImageSponsor } from '../../../logic/APIHandler';
import { TextInput, ImageInput, SwitchInput, DropdownInput } from '../../../logic/FormInput';
import { renderFormFields } from './SponsorHandler';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailSponsor(props) {

    const file = '';
    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [editGallery, setEditGallery] = useState('Edit Galeri');
    const [edit, setEdit] = useState('Edit Sponsor');
    const [pageTitle, setPageTitle] = useState('Detail Sponsor');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [hideData, setHideData] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idSponsor, setIDSponsor] = useState(null);
    const [waNumber, setWANumber] = useState('');
    const [initialLevel, setInitialLevel] = useState('');
    const [currLevel, setCurrLevel] = useState({ label: "Bronze", value: "Bronze" });
    const [inputList, setInputList] = useState([]);
    const [images, setImages] = useState([]);
    const [counter, setCounter] = useState(0);
    const [wordCounter, setWordCounter] = useState(0);
    const [description, setDescription] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        logo: "",
        is_show: false,
        yes_no: 'Tidak',
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idSponsor = props.match.params.id;
        setIDSponsor(idSponsor);
        if ( idSponsor ) {
            getData(idSponsor);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get sponsor's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getSponsor(id);
        if (resp[0] && resp[0].status === 200) {
            const sponsor = resp[0].data;
            setValue("name", sponsor.name || '');
            setValue("level", { label: sponsor.level, value: sponsor.level });
            setCurrLevel({ label: sponsor.level, value: sponsor.level });
            setInitialLevel(sponsor.level);
            setInstagram(sponsor.instagram.link || '');
            setDescription(sponsor.description || '');
            if (sponsor.description) setWordCounter(sponsor.description.length);
            if ( sponsor.level === "Gold" ) {
                setFacebook(sponsor.facebook.link || '');
                setTwitter(sponsor.twitter.link || '');
                setWebsite(sponsor.website.link || '');
                setTiktok(sponsor.tiktok.link || '');
                setWANumber(typeof sponsor.whatsapp === "string"? sponsor.whatsapp : sponsor.whatsapp.link);
            }
            if ( sponsor.gallery && sponsor.gallery.length > 0 ) {
                let galleries = [];
                for (let i=0; i < sponsor.gallery.length; i++) {
                    galleries.push({gallery: sponsor.gallery[i]})
                }
                setInputList([
                    ...inputList, ...galleries
                ]);
                setCounter(sponsor.gallery.length);
            }
            setState({
                ...state,
                logo: (sponsor.logo !== "undefined")? sponsor.logo : "",
                is_show: sponsor.is_show,
                yes_no: (sponsor.is_show? 'Ya':'Tidak'),
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle switch event
    const handleChange = (event) => {
        setState({
            ...state,
            yes_no: (event.target.checked? 'Ya' : 'Tidak'),
            [event.target.name]: event.target.checked
        });
    };

    const onChangeLevel = async (selectedLevel) => {
        setCurrLevel(selectedLevel);
        setTimeout(() => {
            setDescription(selectedLevel.value === 'Silver' ? description.substring(0, 126) : description);
            if (selectedLevel.value === 'Silver') setWordCounter(description.substring(0, 126).length);
        }, 0);
    };

    // handle click event of the Remove button & remove image using API
    const handleRemoveClick = async (index, isChangeLevel) => {
        setIsLoading(true);
        const updatedImages = images.filter(image => image.idx !== index);
        let resp = await deleteImageSponsor(idSponsor, index);
        if (!isChangeLevel) {
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
            }
            else {
                console.log("Delete non-submited image")
            }
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
            setImages(updatedImages);
            setCounter(counter - 1);
            setIsLoading(false);
        }
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {gallery: ""}]);
        setCounter(counter + 1);
    };

    // Handle event when image is assigned
    const handleChangeGallery = event => {
        const temp = [];
        inputList.forEach(val => {
            if ( val.gallery !== "" ) {
                temp.push({gallery: val.gallery});
            }
        });
        temp.push({gallery: URL.createObjectURL(event.target.files[0])})
        setInputList([ ...temp ]);
        setImages([
            ...images,
            { gallery: event.target.files[0], idx: (inputList.length - 1) }
        ]);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Sponsor" ) {
            setEdit("Cancel");
            setPageTitle("Edit Sponsor")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }
    // Handle toggle edit gallery button
    const onClickEditGallery = () => {
        if ( editGallery === "Edit Galeri" ) {
            setEdit("Cancel");
            setEditGallery("Cancel");
            setHide("");
            setNonHide("");
            setHideData("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    const onChangeHandler = (type, val) => {
        if (type === 'desc') {
            setDescription(val);
            setWordCounter(val.length);
        } 
        else if (type === 'instagram') {
            setInstagram(val);
        }
        else if (type === 'facebook') {
            setFacebook(val);
        }
        else if (type === 'twitter') {
            setTwitter(val);
        }
        else if (type === 'website') {
            setWebsite(val);
        }
        else if (type === 'tiktok') {
            setTiktok(val);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data sponsor to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = "";
            if ( hideData ) {
                if ( images.length > 0 ) {
                    resp = await postImageSponsor(idSponsor, images);
                }
                else {
                    if ( inputList.length > 0 ) {
                        resp = [{ status: 200 }];
                    }
                    else {
                        resp = [
                            null, { message: "Tidak ada gambar yang dimasukkan." }
                        ];
                    }
                }
            }
            else {
                if (initialLevel !== currLevel.value) {
                    let totalImage = 0;
                    if (currLevel.value === 'Silver') totalImage = 2;
                    if (currLevel.value !== 'Gold') {
                        for (let i = counter-1; i >= totalImage; i--) {
                            await handleRemoveClick(i);
                        }
                    }
                }
                resp = await editSponsor(idSponsor, data);
            }
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Sponsor
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container className={hideData}>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <Grid container className={hideData}>
                            <Grid item xs={12}>
                                {/* Input for name */}
                                <TextInput
                                    label="Nama"
                                    name="name"
                                    placeholder="Nama"
                                    className="full-width add-margin-bottom"
                                    validation={register({ required: "*nama harus diisi" })}
                                    errors={errors.name}
                                    errorClassName="text-required"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for image */}
                                <ImageInput
                                    name="logo"
                                    label="Logo"
                                    ratioLabel="Ratio 1:1"
                                    validation={register}
                                    errors={errors.logo}
                                    isEdit={true}
                                    control={control}
                                    defaultImg={state.logo}
                                    canDelete={state.logo? false : true}
                                    disabled={isDisabled}
                                    overallClassName="full-input-image add-margin-bottom"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for is_show */}
                                <SwitchInput
                                    legend="Tampilkan Sponsor?"
                                    label={state.yes_no}
                                    name="is_show"
                                    className={`switch-layout add-margin-bottom ${isDisabled? "disabled-border":""}`}
                                    value={state.is_show}
                                    checked={state.is_show}
                                    onChange={handleChange}
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ position: "relative" }}>
                                <DropdownInput
                                    label="Level"
                                    name="level"
                                    className="full-width add-margin-bottom tf-label"
                                    keepData={(selectedLevel) => onChangeLevel(selectedLevel)}
                                    disableClearable={true}
                                    control={control}
                                    options={[
                                        { label: "Bronze", value: "Bronze" },
                                        { label: "Silver", value: "Silver" },
                                        { label: "Gold", value: "Gold" },
                                    ]}
                                    getOptionLabel={(option) => option.label}
                                    value={currLevel}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            {
                                renderFormFields(
                                    currLevel.value,
                                    register,
                                    errors,
                                    waNumber,
                                    setWANumber,
                                    description,
                                    wordCounter,
                                    instagram,
                                    facebook,
                                    twitter,
                                    website,
                                    tiktok,
                                    onChangeHandler,
                                    isDisabled,
                                )
                            }
                        </Grid>

                        { currLevel && currLevel.value !== "Bronze" ?
                        <Grid container>
                            <Grid item xs={12} className={nonHide} style={{ marginTop: "30px" }}>
                                <Grid container className={nonHide}>
                                    <Grid item xs={12}>
                                        <h1
                                            style={{ marginTop: 0 }}
                                            className={(hide === ""? "no-margin-bottom" : "")}
                                        >
                                            Galeri
                                        </h1>
                                        <p className={`input-img-terms ${hide}`}>
                                            * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                        </p>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ padding: "30px 30px 0" }} className={`
                                    gallery-section add-margin-bottom 
                                    ${isDisabled? "disabled-border" : "" } 
                                    ${nonHide}
                                `}>

                                    {/* Button show when there is no image */}
                                    { counter > 0 || hideData ? <></> :
                                        <Grid item xs={12} style={{ paddingBottom: 30 }}>
                                            <p style={{ textAlign: "center" }}>
                                                Tidak ada foto
                                            </p>
                                        </Grid>
                                    }

                                    {/* Input for image */}
                                    {inputList.map((value, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4}
                                                key={`gallery-${index}`} style={{ marginBottom: "25px" }}
                                            >
                                                <ImageInput
                                                    name={`gallery${index}`}
                                                    label={`Foto ${index+1}`}
                                                    validation={register}
                                                    isEdit={true}
                                                    control={control}
                                                    defaultImg={value.gallery}
                                                    disabled={true}
                                                    hideDeleteBtn="hide"
                                                />
                                                <Button
                                                    color="primary" variant="contained"
                                                    className={`delete-btn-gallery ${hide}`}
                                                    onClick={() => handleRemoveClick(index)}
                                                >Remove</Button>
                                            </Grid>
                                        );
                                    })}
                                    <Grid item xs={12} md={4}>
                                        <label>
                                            <input
                                                style={{ display: "none" }}
                                                value={file}
                                                type="file"
                                                accept="image/*"
                                                onInput={handleChangeGallery}/>
                                            <span
                                                onClick={handleAddClick}
                                                className={`add-new-gallery ${hide} 
                                                    ${
                                                        (currLevel.value === "Gold" && counter < 5) || (currLevel.value === "Silver" && counter < 2) ?
                                                            "" : "hide"
                                                    }
                                                `}
                                            >+</span>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> : <></>
                        }

                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    className={`edit-btn ${nonHide} ${hideData}`}
                                    onClick={onClickEdit}
                                >{edit}</Button>
                                { currLevel && currLevel.value !== "Bronze" ?
                                    <Button
                                        variant="contained"
                                        className={`edit-btn ${nonHide} ${hideData}`}
                                        style={{ marginRight: 10 }}
                                        onClick={onClickEditGallery}
                                    >Edit Galeri</Button> : <></>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}