import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Grid, Button } from "@material-ui/core";

export default function PageNotFound() {
    return (
        <Container className="login-container">
             <Grid container>
                <Grid item xs={12} id="login-form" className="page-not-found">
                    <h1 className="verdana-bold">404</h1>
                    <h2>PAGE NOT FOUND</h2>
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                        <Button variant="contained" className="verdana-bold">
                            Back to Home
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
        </Container>
    );
}