import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Grid, Button } from "@material-ui/core";

export default function Unauthorized() {
    return (
        <Container className="login-container unauthorized">
             <Grid container>
                <Grid item xs={12} id="login-form" className="page-not-found">
                    <h1 className="verdana-bold">401</h1>
                    <h2>PAGE UNAUTHORIZED</h2>
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                        <Button variant="contained" className="verdana-bold">
                            Back to Home
                        </Button>
                    </NavLink>
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            className="verdana-bold unauthorized-logout"
                            onClick={() => localStorage.clear()}
                        >
                            Logout
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
        </Container>
    );
}