import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../../styles/Form_Style.css';
import city from '../../../assets/data/Cities';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import { Grid, Button, Snackbar } from '@material-ui/core';
import { postMerchant } from '../../../logic/APIHandler';
import { TextInput, EmailInput, DropdownInput, PhoneInput } from '../../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddMerchant() {

    const history = useHistory();
    const numberRegex = /^[0-9]*$/;
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [picNumber, setPICNumber] = useState("");
    const [cityData, setCityData] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        let res = [];
        city.forEach(val => {
            res.push({
                value: val, label: val,
            });
        });
        setCityData(res);
    }, []);

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    if ( event.target.name === "phone_number" ) {
                        setPhoneNumber(event.target.value);
                    }
                    else if ( event.target.name === "pic_contact" ) {
                        setPICNumber(event.target.value);
                    }
                }
            }
            // only allow numbers after first input
            else {
                if ( numberRegex.test(value.substring(1)) ) {
                    if ( event.target.name === "phone_number" ) {
                        setPhoneNumber(event.target.value);
                    }
                    else if ( event.target.name === "pic_contact" ) {
                        setPICNumber(event.target.value);
                    }
                }
            }
        }
        else {
            if ( event.target.name === "phone_number" ) {
                setPhoneNumber(event.target.value);
            }
            else if ( event.target.name === "pic_number" ) {
                setPICNumber(event.target.value);
            }
        }
    }

    // Send new merchant data to server
    const onSubmit = async data => {
        setIsLoading(true);
        if ( data.city ) {
            if ( data.city.value !== "Lainnya" ) {
                data["city"] = data.city.value;
            }
            else {
                data["city"] = data.city_input;
            }
        }
        let resp = await postMerchant(data);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/merchant-benefit/0");
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Membuat Merchant
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Merchant Baru</h1>

                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            label="Nama Usaha *"
                                            name="name"
                                            placeholder="Nama Usaha"
                                            className="form-left-field"
                                            validation={register({ required: "*nama usaha harus diisi" })}
                                            errors={errors.name}
                                            errorClassName="text-required-half"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            label="Nama Pemilik *"
                                            name="applier_name"
                                            placeholder="Nama Pemilik"
                                            className="form-right-field"
                                            validation={register({ required: "*nama pemilik usaha harus diisi" })}
                                            errors={errors.applier_name}
                                            errorClassName="text-required-half half-right"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <EmailInput
                                            label="Email *"
                                            name="email"
                                            placeholder="Email"
                                            className="form-left-field"
                                            validation={register}
                                            errors={errors.email}
                                            errorClassName="text-required-half"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PhoneInput
                                            label="Nomor Kontak *"
                                            name="phone_number"
                                            placeholder="Nomor Kontak"
                                            className="form-right-field"
                                            value={phoneNumber}
                                            onChange={onChangePhoneNumber}
                                            validation={register}
                                            errors={errors.phone_number}
                                            errorClassName="text-required-half half-right"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            label="PIC"
                                            name="pic"
                                            placeholder="Nama PIC"
                                            className="form-left-field"
                                            validation={register}
                                            errors={errors.pic}
                                            errorClassName="text-required-half"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PhoneInput
                                            label="Nomor Kontak PIC"
                                            name="pic_contact"
                                            placeholder="Nomor Kontak PIC"
                                            className="form-right-field"
                                            value={picNumber}
                                            onChange={onChangePhoneNumber}
                                            validation={register}
                                            isNotRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            label="Kategori Bisnis *"
                                            name="business_category"
                                            placeholder="Kategori Bisnis"
                                            className="form-left-field"
                                            validation={register({ required: "*kategori bisnis harus diisi" })}
                                            errors={errors.business_category}
                                            errorClassName="text-required-half"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DropdownInput
                                            label="Kota"
                                            name="city"
                                            className="form-right-field add-margin-bottom tf-label"
                                            keepData={(val) => setSelectedCity(val)}
                                            disableClearable={false}
                                            control={control}
                                            options={cityData}
                                            getOptionLabel={(option) => option.label}
                                            value={selectedCity}
                                            required={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ display: selectedCity &&
                                        selectedCity.value == "Lainnya"? "block" : "none" }}
                                    >
                                        <TextInput
                                            label="Nama Kota *"
                                            name="city_input"
                                            placeholder="Nama Kota Lainnya"
                                            className="full-width add-margin-bottom"
                                            validation={register({ required: 
                                                selectedCity && selectedCity.value == "Lainnya"?
                                                    "*nama kota lainnya harus diisi" : false
                                            })}
                                            errors={errors.city_input}
                                            errorClassName="text-required"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Alamat Usaha *"
                                            name="address"
                                            placeholder="Alamat Usaha"
                                            className="full-width add-margin-bottom"
                                            validation={register({ required: "*alamat usaha harus diisi" })}
                                            errors={errors.address}
                                            errorClassName="text-required"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            multiline={true}
                                            rows={3}
                                            label="Media Sosial"
                                            name="sosmed"
                                            placeholder="Media Sosial"
                                            className="full-width add-margin-bottom"
                                            validation={register}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <TextInput
                                            multiline={true}
                                            rows={3}
                                            label="Member Benefit *"
                                            name="member_benefit"
                                            placeholder="Promo Untuk Member"
                                            className="full-width add-margin-bottom"
                                            validation={register({ required: "*promo member harus diisi" })}
                                            errors={errors.member_benefit}
                                            errorClassName="text-required"
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <TextInput
                                            multiline={true}
                                            rows={3}
                                            label={`Syarat & Ketentuan *`}
                                            name="terms_and_conditions"
                                            placeholder={`Syarat & Ketentuan Untuk Member`}
                                            className="full-width add-margin-bottom"
                                            validation={register({ required: `*syarat & ketentuan harus diisi` })}
                                            errors={errors.terms_and_conditions}
                                            errorClassName="text-required"
                                        />
                                    </Grid>
                                </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}