import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Button, Snackbar } from "@material-ui/core";
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { getAdmin, editAdmin } from '../../logic/APIHandler';
import { TextInput, EmailInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailAdmin(props) {

    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Admin');
    const [pageTitle, setPageTitle] = useState('Detail Admin');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idAdmin, setIDAdmin] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const [currLevel, setCurrLevel] = useState(null);
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        const idAdmin = props.match.params.id;
        setIDAdmin(idAdmin);
        if ( idAdmin ) {
            getData(idAdmin);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get admin's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getAdmin(id);
        if (resp[0] && resp[0].status === 200) {
            const admin = resp[0].data;
            setValue("name", admin.name);
            setValue("email", admin.email);
            setValue("level",
                admin.level === "database"? "Database"
                : admin.level === "merchant"? "Merchant"
                : "Acara"
            );
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Admin" ) {
            setEdit("Cancel");
            setPageTitle("Edit Admin")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await editAdmin(idAdmin, data);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Admin
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        {/* Input for admin level */}
                        <TextInput
                            label="Jenis Admin"
                            name="level"
                            placeholder="Jenis Admin"
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={true}
                        />
                        {/* Input for name */}
                        <TextInput
                            label="Nama"
                            name="name"
                            placeholder="Nama"
                            className="full-width add-margin-bottom"
                            validation={register({ required: "*nama harus diisi" })}
                            errors={errors.name}
                            errorClassName="text-required"
                            disabled={isDisabled}
                        />
                        {/* Input for email */}
                        <EmailInput
                            label="Email"
                            name="email"
                            placeholder="Email"
                            className="full-width add-margin-bottom"
                            validation={register}
                            errors={errors.email}
                            errorClassName="text-required"
                            disabled={isDisabled}
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}