import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Button, Snackbar } from "@material-ui/core";
import '../../../styles/Form_Style.css';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
import { getExecutiveBoard, editExecutiveBoard, getLocations, getJabatan } from '../../../logic/APIHandler';
import { TextInput, ImageInput, DropdownInput, GroupedDropdown } from '../../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailPengurus(props) {

    // Form State
    const [locationNames, setLocationNames] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currCateg, setCurrCateg] = useState(null);
    const [positions, setPositions] = useState([]);
    const [currPosition, setCurrPosition] = useState(null);
    const [cityData, setCityData] = useState([]);
    const [state, setState] = useState({
        image: "",
        member_card_id: "00000000",
        member_name: "...",
    });

    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Pengurus');
    const [pageTitle, setPageTitle] = useState('Detail Pengurus');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idExecutive, setIDExecutive] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        const idExecutive = props.match.params.id;
        setIDExecutive(idExecutive);
        if ( idExecutive ) {
            getData(idExecutive);
            getAllPositions();
            getAllLocations();
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get all locations
    const getAllLocations = async() => {
        setIsLoading(true);
        let resp = await getLocations();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data;
            let categs = [], names = [];
            result.forEach(res => {
                if ( categs.filter(temp => temp.category === res.category).length < 1 ) {
                    categs.push(res);
                }
                if ( res.category !== "Pusat" && res.category !== "Luar Wilayah" ) {
                    names.push(res);
                }
            });
            setCategories(categs);
            setLocationNames(names);
            setIsLoading(false);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText(resp[0].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    const getAllPositions = async() => {
        setIsLoading(true);
        let resp = await getJabatan();
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data.map(item => ({
                label: item.name,
                value: item._id,
            }));
            setPositions(result);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText(resp[0].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Get executive board's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getExecutiveBoard(id);
        if (resp[0] && resp[0].status === 200) {
            const executive = resp[0].data;
            if (executive.position_id) {
                setCurrPosition({
                    label: executive.position_id.name,
                    value: executive.position_id._id,
                });
            }
            if ( executive.location_id ) {
                setValue("location", executive.location_id);
                setCurrCateg(executive.location_id);
            }
            setState({
                ...state,
                image: (executive.member_id.photo !== "undefined")? executive.image_executiveboard : "",
                member_card_id: executive.member_id.member_card_id,
                member_name: executive.member_id.name,
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else if (resp[1].message) {
                setErrorText(resp[1].message);
            }
            else {
                setErrorText(resp[1].error[0].msg);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Pengurus" ) {
            setEdit("Cancel");
            setPageTitle("Edit Pengurus")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data executive board to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            if (!data['title']) data['title'] = currPosition;
            if ( currCateg.category === 'Pusat' || currCateg.category === 'Luar Wilayah' ) {
                data['chapter'] = currCateg._id;
            }
            else {
                data['chapter'] = data.location._id;
            }

            let resp = await editExecutiveBoard(idExecutive, data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Pengurus
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <Grid container className="member-info">
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={12} style={{ marginBottom: "25px" }}>
                                        <h2 className="center-responsive" style={{ margin: "0" }}>Data Member</h2>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <p className="member-id-text">ID Member</p>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <p className="member-id-text">: {state.member_card_id}</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <p className="member-id-text">Nama</p>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <p className="member-id-text">: {state.member_name}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ImageInput
                                    name="image_executiveboard"
                                    label="Foto Pengurus"
                                    ratioLabel="Ratio 1:1"
                                    isEdit={true}
                                    validation={register}
                                    defaultImg={state.image}
                                    canDelete={false}
                                    disabled={isDisabled}
                                    overallClassName="half-input-image"
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} style={{ position: "relative" }}>
                                <DropdownInput
                                    label="Jabatan"
                                    name="title"
                                    className="full-width add-margin-bottom tf-label"
                                    keepData={(data) => setCurrPosition(data)}
                                    disableClearable={true}
                                    control={control}
                                    options={positions}
                                    getOptionLabel={(option) => option.label}
                                    value={currPosition}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ position: "relative" }}>
                                <DropdownInput
                                    label="Kategori Wilayah"
                                    name="categories"
                                    className="full-width add-margin-bottom tf-label"
                                    keepData={(data) => {
                                        setValue("location", null);
                                        setCurrCateg(data);
                                    }}
                                    disableClearable={true}
                                    control={control}
                                    options={categories}
                                    getOptionLabel={(option) => option.category}
                                    value={currCateg}
                                    required={true}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ position: "relative" }}>
                                <GroupedDropdown
                                    label="Nama Wilayah *"
                                    labelPosition={ currCateg && currCateg.category !== "Pusat"
                                        && currCateg.category !== "Luar Wilayah" ?
                                            "" : "hide-label"
                                    }
                                    name="location"
                                    className={`full-width add-margin-bottom grouped-field
                                        ${ isDisabled? "disabled-field" : "" }
                                        ${ currCateg && currCateg.category !== "Pusat"
                                            && currCateg.category !== "Luar Wilayah" ?
                                                "" : "hide"
                                        }
                                    `}
                                    rules={{
                                        required: currCateg
                                            && currCateg.category !== "Pusat"
                                            && currCateg.category !== "Luar Wilayah"
                                            && "*nama wilayah harus dipilih"
                                    }}
                                    control={control}
                                    options={ !currCateg ? null :
                                        locationNames.filter(res => res.category === currCateg.category)
                                    }
                                    getOptionLabel={option => option.name}
                                    isClearable={false}
                                    defaultValue=""
                                    errors={errors.location}
                                    errorClassName="text-required"
                                />
                            </Grid>
                        </Grid>

                        {/* Input for category */}
                        <TextInput
                                label="Kategori"
                                name="category"
                                placeholder="Kategori"
                                defaultValue="pengurus"
                                className="hide"
                                validation={register}
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}