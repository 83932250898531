import React, { Component } from 'react';
import moment from 'moment';
import { Grid, Button } from "@material-ui/core";
import {
    Menu,
    Close,
    FindInPage,
    Delete,
} from '@material-ui/icons';
import { getEvents } from '../../logic/APIHandler';

import Sidebar from '../../components/Sidebar_Com';
import TableV2 from '../../components/Table_Com_V2';
import StatusModal from '../../components/Modal_Com';
import Spinner from '../../components/Loading_Com';

class PrevEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            eventId: '00000000',
            eventName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
            search: localStorage.getItem('search') || '',
            totalData: 0,
            params: null,
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama kategori acara",
            width: 180,
        },
        {
            title: "Tanggal Acara",
            field: "date",
            tooltip: "Tanggal acara",
            width: 140,
        },
        {
            title: "Waktu Acara",
            field: "time",
            tooltip: "Waktu acara",
            width: 120,
        },
        {
            title: "Lokasi",
            field: "address",
            tooltip: "Lokasi acara",
            width: 140,
        },
        {
            title: "Penanggung Jawab",
            field: "pic",
            tooltip: "Nama penanggung jawab",
            width: 170,
        },
    ]

    actions = [
        {
            icon: () => <FindInPage />,
            tooltip: "Lihat Detail Acara",
            onClick: (_, rowData) => {
                this.props.history.push({
                    pathname: "/acara-lampau-detail/" + rowData._id,
                });
            },
        },
        {
            icon: () => <Delete />,
            tooltip: "Hapus Acara",
            onClick: (_, rowData) => {
                this.handleOpen(rowData);
            },
        },
    ]

    // Show delete event modal
    handleOpen(event) {
        this.setState({
            open: true,
            eventId: event._id,
            eventName: event.name,
        })
    }

    // CLose delete event modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Get evets for table data
    async getData(params) {
        this.setState({ isLoading: true });
        const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
        const payload = {
            page: params && params.page ? params.page :  page,
            sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
            sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
            status: 'past',
            ...this.state.search && { search: this.state.search },
        };

        this.setState({ params: payload });
        localStorage.setItem('sortBy', payload.sortBy);
        localStorage.setItem('sort', payload.sort);

        let events = await getEvents(payload, false);
        if ( events[0] && events[0].status === 200 ) {
            const result = events[0].data.datas;
            result.forEach( (event) => {
                moment.locale('en');
                event.time = moment(event.date).format('hh:mm A');
                moment.locale('id');
                event.date = moment(event.date).format('DD MMMM YYYY');
            });
            this.setState({
                data: result,
                totalData: events[0].data.total,
                isLoading: false,
            });
        }
        else if ( events[1] && events[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({ isLoading: false })
        }
    };

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(_, prevState) {
        setTimeout(() => {
            if (this.state.search.length > 0 && (
              this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
            )) {
                const search = this.state.search.toLowerCase();
                localStorage.setItem('search', search);
                this.props.history.push({ pathname: '/acara-lampau/0' });
                this.getData({ search: '', page: 1 });
            } else if (this.state.search.length === 0 && prevState.search) {
                this.props.history.push({ pathname: '/acara-lampau/0' });
                this.getData({ search: '', page: 1 });
            }
        }, 700);
    };

    render() {
        return (
            <div id="prev-event-page" className="content">
                { this.state.isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <StatusModal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    dataName={this.state.eventName}
                    modalType="past-event"
                    id={this.state.eventId}
                    color="primary"
                    modalTitle="Hapus acara?"
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus acara!"
                    errorText="Gagal menghapus acara!"
                />
                <StatusModal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <Menu /> : <Close />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <TableV2
                        title="Tabel Acara Lampau"
                        path="/acara-lampau"
                        columns={this.columns}
                        actions={this.actions}
                        data={this.state.data}
                        total={this.state.totalData}
                        search={this.state.search}
                        setSearch={val => this.setState({ search: val })}
                        refetchTableData={params => this.getData(params)}
                        params={this.state.params}
                        hideFooterBtn
                    />
                </Grid>
            </div>
        );
    }
}

export default PrevEvent;