import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Button, Snackbar } from "@material-ui/core";
import '../../../styles/Form_Style.css';
import city from '../../../assets/data/Cities';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
import { getMerchant, editMerchant, approveMerchant, deleteMerchant } from '../../../logic/APIHandler';
import { TextInput, ImageInput, GroupedDropdown, URLInput, DateInput,
    EmailInput, PhoneInput, DropdownInput, FileInput } from '../../../logic/FormInput';
import moment from 'moment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailMerchant(props) {

    const history = useHistory();
    const numberRegex = /^[0-9]*$/;
    const { register, handleSubmit, errors, control, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openApproveAlert, setOpenApproveAlert] = useState(false);
    const [openSuccessDeclineAlert, setOpenSuccessDeclineAlert] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorDeclineAlert, setOpenErrorDeclineAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Merchant');
    const [isApproved, setIsApproved] = useState(false);
    const [approveMode, setApproveMode] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [picNumber, setPICNumber] = useState("");
    const [pageTitle, setPageTitle] = useState('Detail Merchant');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idMerchant, setIDMerchant] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [cityData, setCityData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [agreementFile, setAgreementFile] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        logo: "",
        isRegistered: false,
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idMerchant = props.match.params.id;
        setIDMerchant(idMerchant);
        if ( idMerchant ) {
            getData(idMerchant);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get merchant's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getMerchant(id);
        if (resp[0] && resp[0].status === 200) {
            const merchant = resp[0].data;
            setValue("name", merchant.name);
            setValue("applier_name", merchant.applier_name);
            setValue("phone_number", merchant.phone_number);
            setPhoneNumber(merchant.phone_number);
            setValue("sosmed", merchant.sosmed);
            setValue("address", merchant.address);
            let res = [], isInList = false;
            city.forEach(val => {
                if ( merchant.city === val ) isInList = true; 
                res.push({
                    value: val, label: val,
                });
            });
            setCityData(res);
            if ( isInList ) {
                setValue("city", { label: merchant.city, value: merchant.city });
                setSelectedCity({ label: merchant.city, value: merchant.city });
            }
            else {
                if ( merchant.city ) {
                    setValue("city", { label: "Lainnya", value: "Lainnya" });
                    setValue("city_input", merchant.city);
                    setSelectedCity({ label: "Lainnya", value: "Lainnya" });
                }
            }
            setValue("email", merchant.email);
            setValue("pic", merchant.pic);
            setValue("pic_contact", merchant.pic_contact);
            setPICNumber(merchant.pic_contact);
            setValue("business_category", merchant.business_category);
            setValue("address_link", merchant.address_link);
            setValue("member_benefit", merchant.member_benefit);
            setValue("merchant_benefit", merchant.merchant_benefit);
            setValue("terms_and_conditions", merchant.terms_and_conditions);
            setStartDate(merchant.start_duration? merchant.start_duration.substring(0,19) : null);
            setEndDate(merchant.end_duration? merchant.end_duration.substring(0,19) : null);
            setFileName(merchant.cooperation_agreement && merchant.cooperation_agreement.split("/")[4]);
            setAgreementFile(merchant.cooperation_agreement);
            if ( merchant.status !== "registered" ) {
                setIsApproved(true);
                setValue("status", {
                    label: merchant.status.charAt(0).toUpperCase() + merchant.status.slice(1),
                    value: merchant.status
                });
            }
            setState({
                ...state,
                logo: (merchant.logo !== "undefined")? merchant.logo : "",
                isRegistered: (merchant.status === 'registered')? true : false,
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Merchant" ) {
            setEdit("Cancel");
            setPageTitle("Edit Merchant")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Handle decline member
    const onClickDecline = async() => {
        setIsLoading(true);
        let resp = await deleteMerchant(idMerchant);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessDeclineAlert(true);
            await sleep(1000);
            history.replace("/dashboard/0");
        }
        else {
            setOpenErrorDeclineAlert(true);
        }
        setIsLoading(false);
    }

    // Handle toggle approve button
    const onClickApprove = () => {
        if ( edit === "Edit Merchant" ) {
            setEdit("Cancel");
            setPageTitle("Lengkapi Data Merchant")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
            setApproveMode(true);
        }
        else {
            history.go(0);
        }
    }

    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    if ( event.target.name === "phone_number" ) {
                        setPhoneNumber(event.target.value);
                    }
                    else if ( event.target.name === "pic_contact" ) {
                        setPICNumber(event.target.value);
                    }
                }
            }
            // only allow numbers after first input
            else {
                if ( numberRegex.test(value.substring(1)) ) {
                    if ( event.target.name === "phone_number" ) {
                        setPhoneNumber(event.target.value);
                    }
                    else if ( event.target.name === "pic_contact" ) {
                        setPICNumber(event.target.value);
                    }
                }
            }
        }
        else {
            if ( event.target.name === "phone_number" ) {
                setPhoneNumber(event.target.value);
            }
            else if ( event.target.name === "pic_number" ) {
                setPICNumber(event.target.value);
            }
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = () => {
        setOpenSuccessAlert(false);
        setOpenApproveAlert(false);
        setOpenSuccessDeclineAlert(false);
    };
    // Close error toast
    const handleCloseError = () => {
        setOpenErrorAlert(false);
        setOpenErrorDeclineAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated/approved data merchant to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = '';
            // If submit button is to approve merchant
            if ( data.cooperation_agreement[0].size < 26214400 ) {
                if ( approveMode ) {
                    resp = await approveMerchant(idMerchant, data);
                    if (resp[0] && resp[0].status === 200) {
                        setOpenApproveAlert(true);
                        await sleep(1000);
                        history.go(0);
                    }
                }
                // If submit button is to edit merchant's data
                else {
                    resp = await editMerchant(idMerchant, data);
                    if (resp[0] && resp[0].status === 200) {
                        setOpenSuccessAlert(true);
                        await sleep(1000);
                        history.go(0);
                    }
                }
            }
            else {
                resp = [];
                setErrorText("Ukuran file terlalu besar. Batas maksimal 25MB.");
            }
            // If calling API is failed
            if ( !resp[0] || resp[0].status !== 200 ) {
                console.log(resp);
                if ( resp[1] ) {
                    if ( resp[1].data && resp[1].data[0].msg ) {
                        setErrorText(resp[1].data[0].msg);
                    }
                    else {
                        setErrorText(resp[1].message);
                    }
                }
                else {
                    if ( resp[0] ) {
                        setErrorText(resp[0].message);
                    }
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openApproveAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Approve Merchant
                    </Alert>
                </Snackbar>
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Update Merchant
                    </Alert>
                </Snackbar>
                <Snackbar open={openSuccessDeclineAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Tolak Calon Merchant
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorDeclineAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        Gagal Tolak Calon Merchant
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                                {/* Input for name */}
                                <TextInput
                                    label="Nama Usaha *"
                                    name="name"
                                    placeholder="Nama Usaha"
                                    className="form-left-field"
                                    validation={register({ required: "*nama usaha harus diisi" })}
                                    errors={errors.name}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                {/* Input for applier name */}
                                <TextInput
                                    label="Nama Pemilik *"
                                    name="applier_name"
                                    placeholder="Nama Pemilik"
                                    className="form-right-field"
                                    validation={register({ required: "*nama pemilik usaha harus diisi" })}
                                    errors={errors.applier_name}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                {/* Input for email */}
                                <EmailInput
                                    label="Email"
                                    name="email"
                                    placeholder="Email"
                                    className="form-left-field"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                {/* Input for phone number */}
                                <PhoneInput
                                    label="Nomor Kontak"
                                    name="phone_number"
                                    placeholder="Nomor Kontak"
                                    className="form-right-field"
                                    value={phoneNumber}
                                    onChange={onChangePhoneNumber}
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for PIC */}
                                <TextInput
                                    label="PIC"
                                    name="pic"
                                    placeholder="Nama PIC"
                                    className="form-left-field"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                {/* Input for PIC contact */}
                                <PhoneInput
                                    label="Nomor Kontak PIC"
                                    name="pic_contact"
                                    placeholder="Nomor Kontak PIC"
                                    className="form-right-field"
                                    value={picNumber}
                                    onChange={onChangePhoneNumber}
                                    validation={register}
                                    isNotRequired={true}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                {/* Input for business category */}
                                <TextInput
                                    label="Kategori Bisnis *"
                                    name="business_category"
                                    placeholder="Kategori Bisnis"
                                    className="form-left-field"
                                    validation={register({ required: "*kategori bisnis harus diisi" })}
                                    errors={errors.business_category}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DropdownInput
                                    label="Kota"
                                    name="city"
                                    className="form-right-field add-margin-bottom tf-label"
                                    keepData={(val) => setSelectedCity(val)}
                                    disableClearable={false}
                                    control={control}
                                    options={cityData}
                                    getOptionLabel={(option) => option.label}
                                    value={selectedCity}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: selectedCity &&
                                selectedCity.value == "Lainnya"? "block" : "none" }}
                            >
                                <TextInput
                                    label="Nama Kota"
                                    name="city_input"
                                    placeholder="Nama Kota Lainnya"
                                    className="full-width add-margin-bottom"
                                    validation={register({ required: 
                                        selectedCity && selectedCity.value == "Lainnya"?
                                            "*nama kota lainnya harus diisi" : false
                                    })}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                {/* Input for address */}
                                <TextInput
                                    label="Alamat Usaha"
                                    name="address"
                                    placeholder="Alamat Usaha"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                {/* Input for social media */}
                                <TextInput
                                    multiline={true}
                                    rows={3}
                                    label="Media Sosial"
                                    name="sosmed"
                                    placeholder="Media Sosial"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                <TextInput
                                    multiline={true}
                                    rows={3}
                                    label="Member Benefit"
                                    name="member_benefit"
                                    placeholder="Promo Untuk Member"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                <TextInput
                                    multiline={true}
                                    rows={3}
                                    label={`Syarat & Ketentuan`}
                                    name="terms_and_conditions"
                                    placeholder={`Syarat & Ketentuan Untuk Member`}
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ImageInput
                                    name="logo"
                                    label="Logo"
                                    ratioLabel="Ratio 1:1"
                                    validation={register}
                                    errors={errors.logo}
                                    isEdit={true}
                                    control={control}
                                    defaultImg={state.logo}
                                    canDelete={(approveMode? true : (isApproved && state.logo)? false : true)}
                                    disabled={isDisabled}
                                    overallClassName={`full-input-image add-margin-bottom 
                                        ${(approveMode || isApproved? "" : "hide")}
                                    `}
                                />
                            </Grid>

                            <Grid item xs={12} className={`${(approveMode || isApproved? "" : "hide")}`}>
                                <hr style={{ marginBottom: "35px" }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DateInput
                                    label="Durasi Mulai *"
                                    name="start_date"
                                    value={startDate}
                                    maxDate={endDate}
                                    onChange={(val) => setStartDate(moment(val))}
                                    className={`form-left-field ${(approveMode || isApproved? "" : "hide")}`}
                                    validation={register({ required: "*durasi mulai harus diisi" })}
                                    errors={errors.start_date}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateInput
                                    label="Durasi Akhir *"
                                    name="end_date"
                                    value={endDate}
                                    minDate={startDate}
                                    onChange={(val) => setEndDate(moment(val))}
                                    className={`form-right-field ${(approveMode || isApproved? "" : "hide")}`}
                                    validation={register({ required: "*durasi akhir harus diisi" })}
                                    errors={errors.end_date}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for address link */}
                                <URLInput
                                    label="Link Lokasi"
                                    name="address_link"
                                    placeholder="Link Lokasi"
                                    className={`full-width add-margin-bottom 
                                        ${(approveMode || isApproved? "" : "hide")}
                                    `}
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for benefit */}
                                <TextInput
                                    multiline={true}
                                    rows={3}
                                    label="Merchant Benefit"
                                    name="merchant_benefit"
                                    placeholder="Keuntungan Untuk Merchant"
                                    className={`
                                        full-width add-margin-bottom
                                        ${(approveMode || isApproved? "" : "hide")}
                                    `}
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput
                                    label="Dokumen Perjanjian *"
                                    name="cooperation_agreement"
                                    fileName={fileName}
                                    overallClassName={`full-input-image add-margin-bottom 
                                        ${(approveMode || isApproved? "" : "hide")}
                                    `}
                                    agreementFile={agreementFile}
                                    validation={register({ required: "*file perjanjian harus diisi" })}
                                    errors={errors.cooperation_agreement}
                                    errorClassName="text-required"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} className="add-margin-top" style={{ position: "relative" }}>
                                {/* Input for status */}
                                <GroupedDropdown
                                    label="Status"
                                    labelPosition={approveMode || isApproved? "" : "hide"}
                                    name="status"
                                    className={`
                                        full-width add-margin-bottom grouped-field 
                                        ${ isDisabled? 'disabled-field' : '' }
                                        ${(approveMode || isApproved? "" : "hide")}
                                    `}
                                    control={control}
                                    options={[
                                        { label: "Inactive", value: "inactive", },
                                        { label: "Active", value: "active", },
                                    ]}
                                    defaultValue={{ label: "Active", value: "active" }}
                                    isClearable={false}
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`edit-btn ${nonHide} ${(state.isRegistered? 'hide':'')}`}
                                >{edit}</Button>
                                <Button
                                    variant="outlined"
                                    onClick={onClickDecline}
                                    className={`edit-btn secondary-btn 
                                        ${(state.isRegistered && !approveMode? '':'hide')}
                                `}>Decline Merchant</Button>
                                <Button
                                    variant="contained"
                                    style={{ marginRight: "10px" }}
                                    className={`edit-btn ${nonHide} ${(isApproved? "hide":"")}`}
                                    onClick={onClickApprove}
                                >Open Approve Form</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickApprove}
                                    className={`add-cancel-btn ${hide} ${(approveMode? "":"hide")}`}
                                >Cancel</Button>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide} ${(approveMode? "hide":"")}`}
                                >Cancel</Button>
                                <Button
                                    variant="outlined"
                                    onClick={onClickDecline}
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn secondary-btn 
                                        ${(state.isRegistered && approveMode? '':'hide')}
                                `}>Decline Merchant</Button>
                                <Button
                                    type="submit" variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}