import React, { Component } from 'react';
import { Grid, Button, Switch } from "@material-ui/core";
import {
    Menu,
    Close,
    FindInPage,
    Delete,
} from '@material-ui/icons';
import { getSponsors, editSponsorIsShow } from '../../../logic/APIHandler';

import Sidebar from '../../../components/Sidebar_Com';
import TableV2 from '../../../components/Table_Com_V2';
import StatusModal from '../../../components/Modal_Com';
import Spinner from '../../../components/Loading_Com';

class Sponsor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            sponsorId: "00000000",
            sponsorName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
            search: localStorage.getItem('search') || '',
            totalData: 0,
            params: null,
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama Sponsor",
            width: 200,
        },
        {
            title: "Logo",
            field: "logo",
            tooltip: "Logo Sponsor",
            width: 150,
            render: rowData =>
                rowData.logo? (
                    <img src={rowData.logo} alt="img" width="100" height="100" />
                ) : ( <p className="no-image-text">No Logo</p> )
        },
        {
            title: "Ditampilkan?",
            field: "is_show",
            tooltip: "Menyala = Tampil, Mati = Tidak Tampil",
            width: 150,
            render: rowData =>
                <Switch
                    checked={rowData.is_show}
                    onChange={this.toggleIsShow.bind(this)}
                    id={rowData._id}
                />
        },
        { 
            title: "Level", 
            field: "level",
            tooltip: "Level sponsor",
            width: 150,
            render: rowData =>
                rowData.level === "Bronze" ? (
                    <h5 className="level-bar-bronze">Bronze</h5>
                ) : rowData.level === "Silver" ? (
                    <h5 className="level-bar-silver">Silver</h5>
                ) : rowData.level === "Gold" ? (
                    <h5 className="level-bar-gold">Gold</h5>
                ) : (
                    <h5 className="level-bar-platinum">Platinum</h5>
                )
        },
    ];

    actions = [
        {
            icon: () => <FindInPage />,
            tooltip: "Lihat Detail Sponsor",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/sponsor-detail/" + rowData._id,
                });
            },
        },
        {
            icon: () => <Delete />,
            tooltip: "Hapus Sponsor",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            },
        },
    ]

    // Show delete sponsor modal
    handleOpen(sponsor) {
        this.setState({
            open: true,
            sponsorId: sponsor._id,
            sponsorName: sponsor.name,
        })
    }

    // CLose delete sponsor modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Handle switch button
    async toggleIsShow(event, data) {
        await editSponsorIsShow(event.target.id, data);
        this.componentDidMount();
    }

    // Get data for table
    async getData(params) {
        this.setState({ isLoading: true });
        const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
        const payload = {
            page: params && params.page ? params.page :  page,
            sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
            sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
            ...this.state.search && { search: this.state.search },
        };

        this.setState({ params: payload });
        localStorage.setItem('sortBy', payload.sortBy);
        localStorage.setItem('sort', payload.sort);

        let sponsors = await getSponsors(payload, false);
        if ( sponsors[0] && sponsors[0].status === 200 ) {
            this.setState({
                data: sponsors[0].data.datas,
                totalData: sponsors[0].data.total,
                isLoading: false,
            });
        }
        else if ( sponsors[1] && sponsors[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount() {
        this.getData();
    };

    componentDidUpdate(_, prevState) {
        setTimeout(() => {
            if (this.state.search.length > 0 && (
              this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
            )) {
                const search = this.state.search.toLowerCase();
                localStorage.setItem('search', search);
                this.props.history.push({ pathname: '/sponsor/0' });
                this.getData({ search: '', page: 1 });
            } else if (this.state.search.length === 0 && prevState.search) {
                this.props.history.push({ pathname: '/sponsor/0' });
                this.getData({ search: '', page: 1 });
            }
        }, 700);
    };

    render() {
        return (
            <div className="content">
                { this.state.isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <StatusModal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="sponsor"
                    id={this.state.sponsorId}
                    color="primary"
                    modalTitle="Hapus Sponsor?"
                    dataName={this.state.sponsorName}
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus sponsor!"
                    errorText="Gagal menghapus sponsor!"
                />
                <StatusModal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <Menu /> : <Close />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <TableV2
                        title="Tabel Sponsor"
                        path="/sponsor"
                        columns={this.columns}
                        actions={this.actions}
                        data={this.state.data}
                        total={this.state.totalData}
                        search={this.state.search}
                        setSearch={val => this.setState({ search: val })}
                        refetchTableData={params => this.getData(params)}
                        params={this.state.params}
                    />
                </Grid>
            </div>
        );
    }
}

export default Sponsor;