import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import '../../../styles/Form_Style.css';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import { Grid, Button, Snackbar } from '@material-ui/core';
import { postSponsor } from '../../../logic/APIHandler';
import { TextInput, ImageInput, SwitchInput, DropdownInput } from '../../../logic/FormInput';
import { renderFormFields } from './SponsorHandler';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddSponsor() {

    // Form State
    const [currLevel, setCurrLevel] = useState({ label: "Bronze", value: "Bronze" });
    const [inputList, setInputList] = useState([]);
    const [images, setImages] = useState([]);
    const [waNumber, setWANumber] = useState("");
    const [wordCounter, setWordCounter] = useState(0);
    const [description, setDescription] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [state, setState] = React.useState({
        is_show: false,
        yes_no: 'Tidak',
        counter: 0,
    });

    // Page State
    const file = '';
    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Handle switch event
    const handleChange = (event) => {
        setState({
            ...state,
            yes_no: (event.target.checked? 'Ya' : 'Tidak'),
            [event.target.name]: event.target.checked
        });
    };

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    const onChangeLevel = async (selectedLevel) => {
        setCurrLevel(selectedLevel);
        setTimeout(() => {
            setDescription(selectedLevel.value === 'Silver' ? description.substring(0, 126) : description);
            if (selectedLevel.value === 'Silver') setWordCounter(description.substring(0, 126).length);
            if (selectedLevel.value === 'Bronze' && images.length > 0) {
                setInputList([]);
                setImages([]);
                setState({ ...state, counter: 0 });
            }
            else if (selectedLevel.value === 'Silver' && images.length > 2) {
                setInputList(inputList.slice(0,2));
                setImages(images.slice(0,2));
                setState({
                    ...state,
                    counter: images.slice(0,2).length,
                });
            };
        }, 0);
    };

    const onChangeHandler = (type, val) => {
        if (type === 'desc') {
            setDescription(val);
            setWordCounter(val.length);
        } 
        else if (type === 'instagram') {
            setInstagram(val);
        }
        else if (type === 'facebook') {
            setFacebook(val);
        }
        else if (type === 'twitter') {
            setTwitter(val);
        }
        else if (type === 'website') {
            setWebsite(val);
        }
        else if (type === 'tiktok') {
            setTiktok(val);
        }
    }

    // handle click event of the Remove button & remove image using API
    const handleRemoveClick = async index => {
        setIsLoading(true);
        const updatedImages = images.filter(image => image.idx !== index);
        // let resp = await deleteImageInGallery(idEvent, index);
        // if (resp[0] && resp[0].status === 200) {
        //     setOpenSuccessAlert(true);
        // }
        // else {
        //     console.log("Delete non-submited image")
        // }
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        setImages(updatedImages);
        setState({
            ...state,
            counter: state.counter - 1,
        })
        setIsLoading(false);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {gallery: ""}]);
        setState({
            ...state,
            counter: state.counter + 1,
        });
    };

    // Handle event when image is assigned
    const handleChangeGallery = event => {
        const temp = [];
        inputList.forEach(val => {
            if ( val.gallery !== "" ) {
                temp.push({gallery: val.gallery});
            }
        });
        temp.push({gallery: URL.createObjectURL(event.target.files[0])})
        setInputList([ ...temp ]);
        setImages([
            ...images,
            { gallery: event.target.files[0], idx: (inputList.length - 1) }
        ]);
    }

    // Send new sponsor data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            if ( !data.level ) data.level = { label: "Bronze", value: "Bronze" };
            let resp = await postSponsor(data, images);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/sponsor/0");
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Membuat Sponsor
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Sponsor Baru</h1>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*nama harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ImageInput
                                        name="logo"
                                        label="Logo *"
                                        ratioLabel="Ratio 1:1"
                                        validation={register}
                                        errors={errors.logo}
                                        isEdit={false}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={false}
                                        error_label="* foto logo"
                                        overallClassName="full-input-image add-margin-top"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SwitchInput
                                        legend="Tampilkan Sponsor?"
                                        label={state.yes_no}
                                        name="is_show"
                                        className="switch-layout add-margin-top add-margin-bottom"
                                        value={state.is_show}
                                        checked={state.is_show}
                                        onChange={handleChange}
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <DropdownInput
                                        label="Level"
                                        name="level"
                                        className="full-width add-margin-bottom tf-label"
                                        keepData={(selectedLevel) => onChangeLevel(selectedLevel)}
                                        disableClearable={true}
                                        control={control}
                                        options={[
                                            { label: "Bronze", value: "Bronze" },
                                            { label: "Silver", value: "Silver" },
                                            { label: "Gold", value: "Gold" },
                                        ]}
                                        getOptionLabel={(option) => option.label}
                                        value={currLevel}
                                        required={true}
                                    />
                                </Grid>
                                {
                                    renderFormFields(
                                        currLevel.value,
                                        register,
                                        errors,
                                        waNumber,
                                        setWANumber,
                                        description,
                                        wordCounter,
                                        instagram,
                                        facebook,
                                        twitter,
                                        website,
                                        tiktok,
                                        onChangeHandler,
                                        false,
                                    )
                                }
                                { currLevel.value !== "Bronze" ?
                                    <>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h2 style={{ marginTop: 0 }}>Galeri</h2>
                                                <p className="input-img-terms">
                                                    * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                                </p>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ padding: "30px 30px 0" }}
                                            className="gallery-section add-margin-bottom"
                                        >
                                            {inputList.map((value, index) => {
                                                return (
                                                    <Grid item xs={12} sm={6} md={4} key={`sponsor-${index}`}
                                                        style={{ marginBottom: "25px" }}>
                                                        <ImageInput
                                                            name={`gallery${index}`}
                                                            label={`Foto ${index+1}`}
                                                            validation={register}
                                                            isEdit={true}
                                                            control={control}
                                                            defaultImg={value.gallery}
                                                            disabled={true}
                                                            hideDeleteBtn="hide"
                                                        />
                                                        <Button
                                                            color="primary" variant="contained"
                                                            className={`delete-btn-gallery`}
                                                            onClick={() => handleRemoveClick(index)}>Remove</Button>
                                                    </Grid>
                                                );
                                            })}
                                            <Grid item xs={12} md={4}>
                                                <label>
                                                    <input
                                                        style={{ display: "none" }}
                                                        value={file}
                                                        type="file"
                                                        accept="image/*"
                                                        onInput={handleChangeGallery}
                                                    />
                                                    <span
                                                        onClick={handleAddClick}
                                                        className={`add-new-gallery
                                                            ${
                                                                (currLevel.label === 'Gold' && state.counter < 5) || (currLevel.label === 'Silver' && state.counter < 2) ?
                                                                    "" : "hide"
                                                            }
                                                        `}
                                                    >+</span>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </>
                                : <></>
                                }
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}