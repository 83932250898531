export const styles = ({
    isMobile = false,
    isTablet = false,
    width = null,
    display = 'block',
    disabled = false,
}) => {
    const tableHeader = {
        alignItems: 'center',
        backgroundColor: '#CE9963BB',
    };
    
    const title = {
        padding: '0 15px',
        textAlign: isMobile ? 'center' : 'left',
    };

    const search = {
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'flex-end',
        padding: '0 15px',
        marginBottom: isMobile ? '1em' : 0,
    };

    const tableContent = {
        width: '100%',
        textAlign: 'left',
        backgroundColor: 'white',
    };

    const headerCell = {
        gap: 3,
        width: width || 'auto',
    };

    const actionCell = {
        borderBottom: '1px solid lightgray',
        padding: '15px 5px',
    };

    const borderCell = {
        borderBottom: '1px solid lightgray',
        padding: 15,
    };

    const footer = {
        alignItems: 'center',
        backgroundColor: '#CE9963BB',
    };

    const footerBtnSection = {
        padding: (isMobile || isTablet) ? '15px 15px 5px' : '10px 15px',
        display: 'flex',
        flexDirection: (isMobile || isTablet) ? 'column' : 'row',
        gap: 10,
    };

    const addNavLink = {
        textDecoration: 'none',
        display,
        width: (isMobile || isTablet) ? '100%' : 'auto',
        cursor: disabled ? 'default' : 'pointer',
    };

    const addButton = {
        width: (isMobile || isTablet) ? '100%' : 'auto',
        backgroundColor: 'white',
    };

    const iconButton = {
        height: 35,
        padding: 5,
    };

    const emptyTable = {
        height: '60vh',
        textAlign: 'center',
    };

    return {
        tableHeader,
        title,
        search,
        tableContent,
        headerCell,
        actionCell,
        borderCell,
        footer,
        footerBtnSection,
        addNavLink,
        addButton,
        iconButton,
        emptyTable,
    };
};
