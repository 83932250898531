import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../../styles/Form_Style.css';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
import { getPartner, editPartner } from '../../../logic/APIHandler';
import { TextInput, ImageInput, SwitchInput } from '../../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailPartner(props) {

    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Partner');
    const [pageTitle, setPageTitle] = useState('Detail Partner');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idPartner, setIDPartner] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        logo: "",
        is_show: false,
        yes_no: 'Tidak',
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idPartner = props.match.params.id;
        setIDPartner(idPartner);
        if ( idPartner ) {
            getData(idPartner);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get partner's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getPartner(id);
        if (resp[0] && resp[0].status === 200) {
            const partner = resp[0].data;
            setValue("name", partner.name);
            setState({
                ...state,
                logo: (partner.logo !== "undefined")? partner.logo : "",
                is_show: partner.is_show,
                yes_no: (partner.is_show? 'Ya':'Tidak'),
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Handle switch event
    const handleChange = (event) => {
        setState({
            ...state,
            yes_no: (event.target.checked? 'Ya' : 'Tidak'),
            [event.target.name]: event.target.checked
        });
    };

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Partner" ) {
            setEdit("Cancel");
            setPageTitle("Edit Partner")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data partner to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = await editPartner(idPartner, data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Partner
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        {/* Input for name */}
                        <TextInput
                            label="Nama"
                            name="name"
                            placeholder="Nama"
                            className="full-width add-margin-bottom"
                            validation={register({ required: "*nama harus diisi" })}
                            errors={errors.name}
                            errorClassName="text-required"
                            disabled={isDisabled}
                        />

                        {/* Input for is_show */}
                        <SwitchInput
                            legend="Tampilkan Partner?"
                            label={state.yes_no}
                            name="is_show"
                            className={`switch-layout add-margin-bottom ${isDisabled? "disabled-border":""}`}
                            value={state.is_show}
                            checked={state.is_show}
                            onChange={handleChange}
                            validation={register}
                            disabled={isDisabled}
                        />

                        {/* Input for image */}
                        <ImageInput
                            name="logo"
                            label="Logo"
                            ratioLabel="Ratio 1:1"
                            validation={register}
                            errors={errors.logo}
                            isEdit={true}
                            control={control}
                            defaultImg={state.logo}
                            canDelete={state.logo? false : true}
                            disabled={isDisabled}
                            overallClassName="full-input-image"
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}