import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Button, Snackbar } from '@material-ui/core';
import '../../styles/Form_Style.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { getMembers, postCandidateChairman } from '../../logic/APIHandler';
import { TextInput, GroupedDropdown, ImageInput } from '../../logic/FormInput';
import { filterData, filterDataTwoConditions } from '../../logic/Handler';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddPengurus() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        data: [],
    });

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Method that run first when the page is opened 
    useEffect(() => {
        getData();
    }, []);

    // Get members who can be candidate chairman
    const getData = async data => {
        setIsLoading(true);
        let members = await getMembers();
        if (members[0] && members[0].status === 200) {
            let filterByStatus = await filterData(members[0].data, "status", "active");
            let filterByMembership = await filterDataTwoConditions(filterByStatus, "membership", "honorary", "platinum");
            let res = [];
            filterByMembership.forEach(member => {
                res.push({
                    member_card_id: member.member_card_id,
                    label: member.name,
                    value: member._id,
                });
            });
            setState({
                data: res,
            });
        }
        else if ( members[1] && members[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log("Can't load members or still loading.")
        }
        setIsLoading(false);
    }

    // Send new candidate chairman data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = await postCandidateChairman(data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/vote/0");
            }
            else {
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <StatusModal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuat Calon Pengurus
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            Gagal Membuat Calon Pengurus
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Calon Pengurus</h1>

                            <Grid container>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    {/* Input for member */}
                                    <GroupedDropdown
                                        label="Pilih Member *"
                                        name="member_id"
                                        className="full-width add-margin-bottom grouped-field"
                                        rules={{ required: "*salah satu member harus dipilih" }}
                                        control={control}
                                        options={state.data}
                                        getOptionLabel={(option) => 
                                            option.member_card_id + " - " + option.label}
                                        isClearable={true}
                                        defaultValue=""
                                        errors={errors.member_id}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for visi misi */}
                                    <TextInput
                                        label="Visi Misi"
                                        name="visi_misi"
                                        placeholder="Visi Misi"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        // validation={register({ required: "*visi misi harus diisi" })}
                                        // errors={errors.visi_misi}
                                        // errorClassName="text-required"
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for company name */}
                                    <TextInput
                                        label="Name Perusahaan"
                                        name="company_name"
                                        placeholder="Name Perusahaan"
                                        className="form-left-field add-margin-bottom"
                                        validation={register}
                                        errors={errors.company_name}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for business field */}
                                    <TextInput
                                        label="Bidang Usaha"
                                        name="business_fields"
                                        placeholder="Bidang Usaha"
                                        className="form-right-field add-margin-bottom"
                                        validation={register}
                                        errors={errors.business_fields}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for last position */}
                                    <TextInput
                                        label="Jabatan Terakhir"
                                        name="last_position"
                                        placeholder="Jabatan Terakhir"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        errors={errors.last_position}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for work program */}
                                    <TextInput
                                        label="Program Kerja"
                                        name="work_program"
                                        placeholder="Program Kerja"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        // validation={register({ required: "*program kerja harus diisi" })}
                                        // errors={errors.work_program}
                                        // errorClassName="text-required"
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for message */}
                                    <TextInput
                                        label="Pesan"
                                        name="message"
                                        placeholder="Pesan"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        errors={errors.message}
                                        errorClassName="text-required"
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for candidate chairman image */}
                                    <ImageInput
                                        name="photo"
                                        label="Foto Calon Pengurus *"
                                        ratioLabel="Ratio 1:1"
                                        validation={register}
                                        errors={errors.photo}
                                        isEdit={false}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={false}
                                        error_label="* foto calon pengurus"
                                        overallClassName="full-input-image"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}