import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Button, FormControlLabel, Checkbox, Snackbar } from "@material-ui/core";
import '../../../styles/Form_Style.css';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
import { filterDataTwoConditions } from '../../../logic/Handler';
import { getExecutiveBoard, editExecutiveBoard, getMembers } from '../../../logic/APIHandler';
import { TextInput, ImageInput, GroupedDropdown, ChoiceboxInput } from '../../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailPembina(props) {

    const history = useHistory();
    const { register, handleSubmit, control, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Pembina');
    const [pageTitle, setPageTitle] = useState('Detail Pembina');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [idExecutive, setIDExecutive] = useState(null);
    const [defaultIsMember, setDefaultIsMember] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        image: "",
        member_card_id: "-",
        member_name: "",
        isMember: false,
        data: [],
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idExecutive = props.match.params.id;
        setIDExecutive(idExecutive);
        if ( idExecutive ) {
            getData(idExecutive);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get pembina's data from API
    const getData = async id => {
        setIsLoading(true);
        let members = await getMembers();
        let filterByStatus = '';
        let filterByMembership = '';
        let res = [];
        if (members[0] && members[0].status === 200) {
            filterByStatus = await filterDataTwoConditions(members[0].data, "status", "active", "inactive");
            filterByMembership = await filterDataTwoConditions(filterByStatus, "membership", "honorary", "platinum");
            filterByMembership.forEach(member => {
                res.push({
                    label: member.name,
                    value: member._id,
                });
            });
        }
        else if ( members[1] && members[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(members);
            if ( members[1].data && members[1].data[0].msg ) {
                setErrorText(members[1].data[0].msg);
            }
            else {
                setErrorText(members[1].message);
            }
            setOpenErrorAlert(true);
        }

        let resp = await getExecutiveBoard(id);
        if (resp[0] && resp[0].status === 200) {
            const executive = resp[0].data;
            setValue("name", executive.name);
            setValue("title", executive.title);
            if ( executive.member_id ) setDefaultIsMember(true);
            setState({
                ...state,
                image: (executive.image_executiveboard !== "undefined")? executive.image_executiveboard : "",
                isMember: (executive.member_id === null)? false : true,
                member_card_id: (!executive.member_id)? "-" : executive.member_id.member_card_id,
                member_name: (executive.member_id)? executive.member_id.name : executive.name,
                data: res,
            });
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Pembina" ) {
            setEdit("Cancel");
            setPageTitle("Edit Pembina")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
            setEditMode(true);
        }
        else {
            history.go(0);
        }
    }

    // Handle checkbox event
    const onCheckboxClick = () => {
        setState({
            ...state,
            isMember: !state.isMember,
        })
    }

    // Send updated data pembina to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            if ( !state.isMember ) {
                data.member_id = null;
            }
            let resp = await editExecutiveBoard(idExecutive, data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                console.log(resp);
                if ( resp[1].data && resp[1].data[0].msg ) {
                    setErrorText(resp[1].data[0].msg);
                }
                else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <StatusModal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Pembina
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                        </Grid>

                        <Grid container className="member-info">
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={12} style={{ marginBottom: "15px" }}>
                                        <h2
                                            className={`center-responsive ${(editMode === false)? "add-margin-bottom" : ""}`}
                                            style={{ margin: "0" }}>Data Member</h2>
                                    </Grid>
                                    <Grid item xs={12} className={(editMode === false)? "hide" : ""}>
                                        <ChoiceboxInput
                                            options={
                                                <FormControlLabel
                                                    value="Bukan"
                                                    control={<Checkbox checked={state.isMember} />}
                                                    label="Ubah pembina menjadi member Idepreneurs?"
                                                    onClick={onCheckboxClick}
                                                    className={(state.member_card_id === "-")? "" : "hide"}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={(state.member_card_id === "-")? "hide" : ""}>
                                        <p className="member-id-text">ID Member</p>
                                    </Grid>
                                    <Grid item xs={9} className={(state.member_card_id === "-")? "hide" : ""}>
                                        <p className="member-id-text">: {state.member_card_id}</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ position: "relative" }}>
                                        {/* Input for member */}
                                        <GroupedDropdown
                                            label="Pilih Member"
                                            labelPosition={`
                                                ${state.isMember? "" : "hide-label"}
                                                ${defaultIsMember? "hide-label" : ""}
                                            `}
                                            name="member_id"
                                            className={`
                                                full-width grouped-field add-margin-bottom 
                                                ${(state.isMember? '':'hide')} 
                                                ${(defaultIsMember? 'hide' : '')} 
                                                ${(editMode? '' : 'hide')}
                                            `}
                                            control={control}
                                            options={state.data}
                                            isClearable={true}
                                            defaultValue=""
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={(state.member_card_id === "-")? 'hide' : ''}>
                                        <p className="member-id-text">Nama</p>
                                    </Grid>
                                    <Grid item xs={9} className={(state.member_card_id === "-")? 'hide' : ''}>
                                        <p className="member-id-text">: {state.member_name}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Nama"
                                            name="name"
                                            placeholder="Nama"
                                            // value={state.member_name}
                                            className={`
                                                full-width add-margin-bottom 
                                                ${(state.isMember && !defaultIsMember? 'hide':'')}
                                                ${(defaultIsMember? 'hide':'')} 
                                                ${(editMode || !state.isMember? '' : 'hide')}
                                            `}
                                            validation={register}
                                            disabled={isDisabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ margin: "15px 0" }}>
                                {/* Input for image */}
                                <ImageInput
                                    name="image_executiveboard"
                                    label="Foto Pembina"
                                    ratioLabel="Ratio 3:4"
                                    isEdit={true}
                                    validation={register}
                                    defaultImg={state.image}
                                    canDelete={false}
                                    disabled={isDisabled}
                                    overallClassName="half-input-image a4-size"
                                />
                            </Grid>
                        </Grid>

                        {/* Input for title */}
                        <TextInput
                            label="Jabatan"
                            name="title"
                            placeholder="Jabatan"
                            className="full-width add-margin-bottom"
                            validation={register}
                            disabled={isDisabled}
                        />

                        {/* Input for category */}
                        <TextInput
                                label="Kategori"
                                name="category"
                                placeholder="Kategori"
                                defaultValue="dewan"
                                className="hide"
                                validation={register}
                        />

                        <Grid container>
                            <Grid item xs={12}>
                                <Button variant="contained" className={`edit-btn ${nonHide}`} onClick={onClickEdit}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}