import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Sidebar from '../../../components/Sidebar_Com';
import Spinner from '../../../components/Loading_Com';
import StatusModal from '../../../components/Modal_Com';
import { postJabatan } from '../../../logic/APIHandler';
import { TextInput, NumberInput } from '../../../logic/FormInput';
import '../../../styles/Form_Style.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddJabatan() {

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [rank, setRank] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postJabatan(data);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/jabatan/0");
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <StatusModal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                        <Alert severity="success">
                            Berhasil Membuat Jabatan Baru
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Jabatan Baru</h1>

                            <Grid container>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Jabatan"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*nama jabatan harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInput
                                        label="Ranking *"
                                        name="score"
                                        placeholder="0"
                                        min={1}
                                        value={rank}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setRank(parseFloat(value) <= 0 ? '1' : setRank(value));
                                        }}
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*ranking jabatan harus diisi" })}
                                        errors={errors.score}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}