import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, NavLink } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { Button, Snackbar, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { GroupedDropdown, DropdownInput, NumberInput, ChoiceboxInput } from '../logic/FormInput';
import { 
    updateStatusMember, editMember, deleteMember, approveMember, deleteEventCategory,
    deleteEvent, deletePartner, deleteExecutiveBoard, deleteSponsor, deleteMerchant,
    deleteNews, renewMember, deleteCandidateChairman, updateVotingStatus, deleteLocation,
    deleteJabatan, deleteVoters, resetVoteStatus, deleteAdmin, getLatestRegisteredMember,
    handleUpdatedPP, updateAttributeDelivery,
} from '../logic/APIHandler';
import '../styles/Form_Style.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function StatusModal(props) {
    const { isTitleCenter, votePeriod } = props;

    // Form State
    const [currCateg, setCurrCateg] = useState(null);

    // Component State
    const history = useHistory();
    const { register, handleSubmit, control, errors } = useForm();
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("Gagal memuat data!");
    const [isLoading, setIsLoading] = useState(false);

    const [deliveredCard, setDeliveredCard] = useState(false);
    const [deliveredPolo, setDeliveredPolo] = useState(false);
    const [deliveredJacket, setDeliveredJacket] = useState(false);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Submit new status to selected member
    const onSubmit = async data => {
        setIsLoading(true);
        let resp = "";
        if ( props.modalType === 'member' ) {
            resp = await updateStatusMember(props.id, data);
            if ( resp[0] === undefined ) {
                setErrorText(resp[1].message);
            }
        }
        else if ( props.modalType === 'update-member' ) {
            resp = await editMember(props.id, props.detailedData, data.pin);
            if ( resp[0] === undefined ) {
                setErrorText(resp[1].message);
            }
        }
        else if ( props.modalType === 'event-category' ) {
            resp = await deleteEventCategory(props.id);
        }
        else if ( props.modalType === 'partner' ) {
            resp = await deletePartner(props.id);
        }
        else if ( props.modalType === 'pengurus' || props.modalType === 'pembina' ) {
            resp = await deleteExecutiveBoard(props.id);
        }
        else if ( props.modalType === 'past-event' || props.modalType === 'upcoming-event' ) {
            resp = await deleteEvent(props.id);
        }
        else if ( props.modalType === 'sponsor' ) {
            resp = await deleteSponsor(props.id);
        }
        else if ( props.modalType === 'merchant' ) {
            resp = await deleteMerchant(props.id);
        }
        else if ( props.modalType === 'approve-member' ) {
            if ( currCateg.category === "Pusat" || currCateg.category === "Luar Wilayah" ) {
                data["chapter"] = currCateg._id;
            }
            else {
                data["chapter"] = data.location._id;
            }
            resp = await approveMember(props.id, data);
        }
        else if ( props.modalType === 'decline-member' ) {
            resp = await deleteMember(props.id);
        }
        else if ( props.modalType === 'renew-member' ) {
            resp = await renewMember(props.id, data);
        }
        else if ( props.modalType === 'decline-merchant' ) {
            resp = await deleteMerchant(props.id);
        }
        else if ( props.modalType === 'approve-pp' ) {
            resp = await handleUpdatedPP(props.id, true);
        }
        else if ( props.modalType === 'decline-pp' ) {
            resp = await handleUpdatedPP(props.id, false);
        }
        else if ( props.modalType === 'update-delivery-status' ) {
            resp = await updateAttributeDelivery(props.id, { ...data, membership: { value: props.data.membership } });
        }
        else if ( props.modalType === 'is-report-filled' ) {
            props.goToForm();
        }
        else if ( props.modalType === 'news' ) {
            resp = await deleteNews(props.id);
        }
        else if ( props.modalType === 'clear-boards' ) {
            let executives = props.listOfID;
            executives.forEach(async executive => {
                resp = await deleteExecutiveBoard(executive._id);
            });
            await sleep(5000);
            history.go(0);
        }
        else if ( props.modalType === 'clear-voters' ) {
            resp = await deleteVoters();
        }
        else if ( props.modalType === 'delete-candidate' ) {
            resp = await deleteCandidateChairman(props.id);
        }
        else if ( props.modalType === 'delete-admin' ) {
            resp = await deleteAdmin(props.id);
        }
        else if ( props.modalType === 'stop-voting' ) {
            resp = await updateVotingStatus('stop-voting', votePeriod);
        }
        else if ( props.modalType === 'start-voting' ) {
            resp = await updateVotingStatus('start-voting', votePeriod);
        }
        else if ( props.modalType === 'reopen-vote' ) {
            resp = await updateVotingStatus('reopen-voting', votePeriod);
        }
        else if ( props.modalType === 'clear-candidates' ) {
            resp = await updateVotingStatus('clear-voting', votePeriod);
        }
        else if ( props.modalType === 'reset-is-vote' ) {
            resp = await resetVoteStatus(props.id);
        }
        else if ( props.modalType === 'location' ) {
            resp = await deleteLocation(props.id);
        }
        else if ( props.modalType === 'jabatan' ) {
            resp = await deleteJabatan(props.id);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            if (props.modalType === 'clear-voters') {
                props.closeModal();
            } else if (props.modalType === 'approve-member' || props.modalType === 'decline-member') {
                if (props.isGoToNext) {
                    const respNext = await getLatestRegisteredMember();
                    if (respNext[0] && respNext[0].status === 200) {
                        props.onClose();
                        history.push("/member-detail/" + respNext[0].data.detail._id + "?status=registered");
                    } else if (respNext[0] && respNext[0].status === 204) {
                        history.push("/member/0");
                    } else {
                        console.log(respNext)
                        if (respNext[1].data && respNext[1].data[0].msg) {
                            setErrorText(respNext[1].data[0].msg);
                        } else {
                            setErrorText(respNext[1].message);
                        }
                        setOpenErrorAlert(true);
                        history.push("/dashboard/0");
                    }
                }
                else {
                    if ( history.location.pathname.includes("dashboard") ) {
                        setIsLoading(false);
                        props.refreshData();
                    } else {
                        history.push("/member/0");
                    }
                }
            } else {
                history.go(0);
            }
        } else {
            if (props.modalType !== 'is-report-filled') {
                console.log(resp);
                if (resp[1] && resp[1].data && resp[1].data[0].msg) {
                    setErrorText(resp[1].data[0].msg);
                } else if (props.modalType === 'member') {
                    props.showModal(resp[1].message);
                    setIsLoading(false);
                    return;
                } else if (resp[0] && resp[0].status === 204) {
                    setErrorText(resp[0].message);
                } else {
                    setErrorText(resp[1].message);
                }
                setOpenErrorAlert(true);
            }
            setIsLoading(false);
        }
    }

    // HTML for Modal
    const modalBody = (
        <div className="modal-body">
            <h2
                id="simple-modal-title"
                className={props.modalType === "handle-jwt" || props.modalType === "suspend-pic" ? "jwt-title" : ""}
                style={{
                    marginBottom: (props.modalType.includes('member') || props.modalType === 'clear-boards' || props.modalType === 'stop-voting' || props.modalType === 'start-voting')
                        ? "2rem" : "0",
                    textAlign: isTitleCenter ? 'center' : 'left',
                }}
            >
                {props.modalTitle}
            </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
                {(props.modalType === 'member')?
                    <>
                        <GroupedDropdown
                            label="Status Member"
                            labelPosition="half-right-label"
                            name="status"
                            className="full-width grouped-field add-margin-bottom"
                            control={control}
                            options={[
                                { label: "Active", value: "active" },
                                { label: "Suspended", value: "suspend" },
                            ]}
                            isClearable={false}
                            defaultValue={{ label: "Active", value: "active" }}
                        />
                        { localStorage.getItem("admin") === "master" ? <></> :
                            <>
                                <TextField
                                    type="password"
                                    autoComplete='new-password'
                                    variant="outlined"
                                    label="Pin *"
                                    name="pin"
                                    placeholder="Masukkan Pin"
                                    helperText="Minta pin ke penanggung jawab"
                                    className="full-width add-margin-bottom pin-field"
                                    inputRef={register({ required: "*pin harus diisi" })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                { errors.pin && 
                                    <p className="text-required">{errors.pin.message}</p>
                                }
                            </>
                        }
                    </>
                : (props.modalType.includes('event') || props.modalType === 'partner'
                    || props.modalType === 'pembina' || props.modalType === 'pengurus'
                    || props.modalType === 'sponsor' || props.modalType === 'merchant'
                    || props.modalType === 'news' || props.modalType === 'location'
                    || props.modalType === 'delete-candidate' || props.modalType.includes('decline')
                    || props.modalType === 'reset-is-vote' || props.modalType === 'delete-admin'
                    || props.modalType === 'jabatan' || props.modalType === 'approve-pp'
                  ) ?
                    <p style={{ marginBottom: "30px" }}>Nama: {props.dataName}</p>
                : ( props.modalType === 'renew-member' ) ?
                    <>
                        <p>Nama: {props.dataName}</p>
                        <span className="subscription-year-layout">Lama Berlangganan: 
                            <span>
                                <NumberInput
                                    // label="Tahun Berlangganan"
                                    name="subscription_year"
                                    defaultValue="1"
                                    className="subscription-year-input"
                                    validation={register({ required: "*lama berlangganan harus diisi" })}
                                    errors={errors.subscription_year}
                                    errorClassName="text-required"
                                />
                            </span>
                            <span>tahun</span>
                        </span>
                    </>
                : (props.modalType === 'approve-member') ?
                    <>
                        <p className="add-margin-bottom">Nama: {props.dataName}</p>
                        <div style={{ position: "relative" }}>
                            <DropdownInput
                                label="Kategori Wilayah"
                                name="categories"
                                className="full-width add-margin-bottom tf-label"
                                keepData={(data) => setCurrCateg(data)}
                                disableClearable={true}
                                control={control}
                                options={props.dataCategories}
                                getOptionLabel={(option) => option.category}
                                value={currCateg}
                                required={true}
                            />
                        </div>
                        { currCateg && currCateg.category !== "Pusat" && currCateg.category !== "Luar Wilayah" ?
                            <div style={{ position: "relative" }}>
                                <GroupedDropdown
                                    label="Nama Wilayah *"
                                    name="location"
                                    className="full-width add-margin-bottom grouped-field"
                                    rules={{ required: "*nama wilayah harus dipilih" }}
                                    control={control}
                                    options={props.dataLocNames.filter(res => res.category === currCateg.category)}
                                    getOptionLabel={option => option.name}
                                    isClearable={false}
                                    defaultValue=""
                                    errors={errors.location}
                                    errorClassName="text-required"
                                />
                            </div> : <></>
                        }
                    </>
                : (props.modalType === 'update-member') ?
                    <>
                        <TextField
                            type="password"
                            variant="outlined"
                            autoComplete='new-password'
                            label="Pin *"
                            name="pin"
                            placeholder="Masukkan Pin"
                            helperText="Minta pin ke penanggung jawab"
                            className="full-width add-margin-bottom pin-field"
                            inputRef={register({ required: "*pin harus diisi" })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                        {errors.pin && <p className="text-required">{errors.pin.message}</p>}
                    </>
                : ( props.modalType === 'update-delivery-status' ) ?
                    <>
                        <p style={{ marginBottom: '10px' }}>Nama: {props.dataName}</p>
                        <ChoiceboxInput
                            style={{ width: '60%', marginBottom: 0 }}
                            options={
                                <div>
                                    <FormControlLabel
                                        value={deliveredCard}
                                        control={<Checkbox/>}
                                        label="Kartu eMoney"
                                        name="eMoney"
                                        className="label-on-start"
                                        labelPlacement="start"
                                        inputRef={register}
                                        onClick={() => setDeliveredCard(!!!deliveredCard)}
                                        checked={(props.data && props.data.eMoney) || deliveredCard }
                                        disabled={props.data && props.data.eMoney}
                                    />
                                    <FormControlLabel
                                        value={deliveredPolo}
                                        control={<Checkbox/>}
                                        label="Polo"
                                        name="polo"
                                        className="label-on-start"
                                        labelPlacement="start"
                                        inputRef={register}
                                        onClick={() => setDeliveredPolo(!!!deliveredPolo)}
                                        checked={(props.data && props.data.polo) || deliveredPolo }
                                        disabled={props.data && props.data.polo}
                                    />
                                    {props.data && props.data.membership !== 'silver' && (
                                        <FormControlLabel
                                            value={deliveredJacket}
                                            control={<Checkbox/>}
                                            label="Jaket"
                                            name="jaket"
                                            className="label-on-start"
                                            labelPlacement="start"
                                            inputRef={register}
                                            onClick={() => setDeliveredJacket(!!!deliveredJacket)}
                                            checked={props.data.jaket || deliveredJacket }
                                            disabled={props.data.jaket}
                                        />
                                    )}
                                </div>
                            }
                        />
                    </>
                : (props.modalType === 'is-report-filled')?
                    <p style={{ marginBottom: 30 }}>Isi laporan acara sekarang?</p>
                : (props.modalType === 'admin-info')?
                <>
                    <p>Email: {props.emailCallback}</p>
                    <p>Password: {props.passwordCallback}</p>
                </>
                : (props.modalType === 'clear-voters' || props.modalType === 'reopen-vote' || props.modalType === 'clear-candidates')?
                    <p style={{ marginBottom: "2rem" }}></p>
                : "" }
                {props.modalType === "handle-jwt"?
                    <>
                        <NavLink to="/" style={{ textDecoration: "none" }}>
                            <Button onClick={() => localStorage.clear()} variant="contained"
                                className="jwt-btn">Login Kembali</Button>
                        </NavLink>
                    </>
                    : props.modalType === "suspend-pic"?
                        <>
                            <span style={{ whiteSpace: 'pre-line' }}>{props.errorText}</span>
                            <Button
                                variant="contained"
                                onClick={props.onClickCancel}
                                className="jwt-btn"
                            >
                                Kembali
                            </Button>
                        </>
                    : props.modalType === "admin-info"?
                        <Button
                            onClick={() => history.push("/admin/0")}
                            variant="contained" className="jwt-btn"
                        >OK</Button>    
                    : <>
                        <Button onClick={props.onClickCancel} variant="contained" className="modal-cancel-btn">{props.cancelText || 'Cancel'}</Button>
                        <Button type="submit" variant="contained" color={props.color || 'primary'} className="modal-submit-btn">
                            {isLoading ? <CircularProgress color="inherit" id="btn-spinner" /> : (props.submitText || 'Submit')}
                            {/* {<CircularProgress color="inherit" id="btn-spinner" />} */}
                        </Button>
                    </>
                }
          </form>
        </div>
    );

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                className="custom-modal"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalBody}
            </Modal>
            <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                <Alert severity="success">
                    {props.successText}
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorAlert} autoHideDuration={3500} onClose={handleCloseAlert}>
                <Alert severity="error">
                    {props.errorText || errorText}
                </Alert>
            </Snackbar>
        </>
    );
}