import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Style.css';
import city from '../../assets/data/Cities';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import StatusModal from '../../components/Modal_Com';
import { getMember, editMember, getChapters, getLocations, getLatestRegisteredMember, updateAttributeDelivery } from '../../logic/APIHandler';
import { Button, FormControlLabel, Grid, Radio, Checkbox, Snackbar } from '@material-ui/core';
import { TextInput, EmailInput, PhoneInput, DateInput, GroupedDropdown, DropdownInput,
    ImageInput, RadioButtonInput, ChoiceboxInput, } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailMember(props) {

    // Form State
    const [totalRegistered, setTotalRegistered] = useState('-');
    const [gender, setGender] = useState('male');
    const [locationNames, setLocationNames] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currCateg, setCurrCateg] = useState(null);
    const [chapterData, setChapterData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [lastData, setLastData] = useState("");
    const [memberCardId, setMemberCardId] = useState("");
    const [firstId, setFirstId] = useState("");
    const [lastId, setLastId] = useState("");
    const [state, setState] = useState({
        selectedDate: moment(),
        nationalId: "",
        photo: "",
        pop: "",
        isRegistered: false,
        memberName: '',
    });

    // Choicebox State
    const [network, setNetwork] = useState(false);
    const [consumer, setConsumer] = useState(false);
    const [scale, setScale] = useState(false);
    const [supplier, setSupplier] = useState(false);
    const [partner, setPartner] = useState(false);
    const [deliveredCard, setDeliveredCard] = useState(false);
    const [deliveredPolo, setDeliveredPolo] = useState(false);
    const [deliveredJacket, setDeliveredJacket] = useState(false);
    const [isCardDisabled, setIsCardDisabled] = useState(false);
    const [isPoloDisabled, setIsPoloDisabled] = useState(false);
    const [isJacketDisabled, setIsJacketDisabled] = useState(false);

    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [idMember, setIDMember] = useState(null);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [openJWT, setOpenJWT] = useState(false);
    const [openRenew, setOpenRenew] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);
    const [openPinModal, setOpenPinModal] = useState(false);
    const [edit, setEdit] = useState('Edit Member');
    const [pageTitle, setPageTitle] = useState('Detail Member');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isNext, setIsNext] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isExpired, setIsExpired] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const [isMemberNotFound, setIsMemberNotFound] = useState(false);
    const windowWidth = window.innerWidth;

    // Method that run first when the page is opened 
    useEffect(() => {
        window.scrollTo(0, 0);
        getChapter();
        let res = [];
        city.forEach(val => {
            res.push({
                value: val, label: val,
            });
        });
        setCityData(res);

        const idMember = props.match.params.id;
        setIDMember(idMember);
        if ( idMember ) {
            getData(idMember);
            getAllLocations();
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get all locations
    const getAllLocations = async() => {
        setIsLoading(true);
        let resp = await getLocations();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data;
            let categs = [], names = [];
            result.forEach(res => {
                if ( categs.filter(temp => temp.category === res.category).length < 1 ) {
                    categs.push(res);
                }
                if ( res.category !== "Pusat" && res.category !== "Luar Wilayah" ) {
                    names.push(res);
                }
            });
            setCategories(categs);
            setLocationNames(names);
            setIsLoading(false);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText(resp[0].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Get all chapters
    const getChapter = async() => {
        setIsLoading(true);
        let resp = await getChapters();
        if ( resp[0] && resp[0].status === 200 ) {
            let chapters = resp[0].data;
            let res = [];
            chapters.forEach(chapter => {
                res.push({
                    value: chapter.name, label: chapter.name,
                });
            });
            setChapterData(res);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Get member's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = [];
        if (props.location.state && props.location.state.lastId) {
            setFirstId(props.location.state.firstId);
            setLastId(props.location.state.lastId);
        }
        if ( !history.location.search ) {
            resp = await getMember(id, id.substr(0, 6) !== '201612');
        }
        else {
            resp = await getLatestRegisteredMember();
        }
        if (resp[0] && resp[0].status === 200) {
            setIsMemberNotFound(false);
            const member = resp[0].data.detail || resp[0].data;
            if (resp[0].data.detail) setTotalRegistered(resp[0].data.total_registered);

            setMemberCardId(member.member_card_id);
            if ( member.join_reason ) {
                let join_reason = member.join_reason.split(",");
                for (let i=0; i<join_reason.length; i++) {
                    if (join_reason[i].includes("Networking")) setNetwork(true);
                    else if (join_reason[i].includes("Konsumen")) setConsumer(true);
                    else if (join_reason[i].includes("Mengembangkan")) setScale(true);
                    else if (join_reason[i].includes("Suplier")) setSupplier(true);
                    else if (join_reason[i].includes("Rekan")) setPartner(true);
                }
            }
            setValue("name", member.name);
            setValue("membership", {
                label: member.membership.charAt(0).toUpperCase() + member.membership.slice(1),
                value: member.membership
            });
            setValue("place_of_birth", {
                label: member.place_of_birth.charAt(0).toUpperCase() + member.place_of_birth.slice(1),
                value: member.place_of_birth
            });
            setValue("city", {
                label: member.city.charAt(0).toUpperCase() + member.city.slice(1),
                value: member.city
            });
            if ( member.reference === 'member' ) {
                setValue("reference", { label: "Anggota", value: member.reference });
            }
            else if ( member.reference === 'social-media' ) {
                setValue("reference", { label: "Media Sosial", value: member.reference });
            }
            else {
                setValue("reference", { label: "Lainnya", value: member.reference });
            }
            if ( member.chapter ) setValue("chapter", { label: member.chapter, value: member.chapter });
            setValue("job", member.job);
            setValue("gender", member.gender);
            setValue("phone_number", member.phone_number);
            setValue("address", member.address);
            setValue("shipping_address", member.shipping_address);
            setValue("email", member.email);
            setValue("join_reason", member.join_reason);
            setValue("reference_source", member.reference_source);
            setValue("clothes_size", { label: member.clothes_size, value: member.clothes_size });
            // Attribute Delivery
            member.attribute_delivery && member.attribute_delivery.forEach(x => {
                if (x.key === 'emoney') {
                    setDeliveredCard(x.isChecked);
                    setIsCardDisabled(x.isChecked);
                } else if (x.key === 'polo') {
                    setDeliveredPolo(x.isChecked);
                    setIsPoloDisabled(x.isChecked);
                } else {
                    setDeliveredJacket(x.isChecked);
                    setIsJacketDisabled(x.isChecked);
                }
            });

            if ( member.location_id ) {
                setValue("location", member.location_id);
                setCurrCateg(member.location_id);
            }
            
            // Check if member's membership is expired
            let formatedDate = member.date_of_birth.substring(0,19);
            if ( (moment(member.expired_membership) - moment()) < 0 ) {
                setIsExpired(true);
            }
            setState({
                ...state,
                pop: (member.proof_of_payment !== "undefined")? member.proof_of_payment : "",
                photo: (member.photo !== "undefined")? member.photo : "",
                nationalId: (member.national_identification !== "undefined")? member.national_identification : "",
                selectedDate: formatedDate,
                isRegistered: (member.status === 'registered')? true : false,
                memberName: member.name,
            });
            setIsLoading(false);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setIsMemberNotFound(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
            if ( resp[1].data && resp[1].data[0].msg ) {
                setErrorText(resp[1].data[0].msg);
            }
            else {
                setErrorText(resp[1].message);
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        window.scrollTo(0, 0);
        if ( edit === "Edit Member" ) {
            setEdit("Cancel");
            setPageTitle("Edit Member")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Handle cancel button in approval mode
    const onClickCancel = () => {
        history.replace("/dashboard");
    }

    // Handle approve member
    const onClickApprove = (isApproveAndNext) => {
        if ( !isApproveAndNext )
            setIsNext(false);
        else
            setIsNext(true);
        setOpenApprove(true);
    }
    // Handle decline member
    const onClickDecline = (isDeclineAndNext) => {
        if ( !isDeclineAndNext )
            setIsNext(false);
        else
            setIsNext(true);
        setOpenDecline(true);
    }
    const onClickRenew = () => {
        setOpenRenew(true);
    }
    const handleClose = () => {
        setOpenRenew(false);
        setOpenApprove(false);
        setOpenDecline(false);
        setOpenPinModal(false);
    }

    const goToPrevPage = () => {
        window.scrollTo(0, 0);
        history.push('/member-detail/'.concat(parseFloat(memberCardId) - 1));
    }
    const goToNextPage = () => {
        window.scrollTo(0, 0);
        history.push('/member-detail/'.concat(parseFloat(memberCardId) + 1));
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Handle radio button event
    const onRBChange = (event) => {
        setGender(event.target.value);
    }
    // Handle when date is changed
    const handleDateChange = (date) => {
        setState({
            ...state,
            selectedDate: moment(date),
        });
    };
    // Close toast
    const handleCloseAlert = () => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data member to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            if ( currCateg.category === "Pusat" || currCateg.category === "Luar Wilayah" ) {
                data["chapter"] = currCateg._id;
            }
            else {
                data["chapter"] = data.location._id;
            }
            if ( localStorage.getItem("admin") !== "master" ) {
                setLastData(data);
                setOpenPinModal(true);
            }
            else {
                setIsLoading(true);
                let resp = await editMember(idMember, data, null);
                if (resp[0] && resp[0].status === 200) {
                    if (!isCardDisabled || !isPoloDisabled || !isJacketDisabled) {
                        resp = await updateAttributeDelivery(memberCardId, data);
                    }
                    if (resp[0] && resp[0].status === 200) {
                        setOpenSuccessAlert(true);
                        await sleep(1000);
                        history.go(0);
                    }
                }
                else {
                    console.log(resp);
                    if ( resp[1].data && resp[1].data[0].msg ) {
                        setErrorText(resp[1].data[0].msg);
                    }
                    else {
                        setErrorText(resp[1].message);
                    }
                    setOpenErrorAlert(true);
                    setIsLoading(false);
                }
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <h2>IDEPRENEURS</h2>
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">

                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Update Member
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>

                <StatusModal
                    open={openRenew}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="renew-member"
                    id={idMember}
                    color="primary"
                    dataName={state.memberName}
                    modalTitle="Perpanjang Membership"
                    submitText="Submit"
                    cancelText="Cancel"
                    successText="Berhasil memperpanjang membership!"
                />
                <StatusModal
                    open={openPinModal}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="update-member"
                    id={idMember}
                    dataChapter={chapterData}
                    color="primary"
                    modalTitle="Masukkan Pin"
                    submitText="Submit"
                    cancelText="Cancel"
                    successText="Berhasil update member!"
                    detailedData={lastData}
                />
                <StatusModal
                    open={openApprove}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="approve-member"
                    isGoToNext={isNext}
                    id={idMember}
                    color="primary"
                    dataName={state.memberName}
                    dataCategories={categories}
                    dataLocNames={locationNames}
                    modalTitle="Approve Calon Member?"
                    submitText="Submit"
                    cancelText="Cancel"
                    successText="Berhasil approve menjadi member!"
                />
                <StatusModal
                    open={openDecline}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="decline-member"
                    isGoToNext={isNext}
                    id={idMember}
                    color="primary"
                    dataName={state.memberName}
                    modalTitle="Tolak Calon Member?"
                    submitText="Submit"
                    cancelText="Cancel"
                    successText="Berhasil menolak calon member!"
                />
                <StatusModal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid item xs={12}>
                    {!isMemberNotFound &&
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <Grid container>
                                <Grid item xs={12} md={state.isRegistered? 6 : 12}>
                                    <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                                </Grid>
                                { !state.isRegistered ? <></> :
                                    <Grid item xs={12} md={6} className="remaining-registered">
                                        <h3>{`Total Calon Member: ${totalRegistered}`}</h3>
                                    </Grid>
                                }
                            </Grid>
                            
                            {/* Input for name */}
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for name */}
                                    <TextInput
                                        label="Nama"
                                        name="name"
                                        placeholder="Nama"
                                        className="form-left-field"
                                        validation={register({ required: "*nama harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    {/* Input for membership */}
                                    <GroupedDropdown
                                        label="Jenis Keanggotaan"
                                        labelPosition="half-right-label"
                                        name="membership"
                                        className={`
                                            form-right-field add-margin-bottom grouped-field 
                                            ${ isDisabled? 'disabled-field' : '' }
                                        `}
                                        control={control}
                                        options={[
                                            { label: "Honorary", value: "honorary" },
                                            { label: "Silver", value: "silver" },
                                            { label: "Platinum", value: "platinum" },
                                        ]}
                                        defaultValue=""
                                        isClearable={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for job */}
                            <TextInput
                                label="Bidang Usaha"
                                name="job"
                                placeholder="Bidang Usaha"
                                className="full-width add-margin-bottom"
                                validation={register({ required: "*bidang usaha harus diisi" })}
                                errors={errors.job}
                                errorClassName="text-required"
                                disabled={isDisabled}
                            />

                            <Grid container>
                                <Grid item xs={12} md={6} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for place of birth */}
                                    <GroupedDropdown
                                        label="Tempat Lahir"
                                        name="place_of_birth"
                                        className={`
                                            form-left-field add-margin-bottom grouped-field 
                                            ${ isDisabled? 'disabled-field' : '' }
                                        `}
                                        control={control}
                                        options={cityData}
                                        defaultValue=""
                                        isClearable={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    {/* Input for date of birth */}
                                    <DateInput
                                        label="Tanggal Lahir"
                                        name="date_of_birth"
                                        value={state.selectedDate}
                                        onChange={handleDateChange}
                                        className="form-right-field"
                                        validation={register({ required: "*tanggal lahir harus diisi" })}
                                        errors={errors.date_of_birth}
                                        errorClassName="text-required-half half-right"
                                        disabled={isDisabled}
                                        disableFuture={true}
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for gender */}
                            <RadioButtonInput
                                inputLabel="Jenis Kelamin"
                                control={control}
                                name="gender"
                                defaultValue=""
                                rules={{ required: "*jenis kelamin harus diisi" }}
                                errors={errors.gender}
                                errorClassName="text-required"
                                onRBChange={onRBChange}
                                options={
                                    <Grid container className={`rb-layout ${isDisabled? "disabled-border":""}`}>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio />}
                                                label="Laki-laki"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio />}
                                                label="Perempuan"
                                                style={{ marginLeft: "10px" }}
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for phone number */}
                                    <PhoneInput
                                        label="Nomor Kontak"
                                        name="phone_number"
                                        placeholder="Nomor Kontak"
                                        className="form-left-field"
                                        validation={register}
                                        errors={errors.phone_number}
                                        errorClassName="text-required-half"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for email */}
                                    <EmailInput
                                        label="Email"
                                        name="email"
                                        placeholder="Email"
                                        className="form-right-field"
                                        validation={register}
                                        errors={errors.email}
                                        errorClassName="text-required-half half-right"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grouped-layout" style={{ position: "relative" }}>
                                    {/* Input for domisili */}
                                    <GroupedDropdown
                                        label="Kota Domisili"
                                        name="city"
                                        className={`
                                            full-width add-margin-bottom grouped-field 
                                            ${ isDisabled? 'disabled-field' : '' }
                                        `}
                                        control={control}
                                        options={cityData}
                                        defaultValue=""
                                        isClearable={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Alamat Domisili"
                                        name="address"
                                        placeholder="Alamat Domisili"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat domisili harus diisi" })}
                                        errors={errors.address}
                                        errorClassName="text-required"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Alamat Pengiriman"
                                        name="shipping_address"
                                        placeholder="Alamat Pengiriman"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat pengiriman harus diisi" })}
                                        errors={errors.shipping_address}
                                        errorClassName="text-required"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            {/* Input for join reason */}
                            <ChoiceboxInput
                                inputLabel="Alasan Bergabung"
                                options={
                                    <Grid container className={`cb-layout disabled-border`}>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                control={<Checkbox checked={network}/>}
                                                onChange={() => setNetwork(!network)}
                                                label="Menambah Networking"
                                                name="join_reason_1"
                                                inputRef={register}
                                                disabled={isDisabled}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={consumer}/>}
                                                onChange={() => setConsumer(!consumer)}
                                                label="Menambah Konsumen Potensial"
                                                name="join_reason_2"
                                                inputRef={register}
                                                disabled={isDisabled}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={scale}/>}
                                                onChange={() => setScale(!scale)}
                                                label="Mengembangkan Usaha"
                                                name="join_reason_3"
                                                inputRef={register}
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingLeft: "20px" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={supplier}/>}
                                                onChange={() => setSupplier(!supplier)}
                                                label="Menambah Suplier Potensial"
                                                name="join_reason_4"
                                                inputRef={register}
                                                disabled={isDisabled}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={partner}/>}
                                                onChange={() => setPartner(!partner)}
                                                label="Mencari Rekan Usaha"
                                                name="join_reason_5"
                                                inputRef={register}
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    {/* Input for reference */}
                                    <GroupedDropdown
                                        label="Referensi"
                                        name="reference"
                                        className={`
                                            form-left-field add-margin-bottom grouped-field 
                                            ${ isDisabled? 'disabled-field' : '' }
                                        `}
                                        control={control}
                                        options={[
                                            { label: "Anggota", value: "member" },
                                            { label: "Media Sosial", value: "social-media" },
                                            { label: "Lainnya", value: "other" },
                                        ]}
                                        defaultValue=""
                                        isClearable={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for reference source */}
                                    <TextInput
                                        label="Sumber Referensi (Nama Anggota / Sosmed / Lainnya)"
                                        name="reference_source"
                                        placeholder="Sumber Referensi"
                                        className="form-right-field"
                                        validation={register({ required: "*sumber referensi harus diisi" })}
                                        errors={errors.reference_source}
                                        errorClassName="text-required"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <GroupedDropdown
                                        label="Ukuran Baju"
                                        name="clothes_size"
                                        className={`full-width add-margin-bottom grouped-field 
                                            ${isDisabled? 'disabled-field' : ''}
                                        `}
                                        control={control}
                                        options={[
                                            { label: "XS", value: "XS" },
                                            { label: "S", value: "S" },
                                            { label: "M", value: "M" },
                                            { label: "L", value: "L" },
                                            { label: "XL", value: "XL" },
                                            { label: "XXL", value: "XXL" },
                                            { label: "3XL", value: "3XL" },
                                        ]}
                                        rules={{ required: "*ukuran baju harus diisi" }}
                                        errors={errors.clothes_size}
                                        errorClassName="text-required"
                                        defaultValue=''
                                        isClearable={false}
                                    />
                                </Grid>
                                { state.isRegistered ? <></> :
                                    <>
                                        {/* <Grid item xs={12} style={{ position: "relative" }}>
                                            <GroupedDropdown
                                                label="Chapter"
                                                name="chapter"
                                                className={`
                                                    full-width add-margin-bottom grouped-field
                                                    ${ isDisabled? 'disabled-field' : '' }
                                                `}
                                                control={control}
                                                options={chapterData}
                                                isClearable={true}
                                                disabled={isDisabled}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} style={{ position: "relative" }}>
                                            <DropdownInput
                                                label="Kategori Wilayah"
                                                name="categories"
                                                className="full-width add-margin-bottom tf-label"
                                                keepData={(data) => {
                                                    setValue("location", null);
                                                    setCurrCateg(data);
                                                }}
                                                disableClearable={true}
                                                control={control}
                                                options={categories}
                                                getOptionLabel={(option) => option.category}
                                                value={currCateg}
                                                required={true}
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ position: "relative" }}>
                                            <GroupedDropdown
                                                label="Nama Wilayah *"
                                                labelPosition={ currCateg && currCateg.category !== "Pusat"
                                                    && currCateg.category !== "Luar Wilayah" ?
                                                        "" : "hide-label"
                                                }
                                                name="location"
                                                className={`full-width add-margin-bottom grouped-field
                                                    ${ isDisabled? "disabled-field" : "" }
                                                    ${ currCateg && currCateg.category !== "Pusat"
                                                        && currCateg.category !== "Luar Wilayah" ?
                                                            "" : "hide"
                                                    }
                                                `}
                                                rules={{ required: currCateg && currCateg.category !== "Pusat"
                                                    && currCateg.category !== "Luar Wilayah" ?
                                                        "*nama wilayah harus dipilih" : false
                                                }}
                                                control={control}
                                                options={ !currCateg ? null :
                                                    locationNames.filter(res => res.category === currCateg.category)
                                                }
                                                getOptionLabel={option => option.name}
                                                isClearable={false}
                                                defaultValue=""
                                                errors={errors.location}
                                                errorClassName="text-required"
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>

                            <Grid container style={{ marginTop: "20px" }}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <ImageInput
                                        name="national_identification"
                                        label="Foto KTP"
                                        validation={register}
                                        errors={errors.national_identification}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={state.nationalId}
                                        canDelete={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <ImageInput
                                        name="photo"
                                        label="Foto"
                                        validation={register}
                                        errors={errors.photo}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={state.photo}
                                        canDelete={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <ImageInput
                                        name="proof_of_payment"
                                        label="Bukti Bayar"
                                        validation={register}
                                        errors={errors.proof_of_payment}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={state.pop}
                                        canDelete={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            <ChoiceboxInput
                                options={
                                    <Grid container style={{ marginTop: "20px" }}>
                                        <Grid item xs={12} md={4} className="add-margin-bottom" style={{ padding: '0 10px' }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={deliveredCard}/>}
                                                onChange={() => setDeliveredCard(!deliveredCard)}
                                                label="Kartu eMoney"
                                                name="eMoney"
                                                className="label-on-start bold-label"
                                                labelPlacement="start"
                                                inputRef={register}
                                                disabled={isDisabled || isCardDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} className="add-margin-bottom" style={{ padding: '0 10px' }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={deliveredPolo}/>}
                                                onChange={() => setDeliveredPolo(!deliveredPolo)}
                                                label="Polo"
                                                name="polo"
                                                className="label-on-start bold-label"
                                                labelPlacement="start"
                                                inputRef={register}
                                                disabled={isDisabled || isPoloDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} className="add-margin-bottom" style={{ padding: '0 10px' }}>
                                            {watch('membership') && watch('membership').value !== 'silver' && (
                                                <FormControlLabel
                                                    control={<Checkbox checked={deliveredJacket}/>}
                                                    onChange={() => setDeliveredJacket(!deliveredJacket)}
                                                    label="Jaket"
                                                    name="jaket"
                                                    className="label-on-start bold-label"
                                                    labelPlacement="start"
                                                    inputRef={register}
                                                    disabled={isDisabled || isJacketDisabled}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained" onClick={onClickEdit}
                                        className={`edit-btn ${nonHide} ${(state.isRegistered? 'hide':'')}`}>
                                        {edit}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{ marginRight: "15px" }}
                                        className={`edit-btn ${(isExpired && isDisabled ? '':'hide')}`}
                                        onClick={onClickRenew}   
                                    >
                                        Renew Membership
                                    </Button>
                                    <Button
                                        variant="contained" onClick={goToPrevPage}
                                        className={`edit-btn prev-btn ${nonHide} ${(state.isRegistered? 'hide':'')}`}
                                        style={{
                                            float: 'left',
                                            marginRight: '5px',
                                            background: 'white',
                                            border: 'solid 2px var(--main-brown)'
                                        }}
                                        disabled={memberCardId === '201612000001'}
                                    >    
                                        Prev
                                    </Button>
                                    <Button
                                        variant="contained" onClick={goToNextPage}
                                        className={`edit-btn next-btn ${nonHide} ${(state.isRegistered? 'hide':'')}`}
                                        style={{
                                            float: 'left',
                                            background: 'white',
                                            border: 'solid 2px var(--main-brown)'
                                        }}
                                        disabled={memberCardId === lastId}
                                    >    
                                        Next
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={onClickEdit}
                                        className={`add-cancel-btn ${hide}`}
                                    >Cancel</Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn responsive-submit-btn ${hide}`}
                                    >Submit</Button>
                                    
                                    <Button
                                        variant="contained"
                                        onClick={onClickCancel}
                                        className={`add-cancel-btn ${(state.isRegistered? '':'hide')}`}
                                    >Cancel</Button>
                                    { totalRegistered <= 1 ? <></> :
                                        <Button
                                            variant="contained"
                                            onClick={() => onClickDecline(true)}
                                            style={{ float: "right", marginRight: "15px" }}
                                            className={`add-new-btn secondary-btn 
                                                ${(state.isRegistered? '':'hide')}
                                            `}
                                        >Decline & Next</Button>
                                    }
                                    <Button
                                        variant="contained"
                                        onClick={() => onClickDecline(false)}
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn secondary-btn ${(state.isRegistered? '':'hide')}`}
                                    >Decline</Button>
                                    { totalRegistered <= 1 ? <></> :
                                        <Button
                                            variant="contained"
                                            onClick={() => onClickApprove(true)}
                                            style={{ float: "right", marginRight: "15px" }}
                                            className={`add-new-btn responsive-submit-btn 
                                                ${(state.isRegistered? '':'hide')}
                                            `}
                                        >Approve & Next</Button>
                                    }
                                    <Button
                                        variant="contained"
                                        onClick={() => onClickApprove(false)}
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn responsive-submit-btn ${(state.isRegistered? '':'hide')}`}
                                    >Approve</Button>
                                </Grid>
                            </Grid>

                        </form>
                    }
                    {isMemberNotFound &&
                        <Grid container className='add-form'>
                            <Grid item xs={12}>
                                <h1 style={{ textAlign: 'center' }}>Member Tidak Ditemukan</h1>
                                <Button
                                    variant='contained'
                                    onClick={() => history.push('/member/0')}
                                    className='data-not-found-btn'
                                >
                                    Kembali ke Tabel Member
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </div>
    );
}